import textPlugin from './textPlugin';
export { default as convertChangesToInline } from './convertChangesToInline';

var key = 'text';

export default { key: key, plugin: textPlugin

  /*
   * This should change when we move to rich text and values
   * as objects.
   */
};export var getDefaultValue = function getDefaultValue() {
  return '';
};

export var getContentType = function getContentType() {
  return key;
};

export var isDefaultContentType = function isDefaultContentType(type) {
  return key === type;
};