import PlaceholderPlugin from '@gigmade/slate-react-placeholder';
import { PathUtils } from '@gigmade/slate';

import { isGigNode, isContentNode } from '../../utils/node';
import { getRootGigNode, getGigNodeStructureByKey } from '../../utils';

var create = PathUtils.create,
    lift = PathUtils.lift,
    increment = PathUtils.increment,
    decrement = PathUtils.decrement;


var titleGigNodePath = create([0]);

var noPrevGigNode = function noPrevGigNode(document, path, hasChildrenWrapper) {
  return !hasChildrenWrapper && !isGigNode(document.getNode(decrement(lift(path)))) || hasChildrenWrapper && lift(path).last() === 0;
};

var whens = {
  title: function title(editor, node) {
    var document = editor.value.document;

    var path = document.getPath(node.key);

    return path && path.size === 2 &&
    // isEqual(path, titleContentPath) &&
    node.type === 'content' && document.getNode(titleGigNodePath).type === 'text' && node.text === '';
  },
  text: function text(editor, node) {
    var document = editor.value.document;

    var path = document.getPath(node.key);
    if (path.size > 4) {
      return false;
    }
    var rootNode = getRootGigNode(editor);
    if (!rootNode) {
      return false;
    }

    var _getGigNodeStructureB = getGigNodeStructureByKey(editor, rootNode.key),
        hasChildrenWrapper = _getGigNodeStructureB.hasChildrenWrapper;

    var placeholderLength = hasChildrenWrapper ? 4 : 3;

    return path.size === placeholderLength && isContentNode(node) &&
    // The parent is (a gigNode) of type text
    document.getNode(lift(path)).type === 'text' &&
    // root doesn't have a next child.
    // The idea of this check is to be independent of whether the title
    // contentType has a content or not, so instead of checking absolute
    // paths, we check:
    // 1. A next gigNode should not exist.
    // Note we don't worry if it's a gigNode or childrenWrapper.
    !document.getNode(increment(path, 1, hasChildrenWrapper ? 2 : 1)) &&
    // 2. A previous gigNode should not exist.
    noPrevGigNode(document, path, hasChildrenWrapper) && node.text === '';
  }
};

export default (function (_ref) {
  var type = _ref.type,
      placeholder = _ref.placeholder;

  if (type !== 'title' && type !== 'text') {
    throw new Error("type needs to be 'title' or 'text'");
  }
  return PlaceholderPlugin({
    placeholder: placeholder,
    when: whens[type]
  });
});