import React, { useCallback, useState, useEffect } from 'react'
import debounce from 'lodash/debounce'
import { UNTITLED, STARTTEXT } from '../config.json'
import TableOfContents from './TableOfContents'
import useWipNode from '../connectors/useWipNode'
import { onExternalNew } from '../localState/wipNode'

import { EditorWrapper, EditorRoot, ScrollableEditorView } from './Editor.style'
import useOpaqueEditorState from '../connectors/useOpaqueEditorState'
import useEditorControls from '../connectors/useEditorControls'
import { mockFileLoader as createFileLoader } from '@gigmade/file-loader'

import { TestEditor as SlateEditor } from '@gigmade/msm-editor'
import { startNode } from '../config.json'

const fileLoader = createFileLoader({ waitFactor: 100, hasErrors: false })

const storageKey = 'editorValue'

const delayedSave = debounce((window, data) => {
  if (data.node) {
    console.log('saving..')
    window.localStorage.setItem(storageKey, JSON.stringify(data.node))
  }
}, 250)

export default ({ error, loading, readOnly }) => {
  const { layout: editorLayout } = useEditorControls()
  const { undoable, onEditorChange } = useWipNode()
  const { setEditorState } = useOpaqueEditorState()
  const [initialNode, setInitialNode] = useState(null)

  const onChange = useCallback(
    data => {
      const { node, selectedId, editorState } = data
      setEditorState(editorState)
      onEditorChange({ node, selectedId })
      delayedSave(window, data)
    },
    [setEditorState, onEditorChange]
  )

  useEffect(() => {
    return () => {
      delayedSave.cancel()
    }
  }, [])

  useEffect(() => {
    const storedNode =
      JSON.parse(window.localStorage.getItem(storageKey)) || startNode
    setInitialNode(storedNode)
    onExternalNew({ node: storedNode })
  }, [])

  return loading || initialNode == null || error ? null : (
    <EditorWrapper>
      {/*{Boolean(editorLayout) ? null : <TableOfContents />}*/}
      <ScrollableEditorView>
        <SlateEditor
          RootWrapper={EditorRoot}
          debug={false}
          undoable={undoable}
          fileLoader={fileLoader}
          node={initialNode}
          isDiffing={false}
          onChange={onChange}
          textPlaceholder={STARTTEXT}
          titlePlaceholder={UNTITLED}
        />
      </ScrollableEditorView>
    </EditorWrapper>
  )
}
