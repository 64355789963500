var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useEffect, cloneElement, Children } from 'react';

import { useLayout2d } from './Layout2dContext';
import usePrevious from './usePrevious';
import useMeasure from 'react-use/lib/useMeasure';

export default (function (_ref) {
  var children = _ref.children,
      layout2dId = _ref.layout2dId,
      layout2d = _ref.layout2d,
      isFocused = _ref.isFocused,
      domProps = _objectWithoutProperties(_ref, ['children', 'layout2dId', 'layout2d', 'isFocused']);

  var child = void 0;

  var _useLayout2d = useLayout2d(),
      focusNodeKey = _useLayout2d[0].focusNodeKey,
      dispatch = _useLayout2d[1];

  var prevIsFocused = usePrevious(isFocused);

  var _useMeasure = useMeasure(),
      ref = _useMeasure[0],
      height = _useMeasure[1].height;

  useEffect(function () {
    var _value;

    if (!layout2d || !isFocused || !height) return;
    dispatch({ type: 'setValue', value: (_value = {}, _value[layout2dId] = child, _value) });
  }, [height, isFocused, layout2d, dispatch, child, layout2dId]);

  useEffect(function () {
    if (isFocused && focusNodeKey !== layout2dId) {
      dispatch({ type: 'setFocusNodeKey', value: layout2dId });
    } else if (prevIsFocused && !isFocused) {
      dispatch({ type: 'setFocusNodeKey', value: null });
      dispatch({ type: 'setFocusedWidth', value: null });
    }
  }, [isFocused, layout2dId, dispatch, focusNodeKey, prevIsFocused]);

  useEffect(function () {
    var _value2;

    if (!layout2d) return;
    dispatch({ type: 'setValue', value: (_value2 = {}, _value2[layout2dId] = child, _value2) });
  }, [layout2dId, layout2d, child, isFocused, dispatch]);

  try {
    child = Children.only(children);
  } catch (e) {
    throw new Error('Each Layout2dNodeContent must wrap a single child');
  }

  var newChild = cloneElement(child, _extends({}, domProps));
  if (!layout2d) {
    return newChild;
  }

  return React.createElement(
    'div',
    { ref: ref },
    newChild
  );
});