import { ALT, INVERT, SWAP, INSERT, DELETE, SPRINGER } from '@gigmade/msm-merge-util';
import InvertSwapSpringer from './InvertSwapSpringer';
import Indent from './Indent';
import Alt from './Alt';
import Box from './Box';

export { Box, Alt, InvertSwapSpringer };

export default (function (type, indentType) {
  switch (type) {
    case INSERT:
    case DELETE:
      {
        return Box;
      }
    /* eslint-disable no-fallthrough */
    case SPRINGER:
      {
        if (indentType) {
          return Indent;
        }
      }
    case INVERT:
    case SWAP:
      {
        return InvertSwapSpringer;
      }
    case ALT:
      {
        return Alt;
      }
    default:
      {
        throw new Error('Could not find suitable component');
      }
  }
});

export var DEDENT = 'dedent';
export var INDENT = 'indent';