var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  width: 20px;\n  padding-right: 0.7em;\n'], ['\n  width: 20px;\n  padding-right: 0.7em;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  background: ', ';\n  position: absolute;\n  width: 3px;\n'], ['\n  background: ', ';\n  position: absolute;\n  width: 3px;\n']);

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import { DiffIndicatorContext } from './diffIndicatorContext';
import { colorScheme } from '@gigmade/msm-ui';

/*
 * This pushes the main editor right.
 */
var Wrapper = styled.div(_templateObject);

var DiffLine = styled.div.attrs(function (_ref) {
  var top = _ref.top,
      height = _ref.height;
  return {
    style: { top: top, height: height }
  };
})(_templateObject2, colorScheme.compare.changeLine);

export default (function () {
  var _useContext = useContext(DiffIndicatorContext),
      diffPositions = _useContext.diffPositions;

  return React.createElement(
    Wrapper,
    null,
    Object.keys(diffPositions).map(function (key, i) {
      var _diffPositions$key = diffPositions[key],
          props = _diffPositions$key.props,
          MarkComponent = _diffPositions$key.MarkComponent,
          showDiffLine = _diffPositions$key.showDiffLine,
          dimensions = _objectWithoutProperties(_diffPositions$key, ['props', 'MarkComponent', 'showDiffLine']);

      // The dimensions of the rendered node.


      var height = dimensions.height,
          top = dimensions.top;
      // The dimensions MarkComponents should have

      var markDimensions = { top: top, left: 5, width: 15, height: 15 };

      return React.createElement(
        Fragment,
        { key: key },
        MarkComponent && React.createElement(MarkComponent, {
          dimensions: markDimensions,
          nodeDimensions: dimensions
        }),
        showDiffLine && React.createElement(DiffLine, _extends({ height: height, top: top }, props))
      );
    })
  );
});