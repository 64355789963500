import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import getRightSiblingGigNodesByKey from './getRightSiblingGigNodesByKey';
import unwrapGigNodeByKey from './unwrapGigNodeByKey';
import appendGigNodeChild from './appendGigNodeChild';
import getRootGigNode from './getRootGigNode';
import getGigNodeStructureByKey from './getGigNodeStructureByKey';

/*
 * addIndex:
 * When the unwrapped node becomes a sibling of its parent, 0 is the default
 * `addIndex` and means the unwrapped node is placed immediately after its old
 * parent.
 */
export default (function (editor, key) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$addIndex = _ref.addIndex,
      addIndex = _ref$addIndex === undefined ? 0 : _ref$addIndex;

  var thisGigNode = getClosestGigNodeByKey(editor, key);
  var thisPath = editor.value.document.getPath(thisGigNode.key);

  if (thisPath.size < 3) {
    // Path certainly too low for indenting.
    return;
  }

  if (thisPath.size === 3) {
    // path too low for indenting if root has a childrenWrapper.
    var rootNode = getRootGigNode(editor);

    var _getGigNodeStructureB = getGigNodeStructureByKey(editor, rootNode.key),
        hasChildrenWrapper = _getGigNodeStructureB.hasChildrenWrapper;

    return hasChildrenWrapper;
  }

  // Only when addIndex is 0, we need to let the right siblings `sN` of
  // `thisGigNode` (as before dedenting) become the last children of
  // `thisGigNode` after the dedent. Example:
  // > This structure:
  // n1
  //   thisGigNode
  //     n2
  //   s1
  // > needs to become this structure:
  // n1
  // thisGigNode
  //   n2
  //   s1
  // For any non-0 `addIndex`, this step is not required.

  // Let `sN` be the right siblings of `thisGigNode` before it is dedented.
  var rightSiblings = getRightSiblingGigNodesByKey(editor, thisGigNode.key);

  // 1. Move `thisGigNode` out of its parent `p` and add it as a right sibling
  // to `p` if `addIndex == 0`, or else move it according to the relative
  // displacement `addIndex`.
  var effectiveAddIndex = unwrapGigNodeByKey(editor, thisGigNode.key, {
    addIndex: addIndex
  });

  // 2. If `addIndex == 0`, append `sN` as the last children of `thisGigNode`.
  // effectiveAddIndex is clamped to a reasonable range so it could be different.
  if (effectiveAddIndex !== 0) {
    return;
  }

  rightSiblings.forEach(function (sibling) {
    appendGigNodeChild(editor, thisGigNode.key, sibling);
  });
});