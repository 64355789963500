// import { cacheExists } from './diffCache'
// import { getRootGigNode } from '../utils'
// import getDiffObj from './getDiffObj'

export default (function (editor) {
  return editor.props && editor.props.isDiffing;

  // if (cacheExists(editor)) {
  //   return true
  // }

  // // As a safety, while cache is loading we can still see if
  // // we're in a diff
  // const rootNode = getRootGigNode(editor)
  // const diffObj = getDiffObj(rootNode)

  // return diffObj && diffObj.key != null
});