/*
 * TODO: Use getRootBlocksAtRange so it works for whole ranges.
 */
import { indent, dedent } from '../utils';
import { getGigNodeDepth, emptyLineSelected } from '../utils';

import { isTab, isShiftTab, isEnter, isBackspace } from './keyboard';

export default (function (options) {
  return {
    commands: {
      gigIndent: function gigIndent(editor) {
        indent(editor);
      },
      gigDedent: function gigDedent(editor) {
        dedent(editor);
      }
    },
    onKeyDown: function onKeyDown(event, editor, next) {
      if (isTab(event)) {
        event.preventDefault();
        indent(editor);
        return true;
      }

      if (isShiftTab(event)) {
        event.preventDefault();
        dedent(editor);
        return true;
      }

      if (isEnter(event) && emptyLineSelected(editor)) {
        event.preventDefault();
        dedent(editor);
        return true;
      }

      // if (
      //   isBackspace(event) &&
      //   emptyLineSelected(editor) &&
      //   getGigNodeDepth(editor) > 1
      // ) {
      //   event.preventDefault()
      //   dedent(editor)
      //   return true
      // }

      return next();
    }
  };
});