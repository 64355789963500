/*
 * The concept of empty is currently only used for text nodes.
 * These are "empty" if their content is empty. They could still have children.
 * On empty, they might be unwrapped, i.e. their children will be made siblings
 * and the node will be deleted.
 *
 * In future, we may design a "isEmpty" function for tables and the like.
 * But there, we may have to assume that the children can be neither ignored nor
 * unwrapped.
 */
import getGigNodeContentPluginByKey from './getGigNodeContentPluginByKey';

export default (function (editor, key) {
  var contentIsEmpty = getGigNodeContentPluginByKey(editor, key).defineNode.contentIsEmpty;

  return Boolean(contentIsEmpty);
});