var _templateObject = _taggedTemplateLiteralLoose(['\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n'], ['\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  line-height: 0.8em;\n  opacity: 0.5;\n  font-size: 0.8em;\n  display: inline-block;\n  animation: ', ' 2s linear infinite;\n'], ['\n  line-height: 0.8em;\n  opacity: 0.5;\n  font-size: 0.8em;\n  display: inline-block;\n  animation: ', ' 2s linear infinite;\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import styled, { keyframes } from 'styled-components';

var rotate360 = keyframes(_templateObject);

var Spinner = styled.span(_templateObject2, rotate360);

Spinner.defaultProps = {
  className: 'icon-spin1'
};

export default Spinner;