var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

/*
 * Note: Putting shapes into different svg containers instead of the same
 * allows us to make tight viewBoxes around them, not capturing the mouse in
 * unwanted places.
 */

import React, { forwardRef, useMemo } from 'react';
import Svg from './Svg';
// (x, y, minX, minY, maxX, maxY,
import { pointOnRect } from '../../math';
import { PathLine } from 'react-svg-pathline';
import { dimStr } from './calcs';
import { strokeColor, strokeWidth, lineRadius } from './config';
import createBox from './createBox';

// we need to calculate 2 points on rects.
var getConnectPoints = function getConnectPoints(sourceRect, targetRect) {
  var x1Min = sourceRect[0],
      y1Min = sourceRect[1],
      x1Max = sourceRect[2],
      y1Max = sourceRect[3];
  var x2Min = targetRect[0],
      y2Min = targetRect[1],
      x2Max = targetRect[2],
      y2Max = targetRect[3];

  var centerS = [x1Min + (x1Max - x1Min) / 2, y1Min + (y1Max - y1Min) / 2];
  var centerT = [x2Min + (x2Max - x2Min) / 2, y2Min + (y2Max - y2Min) / 2];

  var endpointS = pointOnRect.apply(undefined, centerT.concat(sourceRect));
  var endpointT = pointOnRect.apply(undefined, centerS.concat(targetRect));

  return [endpointS, endpointT];
};

var ArrowHead = function ArrowHead(_ref) {
  var points = _ref.points,
      inverse = _ref.inverse;

  // Draw head around point[1], rotate per points direction.
  var _useMemo = useMemo(function () {
    var _ref2 = inverse ? [points[1], points[0]] : points,
        src = _ref2[0],
        tip = _ref2[1];

    var dx = tip.x - src.x,
        dy = tip.y - src.y;

    var length = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    var arrowLength = Math.min(10 * strokeWidth, length / 3);

    var angle = dx !== 0 ? Math.atan2(dx, dy) / Math.PI * 180 : dy < 0 ? 180 : 0;

    var headPoints = [{ x: tip.x - arrowLength, y: tip.y + arrowLength }, tip, { x: tip.x + arrowLength, y: tip.y + arrowLength }];

    var sq2 = Math.sqrt(2);
    var dimensions = {
      top: tip.y - arrowLength * sq2,
      left: tip.x - arrowLength * sq2,
      width: 2 * arrowLength * sq2,
      height: 2 * arrowLength * sq2
    };

    return { angle: angle, dimensions: dimensions, headPoints: headPoints, tip: tip };
  }, [points, inverse]),
      angle = _useMemo.angle,
      dimensions = _useMemo.dimensions,
      headPoints = _useMemo.headPoints,
      tip = _useMemo.tip;

  return React.createElement(
    Svg,
    _extends({
      fill: 'none',
      strokeWidth: strokeWidth,
      stroke: strokeColor
    }, dimensions, {
      viewBox: dimStr(dimensions)
    }),
    React.createElement(
      'g',
      { transform: 'rotate(' + (180 - angle) + ' ' + tip.x + ' ' + tip.y + ')' },
      React.createElement(PathLine, {
        points: headPoints,
        stroke: strokeColor,
        strokeWidth: strokeWidth,
        fill: 'none',
        r: lineRadius
      })
    )
  );
};

var Connector = function Connector(_ref3) {
  var points = _ref3.points;

  // Arbitrary margin to make responsive field bigger:
  var responsive = 2;

  var dimensions = useMemo(function () {
    var halfWidth = strokeWidth / 2;
    var sourceP = points[0],
        targetP = points[1];

    var dimensions = {
      left: Math.min(sourceP.x, targetP.x) - halfWidth - responsive,
      top: Math.min(sourceP.y, targetP.y),
      width: strokeWidth + 2 * responsive,
      height: Math.abs(sourceP.y - targetP.y)
    };

    return dimensions;
  }, [points]);

  return React.createElement(
    Svg,
    _extends({
      fill: 'none',
      strokeWidth: strokeWidth,
      stroke: strokeColor
    }, dimensions, {
      viewBox: dimStr(dimensions)
    }),
    React.createElement(PathLine, {
      points: points,
      stroke: strokeColor,
      strokeWidth: strokeWidth,
      fill: 'none',
      r: lineRadius
    })
  );
};

export default forwardRef(function (_ref4, ref) {
  var twoSided = _ref4.twoSided,
      fromDimensions = _ref4.fromDimensions,
      toDimensions = _ref4.toDimensions,
      domProps = _objectWithoutProperties(_ref4, ['twoSided', 'fromDimensions', 'toDimensions']);

  var _createBox = createBox({
    dimensions: fromDimensions
  }),
      sourcePoints = _createBox.points,
      sourceBox = _createBox.element;

  var _createBox2 = createBox({
    dimensions: toDimensions
  }),
      targetPoints = _createBox2.points,
      targetBox = _createBox2.element;

  var connectPoints = useMemo(function () {
    var connectPoints = getConnectPoints(sourcePoints, targetPoints);
    // We could make the div position/size cover all, which affects popup.
    // We should probably add some opacity, then it may make sense
    // Actually, we should make the div span the height of the connector (only).
    // const overallDimensions =
    return connectPoints;
  }, [sourcePoints, targetPoints]);

  return React.createElement(
    'div',
    _extends({ ref: ref }, domProps),
    sourceBox,
    React.createElement(Connector, { points: connectPoints }),
    React.createElement(ArrowHead, { points: connectPoints }),
    twoSided && React.createElement(ArrowHead, { points: connectPoints, inverse: true }),
    targetBox
  );
});