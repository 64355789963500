import isGigNode from './node/isGigNode';

export default (function (editor, findFn, ancestor) {
  var document = editor.value.document;

  return (ancestor || document).findDescendant(function (node) {
    if (node.object !== 'block') {
      return false;
    }
    if (!isGigNode(node)) {
      return false;
    }
    return Boolean(findFn(node));
  });
});