var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

/*
 * Once all react elements that take part in tree are stored in context,
 * we can create the flexbox layout in a hidden react portal.
 * From that flexbox we'll get all node params (position, dimsensions)
 * that we need to apply back to the nodes in our default tree, in order
 * to render a 2d map view.
 */

import React, { useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { NodeGroup, ChildGroup } from './styles';
import { useLayout2d } from './Layout2dContext';

var DATA_LAYOUT2D_HIDDEN = 'data-l2d-hidden';

/*
 * Layout2dFlexbox renders the hidden flexbox in the portal.
 * It then retrieves all position/dimension data from that flexbox.
 * For this it consumes Layout2dContext for values/valuesReady
 */
export default (function (_ref) {
  var tree = _ref.tree,
      containerRef = _ref.containerRef,
      type = _ref.type,
      getParent = _ref.getParent;

  var _useLayout2d = useLayout2d(),
      _useLayout2d$ = _useLayout2d[0],
      values = _useLayout2d$.values,
      valuesReady = _useLayout2d$.valuesReady,
      focusNodeKey = _useLayout2d$.focusNodeKey,
      focusWidth = _useLayout2d$.focusWidth,
      portalRef = _useLayout2d$.portalRef,
      dispatch = _useLayout2d[1];

  var children = tree.children,
      node = _objectWithoutProperties(tree, ['children']);

  var target = usePortal(portalRef);

  var rootRef = useRef(null);

  var update = useCallback(function () {
    if (!rootRef.current || !valuesReady || !type) return;
    var nodes = rootRef.current.querySelectorAll('div[' + DATA_LAYOUT2D_HIDDEN + ']');
    if (!nodes) {
      return;
    }

    var _getChildrenListAndKe = getChildrenListAndKeys(tree),
        childrenList = _getChildrenListAndKe[0],
        keys = _getChildrenListAndKe[1];

    var containerProps = containerRef.current.getBoundingClientRect();
    var params = {};
    var fixedParams = {};
    var connectParams = {};[].forEach.call(nodes, function (el) {
      var rect = el.getBoundingClientRect();
      fixedParams[el.getAttribute(DATA_LAYOUT2D_HIDDEN)] = {
        height: rect.height,
        position: 'fixed',
        top: rect.top,
        left: rect.left,
        width: rect.width,
        bottom: rect.bottom,
        right: rect.right,
        zIndex: 1
      };

      connectParams[el.getAttribute(DATA_LAYOUT2D_HIDDEN)] = {
        height: rect.height,
        top: rect.top - containerProps.top,
        left: rect.left - containerProps.left,
        right: rect.right - containerProps.left,
        width: rect.width,
        bottom: rect.bottom - containerProps.top
      };

      params[el.getAttribute(DATA_LAYOUT2D_HIDDEN)] = {
        left: rect.left - containerProps.left,
        top: rect.top - containerProps.top,
        width: rect.width,
        height: rect.height
      };
    });

    var finalParams = _extends({}, params);
    var parentsFor = {};

    for (var _iterator = keys, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
      var _ref2;

      if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref2 = _iterator[_i++];
      } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref2 = _i.value;
      }

      var nodeKey = _ref2;

      var parent = getParent(nodeKey);
      var parentId = parent ? parent.key : null;
      parentsFor[nodeKey] = [];
      while (parentId) {
        parentsFor[nodeKey].push(parentId);
        var _parent = getParent(parentId);
        parentId = _parent ? _parent.key : null;
      }
    }

    for (var _iterator2 = keys, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
      var _ref3;

      if (_isArray2) {
        if (_i2 >= _iterator2.length) break;
        _ref3 = _iterator2[_i2++];
      } else {
        _i2 = _iterator2.next();
        if (_i2.done) break;
        _ref3 = _i2.value;
      }

      var _nodeKey = _ref3;

      var _children = childrenList[_nodeKey];
      for (var _iterator3 = _children, _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[Symbol.iterator]();;) {
        var _ref4;

        if (_isArray3) {
          if (_i3 >= _iterator3.length) break;
          _ref4 = _iterator3[_i3++];
        } else {
          _i3 = _iterator3.next();
          if (_i3.done) break;
          _ref4 = _i3.value;
        }

        var el = _ref4;

        var parents = parentsFor[el.key];
        for (var i = 0; i < parents.length; i++) {
          if (!params[parents[i]]) continue;
          finalParams[el.key]['top'] -= params[parents[i]]['top'];
          finalParams[el.key]['left'] -= params[parents[i]]['left'];
        }
      }
    }
    dispatch({
      type: 'setNodeMapParams',
      value: {
        absoluteParams: finalParams,
        fixedParams: fixedParams,
        connectParams: connectParams
      }
    });
  }, [dispatch, tree, getParent, containerRef, valuesReady, type]);

  useEffect(function () {
    if (rootRef.current && values && valuesReady && values && type) {
      update();
    }
  }, [values, valuesReady, update, type, focusWidth]);

  /*
  useDebounce(
    () => {
      if (rootRef.current) {
        update()
      }
    },
    400,
    [values]
  )
  */

  var result = React.createElement(
    'div',
    { ref: rootRef },
    React.createElement(
      Tree,
      {
        nodeKey: node.key,
        children: node.children,
        type: type,
        focusNodeKey: focusNodeKey,
        focusWidth: focusWidth,
        values: values
      },
      children
    )
  );
  return createPortal(result, target);
});

var Tree = function Tree(_ref5) {
  var children = _ref5.children,
      nodeKey = _ref5.nodeKey,
      type = _ref5.type,
      values = _ref5.values,
      focusNodeKey = _ref5.focusNodeKey,
      focusWidth = _ref5.focusWidth;

  // FIXME px values.
  return React.createElement(
    NodeGroup,
    { type: type },
    React.createElement(
      'div',
      {
        'data-l2d-hidden': nodeKey,
        style: {
          margin: type === 'down' ? '30px 0 0 0' : 0,
          boxSizing: 'border-box',
          border: '1px solid red',
          width: focusNodeKey === nodeKey && focusWidth > 0 ? focusWidth : null,
          flexShrink: focusNodeKey === nodeKey && focusWidth > 0 ? 0 : null
        }
      },
      values[nodeKey]
    ),
    children.length > 0 && React.createElement(
      ChildGroup,
      { type: type },
      children.map(function (child, i) {
        var children = child.children,
            nodeKey = child.key,
            node = _objectWithoutProperties(child, ['children', 'key']);

        return React.createElement(Tree, {
          key: child.key + '-' + i,
          children: children,
          nodeKey: nodeKey,
          type: type,
          values: values,
          focusNodeKey: focusNodeKey,
          focusWidth: focusWidth
        });
      })
    )
  );
};

var getChildrenListAndKeys = function getChildrenListAndKeys(tree) {
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var keys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  list[tree.key] = tree.children;
  keys.push(tree.key);(tree.children || []).forEach(function (el) {
    return getChildrenListAndKeys(el, list, keys);
  });
  return [list, keys];
};

function usePortal(parentRef) {
  var rootElemRef = React.useRef(document.createElement('div'));
  useEffect(function () {
    var rootElement = rootElemRef.current;

    parentRef.current.appendChild(rootElement);
    return function () {
      rootElement.remove();
    };
  }, [parentRef]);
  return rootElemRef.current;
}