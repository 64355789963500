export var dimStr = function dimStr(dimensions) {
  return dimensions.left + " " + dimensions.top + " " + dimensions.width + " " + dimensions.height;
};

export var dimensionsToBox = function dimensionsToBox(_ref) {
  var top = _ref.top,
      left = _ref.left,
      width = _ref.width,
      height = _ref.height;
  return [left, top, left + width, top + height];
};

/*
 * We stretch the box by a margin. Except its height which we leave 0
 * if it was 0, to get a single line.
 */
export var addDimMargin = function addDimMargin(_ref2, margin) {
  var top = _ref2.top,
      left = _ref2.left,
      width = _ref2.width,
      height = _ref2.height;
  return {
    top: height === 0 ? top : top - margin,
    left: left - margin,
    width: width + margin * 2,
    height: height === 0 ? 0 : height + margin * 2
  };
};

export var positionedCss = function positionedCss(dimensions) {
  return "\n    position: absolute;\n    top: " + dimensions.top + "px;\n    left: " + dimensions.left + "px;\n    width: " + dimensions.width + "px;\n    height: " + dimensions.height + "px;\n    ";
};