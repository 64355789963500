/*
 * When I delete a void content node, its container gigNode sticks around
 * and is not usable (it's still an image type container.)
 * So, the suggestion is to build a new gigNode, and re-assign the children
 * of the old one back to it, then removing the old gigNode.
 */
import { unwrapRemoveGigNodeByKey } from '../../utils';
import { getContentType, isGigNode } from '../../utils/node';

import { contentNodeType } from '../../utils/helpers/createGigNodeJson';

var normalizeNode = function normalizeNode(debug) {
  return function (node, editor, next) {
    if (node.object !== 'block') {
      return next();
    }
    // Looking for a gigNode.
    if (!isGigNode(node)) {
      return next();
    }

    // A gigNode that by its type definition should have content.
    var content = editor.gigGetContentPlugins()[getContentType(node)].defineNode.content;

    if (!content) {
      return next();
    }
    // And the content is not there (anymore)
    if (node.nodes.size && node.nodes.first().type === contentNodeType(content)) {
      return next();
    }
    // Here we have the situation that a gigNode that needs a content has none
    // (or perhaps none at the first place in the list).
    // NOTE: There is the same unwrapRemove handler in text.js schema, and
    // this check here may take precedence over the other depending on how
    // validation is called.
    if (debug) {
      console.log('Handling missing first content node');
    }
    return function () {
      editor.withoutNormalizing(function () {
        unwrapRemoveGigNodeByKey(editor, node.key);
      });
    };
  };
};

export default (function (_ref) {
  var debug = _ref.debug;
  return { normalizeNode: normalizeNode(debug) };
});