/*
 * Some functions may not interfere with containers, so we need a function that gives
 * us the closest ancestor of a gigNode that is not inside a container.
 * NOTE: a container qualifies when it is not in a container.
 */
import getAncestorGigNodesByKey from './getAncestorGigNodesByKey';
import getGigNodeContentPluginByKey from './getGigNodeContentPluginByKey';

export default (function (editor, key) {
  var ancestors = getAncestorGigNodesByKey(editor, key, { bottomUp: false });
  var highestContainerIdx = ancestors.findIndex(function (ancestor) {
    var plugin = getGigNodeContentPluginByKey(editor, ancestor.key);
    return Boolean(plugin.OuterContainer);
  });

  // If there is no container (index = -1), the parent is it.
  return ancestors.get(Math.max(highestContainerIdx, 0));
});