/*
 * Need 2 nodes (title and text each 1) to get started with editing.
 */
import getChildrenGigNodes from '../../utils/node/getChildrenGigNodes';
import createGigNode from '../../utils/createGigNode';
import insertChildGigNode from '../../utils/insertChildGigNode';

var unshiftNewGigNode = function unshiftNewGigNode(editor, node) {
  var childGigNode = createGigNode(editor);
  insertChildGigNode(editor, node.key, childGigNode, 0);
};

/*
 * We can only have 1 root node.
 */
var fixRootCount = function fixRootCount(editor, node, count) {
  if (count === 0) {
    unshiftNewGigNode(editor, node);
  }
  if (count > 1) {
    getChildrenGigNodes(node).forEach(function (child, i) {
      if (i > 0) {
        editor.removeNodeByKey(child.key);
      }
    });
  }
};

var ensureDefaultValue = function ensureDefaultValue(debug) {
  return function (node, editor, next) {
    if (node.object !== 'document') {
      return next();
    }

    if (node.nodes.size !== 1) {
      if (debug) {
        console.log('Fixing document should have 1 child but has ' + node.nodes.size);
      }

      return function () {
        return editor.withoutNormalizing(function () {
          fixRootCount(editor, node, node.nodes.size);
        });
      };
    }

    var rootNode = node.nodes.first();

    if (getChildrenGigNodes(rootNode).size >= 1) {
      return next();
    }

    if (debug) {
      console.log('Fixing root node should have at least one child.');
    }

    // root has no children -> create one.
    return function () {
      editor.withoutNormalizing(function () {
        unshiftNewGigNode(editor, rootNode);
      });
    };
  };
};

export default (function (_ref) {
  var debug = _ref.debug;
  return {
    normalizeNode: ensureDefaultValue(debug)
  };
});