var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useCallback, Fragment } from 'react';
import addMessage from './addMessage';
import pauseSave from './pauseSave';
import { mockFileLoader as createFileLoader } from '@gigmade/file-loader';

import { Editor as SlateEditor } from '../..';

var fileLoader = createFileLoader();

var empty = [];

export default (function (_ref) {
  var node = _ref.node,
      userOnChange = _ref.onChange,
      _ref$plugins = _ref.plugins,
      plugins = _ref$plugins === undefined ? empty : _ref$plugins,
      slateProps = _objectWithoutProperties(_ref, ['node', 'onChange', 'plugins']);

  var onChange = useCallback(function (data) {
    if (userOnChange) {
      userOnChange(data);
    }
  }, [userOnChange]);

  return React.createElement(SlateEditor, _extends({
    debug: true,
    fileLoader: fileLoader,
    readOnly: false,
    node: node,
    plugins: plugins,
    sessionNumber: 0,
    onChange: onChange,
    addMessage: addMessage,
    textPlaceholder: 'Write something.',
    titlePlaceholder: 'Untitled',
    pauseSave: pauseSave
  }, slateProps));
});

export { default as TestEditorControls } from './components/EditorControls';