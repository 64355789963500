import getOrAddChildrenGigNodeWrapper from './getOrAddChildrenGigNodeWrapper';

function getKey(child) {
  return typeof child === 'string' ? child : child.key;
}

/*
 * NOTE: child can be a key IF it's in the document already.
 */
export default (function (editor, parentKey, child, index) {
  editor.withoutNormalizing(function () {
    var childrenWrapper = getOrAddChildrenGigNodeWrapper(editor, parentKey);

    var document = editor.value.document;

    var childKey = getKey(child);
    if (document.hasNode(childKey)) {
      // TODO: could optimize by identifying child path first?
      // TEMP HACK: sometimes index coming from onToggle is not valid
      // Ideally, Slate would perform a check like this internally,
      // Or onToggle would have correct indices
      if (index > childrenWrapper.nodes.size) {
        index = childrenWrapper.nodes.size;
      } else if (childrenWrapper.nodes.includes(child) && index >= childrenWrapper.nodes.size) {
        index = childrenWrapper.nodes.size - 1;
      }

      editor.moveNodeByKey(childKey, childrenWrapper.key, index);
      return;
    }

    editor.insertNodeByKey(childrenWrapper.key, index, child);
  });
});