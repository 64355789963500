/*
 * Note: While this is "plumbing" command, not respecting the gigNode
 * specific structure, it is usable for gigNode children because
 * at the time it is used, a content is already present at the parent
 * if applicable, and appending nodes will mean appending gigNode children.
 */
import insertChild from './insertChild';

export default (function (editor, parentKey, child) {
  var document = editor.value.document;

  var parent = document.getNode(parentKey);
  var index = parent.nodes.size;
  insertChild(editor, parentKey, child, index);
});