import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import appendChild from './plumbing/appendChild';
import { getChildrenWrapper, createChildrenWrapperJson } from './helpers/createGigNodeJson';
import { Block } from '@gigmade/slate';

export default (function (editor, key) {
  var document = editor.value.document;

  // Children to the doc will be inside the doc, not wrapped further.

  if (document.key === key) {
    return document;
  }

  var thisGigNode = getClosestGigNodeByKey(editor, key);

  var childrenWrapper = getChildrenWrapper(thisGigNode);

  if (!childrenWrapper) {
    childrenWrapper = Block.fromJSON(createChildrenWrapperJson());
    appendChild(editor, thisGigNode.key, childrenWrapper);
  }

  return childrenWrapper;
});