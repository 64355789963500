import isGigNode from './node/isGigNode';

export default (function (editor, key, dataKey, value) {
  var document = editor.value.document;

  var thisGigNode = document.getNode(key);
  if (!isGigNode(thisGigNode)) {
    throw new Error('Call updateValueByKey on gigNodes only.');
  }

  var data = thisGigNode.data;

  var newData = typeof value === 'undefined' ? data.delete(dataKey) : data.set(dataKey, value);
  editor.setNodeByKey(key, { data: newData });
});