import getClosestGigNodeByKey from './getClosestGigNodeByKey';

export default (function (editor, key) {
  var document = editor.value.document;


  var thisGigNode = getClosestGigNodeByKey(editor, key);

  // if our node is document.
  if (!thisGigNode) {
    return null;
  }

  var parent = document.getParent(thisGigNode.key);

  // We must be root.
  if (!parent || parent.object === 'document') {
    return null;
  }

  return getClosestGigNodeByKey(editor, parent.key);
});