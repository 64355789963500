var addPreventDefault = function addPreventDefault(fn) {
  return function (e) {
    e.preventDefault();
    fn(e);
  };
};

/*
 * See editorState.js on why we need editorValue despite not using it.
 */
export default (function (_ref) {
  var editor = _ref.editorState,
      editorValue = _ref.editorValue;

  if (editor == null) {
    return { loading: true };
  }

  var undo = addPreventDefault(editor.undo);
  var redo = addPreventDefault(editor.redo);
  var indent = addPreventDefault(editor.gigIndent);
  var dedent = addPreventDefault(editor.gigDedent);
  // Avoid passing the event to gigInsertTable. It accepts rows, cols
  // arguments.
  var insertTable = addPreventDefault(function () {
    return editor.gigInsertTable();
  });
  var addPendingTrace = addPreventDefault(function () {
    return editor.gigAddPendingTrace();
  });
  var addTrace = addPreventDefault(function (e) {
    return editor.gigAddTrace(e);
  });
  var onChangeFileInput = addPreventDefault(function (event) {
    // Note: If adding an image, deleting it, then adding again but
    // hitting cancel in the upload dialog, we get an empty
    // filelist here.
    editor.gigAddMedia(event.target.files);
  });

  var setLayout = function setLayout(layout) {
    editor.gigSetLayout(layout);
  };
  var layout = editor.gigGetLayout();

  return {
    loading: false,
    undo: undo,
    redo: redo,
    indent: indent,
    dedent: dedent,
    insertTable: insertTable,
    addTrace: addTrace,
    setLayout: setLayout,
    layout: layout,
    addPendingTrace: addPendingTrace,
    // TODO: Define plugin buttons inside the plugin!
    pendingTraceAtSelectionEnabled: editor.gigPendingTraceAtSelectionEnabled && editor.gigPendingTraceAtSelectionEnabled(),
    onChangeFileInput: onChangeFileInput,
    extractNodes: editor.gigExtractNodes
  };
});