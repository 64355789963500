import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/Seo'
import App from '../components/App'
import Layout from '../components/Layout'
import styled from 'styled-components'

const Notes = styled.div`
  background: #f8f8f8;
  & > * {
    display: inline-block;
    margin: 1em;
  }
`

export default () => (
  <Layout noHeader>
    <div
      style={{
        fontFamily: 'Lato, sans-serif !important',
      }}
    >
      <SEO title="Demo" />
      <Notes>
        <Link to="/#feedback">{'<<<'} Back to feedback</Link>
        {/*<p>Note: Use tab key to indent sections.</p>*/}
      </Notes>
      <App />
    </div>
  </Layout>
)
