import { Document } from '@gigmade/slate';
import { getEventRange, getEventTransfer } from '@gigmade/slate-react';
import { getClosestGigNode } from '../../utils';
import { isGigNode, isChildrenWrapper } from '../../utils/node';
import { hasShift } from '../keyboard';
import insertTextAtKey from './insertTextAtKey';
import changeData from './changeData';

/*
 * slatejs note: I shouldn't have to re-create this.
 */
var deleteExpanded = function deleteExpanded(editor) {
  var selection = editor.value.selection;


  if (selection.isExpanded) {
    editor.delete();
  }
};

/*
 * Return deepest single gigNode or children node.
 */
var deepestSingleContainer = function deepestSingleContainer(doc) {
  // result holds the found container.
  var result = null;
  // candidate iterates through fragment.
  var candidate = doc;

  while (candidate.nodes.size === 1 && candidate.nodes.first().object === 'block') {
    candidate = candidate.nodes.first();
    if (isGigNode(candidate) || isChildrenWrapper(candidate)) {
      result = candidate;
    }
  }

  return result;
};

/*
 * Remove the global uuids from the fragment.
 * TODO: Simplify? New controller just for this?
 * Future: case distinction:
 * - copy and paste -> remove ids
 * - cut and paste -> keep same ids
 * - attention if pasted ids exists in any other doc
 * - generalize above to keeping ids only if they don't exist anywhere.
 * - on removal, add a relation from new id to old!
 */
var removeUuids = changeData(function (node) {
  return node.data.remove('id');
});

var removeDiffs = changeData(function (node) {
  return node.data.removeIn(['temp', 'diff']);
});

var truncateFragment = function truncateFragment(fragment) {
  var startBlock = deepestSingleContainer(fragment);
  return startBlock ? Document.create([startBlock]) : fragment;
};

var onPaste = function onPaste(event, editor, next) {
  var transfer = getEventTransfer(event);
  var type = transfer.type,
      fragment = transfer.fragment,
      text = transfer.text;


  if (type === 'fragment') {
    var newFragment = removeDiffs(removeUuids(truncateFragment(fragment)));
    editor.insertFragment(newFragment);
    return;
  } else if (text && text.match('\n')) {
    // TODO: there should a more generalized onPaste handler for each contentType
    // Once those are put into their plugins
    // In addition, we will want to take depth cues from non-plugin constructs i
    // MS Word et al such as numbered headings, <h1>-<h6> tags, etc.
    // For now, this is a quick method to ensure that we are properly creating gig nodes
    // To avoid the somewhat buggy normalization process.
    var targetGigNode = getClosestGigNode(editor) || document;
    editor.withoutNormalizing(function () {
      if (editor.value.selection.isExpanded) {
        editor.delete();
      }
      insertTextAtKey(editor, targetGigNode.key, text);
    });
    return;
  }

  return next();
};

export default (function (options) {
  return {
    onPaste: onPaste,
    onDrop: function onDrop(event, editor, next) {
      var target = getEventRange(event, editor);

      if (target) {
        if (!hasShift(event)) {
          deleteExpanded(editor);
        }
        editor.select(target);
      }
      onPaste(event, editor, next);
    }
  };
});