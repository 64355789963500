export default (function (editor, node, newNode) {
  var document = editor.value.document;


  var parent = document.getParent(node.key);
  var index = parent.nodes.findIndex(function (n) {
    return n.key === node.key;
  });
  editor.insertNodeByKey(parent.key, index, newNode);
  editor.removeNodeByKey(node.key);
});