import { useRef, useEffect } from 'react';

export default (function (_ref) {
  var skip = _ref.skip,
      node = _ref.node,
      handler = _ref.handler;

  var origText = useRef(null);

  useEffect(function () {
    if (skip) {
      return;
    }

    if (origText.current == null) {
      // Set this one time to the original text
      origText.current = node.text;
      return;
    }

    var text = node.text;
    if (text !== origText.current) {
      origText.current = text;
      handler(text);
    }
  }, [node, skip, handler]);
});