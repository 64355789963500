/*
 * This renders the full map view.
 */

import React, { Fragment } from 'react';
import { Connectors } from './';
export default (function (_ref) {
  var children = _ref.children,
      tree = _ref.tree,
      layout2d = _ref.layout2d,
      getParent = _ref.getParent;

  if (!layout2d) {
    // Regular display, no map.
    return children;
  }

  return React.createElement(
    Fragment,
    null,
    children,
    React.createElement(Connectors, { tree: tree, type: layout2d, getParent: getParent })
  );
});