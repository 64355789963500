function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';

var WrapIfExists = function WrapIfExists(_ref) {
  var Component = _ref.Component,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ['Component', 'children']);

  if (!Component) {
    return children;
  }

  return React.createElement(
    Component,
    props,
    children
  );
};

export default (function (_ref2) {
  var RootWrapper = _ref2.RootWrapper;

  var renderEditor = function renderEditor(props, editor, next) {
    return React.createElement(
      WrapIfExists,
      { Component: RootWrapper },
      next()
    );
  };

  return { renderEditor: renderEditor };
});