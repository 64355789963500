import styled from 'styled-components'
import { Button } from './ui'

export const TopBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: row nowrap;
  max-height: 51px;
  min-height: 50px;
  height: 51px;
  border-bottom: 1px solid #eee;
`

export const RightToolbar = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${Button} {
    width: auto;
    height: 100%;
  }
`

export const Toolbar = styled.nav`
  display: flex;
  min-height: 40px;
  flex-direction: row;
  border-bottom: 1px solid #eee;
`
