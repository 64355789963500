import React, { Fragment, createRef } from 'react';
import { useEditorControls } from '../../..';

var attachmentInputRef = createRef();

export default (function (_ref) {
  var value = _ref.value;

  var _useEditorControls = useEditorControls({ editorState: value ? value.editorState : null }),
      loading = _useEditorControls.loading,
      insertTable = _useEditorControls.insertTable,
      onChangeFileInput = _useEditorControls.onChangeFileInput,
      addTrace = _useEditorControls.addTrace,
      addPendingTrace = _useEditorControls.addPendingTrace,
      setLayout = _useEditorControls.setLayout;

  if (loading) {
    return null;
  }

  return React.createElement(
    Fragment,
    null,
    React.createElement(
      'button',
      { onClick: function onClick() {
          return setLayout('');
        } },
      'Default Layout'
    ),
    React.createElement(
      'button',
      { onClick: function onClick() {
          return setLayout('right');
        } },
      'Right Layout'
    ),
    React.createElement(
      'button',
      { onClick: function onClick() {
          return setLayout('down');
        } },
      'Down Layout'
    ),
    React.createElement('input', {
      type: 'file',
      ref: attachmentInputRef,
      onChange: onChangeFileInput
    })
  );
});