/*
 * This saves the internal editor state with all aspects such as selection, undo history.
 * Currently, we only need that to connect the EditorControls with the editor.
 */

import makeSubscription from '../makeSubscription'

const { notifySubscribers, subscribe } = makeSubscription()
export { subscribe }

/*
 * Contains the differ object.
 */
let editorState = null

export const setEditorState = newState => {
  editorState = newState
  notifySubscribers()
}

export const getEditorState = () => editorState

/*
 * FIXME: Just adding getEditorValue because editorState is the slate controller
 * and that is mutable.
 * I needed that to get the Editor toolbar to update on editor updates.
 */
export const getEditorValue = () => {
  return editorState ? editorState.value : null
}
