import React from 'react'
import Editor from './Editor'
import Nav from './Nav'
import styled from 'styled-components'

const Wrapper = styled.div`
  visibility: ${props => (props.hide ? 'hidden' : 'inherit')};
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  height: 100vh;
`
export const InnerWrapper = styled.div`
  position: relative;
  flex: 1;
  min-height: 100px;
`

export default () => {
  return (
    <Wrapper hide={false}>
      <Nav />
      <InnerWrapper>
        <Editor readOnly={false} error={false} loading={false} />
      </InnerWrapper>
    </Wrapper>
  )
}
