/*
 * Inserting a text node between two non-text nodes may be difficult.
 * This function solves this with a click handler that inserts an empty text
 * node when a non-text node's padding is clicked.
 */
import { insertAndSelectEmptyGigNodeSiblingByKey, isRootGigNodeByKey, getClosestGigNodeAncestorNotInContainer, getLastGigNode, getPreviousGigNodeByKey, getNextGigNodeByKey } from '../utils';
import { isTextGigNode } from '../utils/node';

export default (function (editor, node) {
  var prev = getPreviousGigNodeByKey(editor, node.key);
  var lastOutsidePrev = prev ? getClosestGigNodeAncestorNotInContainer(editor, prev.key) : null;
  var prevIsText = prev && isTextGigNode(lastOutsidePrev);
  var next = getNextGigNodeByKey(editor, node.key);
  var lastOutsideNext = next ? getClosestGigNodeAncestorNotInContainer(editor, next.key) : null;
  var nextIsText = next && isTextGigNode(lastOutsideNext);
  // Special handling for root even if it is text node.
  // Because root's bottom padding is lowest in editor view.
  if (isRootGigNodeByKey(editor, node.key)) {
    return function (topPadding) {
      if (topPadding) {
        return;
      }
      var lastNode = getLastGigNode(editor);
      // If lastNode is inside a table, we need to insert at the level of the
      // table or we break the structure, or we find a text but really our
      // neighbor is a table.
      var lastOutsideNode = getClosestGigNodeAncestorNotInContainer(editor, lastNode.key);
      if (isTextGigNode(lastOutsideNode)) {
        return;
      }
      insertAndSelectEmptyGigNodeSiblingByKey(editor, lastOutsideNode.key, false);
    };
  }
  if (isTextGigNode(node)) {
    return function () {};
  }
  return function (topPadding) {
    // if any neighbor of our padding click is text, do not execute it.
    if (topPadding && prevIsText || !topPadding && nextIsText) {
      return;
    }
    return insertAndSelectEmptyGigNodeSiblingByKey(editor, node.key, topPadding);
  };
});