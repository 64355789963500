/*
 * Move "child" node into "parent" node at index.
 * Note: This is plumbing, not used for adding children to
 * gigNode.
 */
export default (function (editor, parentKey, child, index) {
  var document = editor.value.document;

  if (document.hasNode(child.key)) {
    editor.moveNodeByKey(child.key, parentKey, index);
    return;
  }

  editor.insertNodeByKey(parentKey, index, child);
});