var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import { getChildrenGigNodesByKey, getClosestGigNodeByKey, getParentGigNodeByKey, getLeftSiblingGigNodeByKey, insertSiblingGigNode, insertChildGigNode } from '../../utils';
import gigNodeFromDiffedKey from '../gigNodeFromDiffedKey';
import diffToggles from '../diffToggles';
import updateCache from './updateCache';

import produce from 'immer';

var FINALIZED = 'finalized';

var keyOf = function keyOf(node) {
  return node ? node.key : null;
};

/* TODO: handle the case where my variant is:
 * Untitled
 *   Something
 *
 * and theirs is:
 * Something
 *
 * i.e. a springer with leftSibling and parent both null.
 */
function calculateCache(editor, gigNodeKey, springerKeyInfo) {
  var targetBlocks = springerKeyInfo.data.map(function (targetInfo) {
    var parent = targetInfo.parent,
        leftSibling = targetInfo.leftSibling;

    var gigParent = gigNodeFromDiffedKey(editor, parent);
    // For now, search globally and don't pass gigParent as a last param to
    // gigNodeFromDiffedKey. This is because gigParent in the config currently
    // active in slate may not be the leftSibling's parent.
    // TODO: when passing both parents, this might work and speed up things
    // however..
    var gigLeftSibling = leftSibling == null ? null : gigNodeFromDiffedKey(editor, leftSibling);

    return {
      leftSiblingKey: keyOf(gigLeftSibling),
      parentKey: keyOf(gigParent)
    };
  });

  return [{
    key: gigNodeKey,
    cache: {
      data: targetBlocks /* {
                         leftSiblingKey: gigLeftSibling ? gigLeftSibling.key : null,
                         parentKey: gigParent ? gigParent.key : null
                         }*/
      , // If the springer is
      accepted: springerKeyInfo.accepted
    }
  }];
}

var isAccepted = function isAccepted(options) {
  return function () {
    var toggleCache = options.toggleCache;

    return toggleCache.accepted;
  };
};

function getCurrLeftSiblingAndParentKeys(editor, key) {
  // Note: we want to store the node's current prevSibling/parent so
  // next toggle it goes to the current place (if edited)
  var currLeftSibling = getLeftSiblingGigNodeByKey(editor, key);
  var currParent = getParentGigNodeByKey(editor, key);
  return {
    leftSiblingKey: currLeftSibling ? currLeftSibling.key : null,
    parentKey: currParent.key
  };
}

/*
 * In a first step, we would move all dragAlong
 */
var executeDragAlongs = function executeDragAlongs(editor, options, leaderKey, dragAlongSpringers) {
  var dependents = void 0;

  editor.withoutNormalizing(function () {
    // First, just place everyone in the same sequence as originally found.
    dragAlongSpringers.slice().reverse().forEach(function (key) {
      insertSiblingGigNode(editor, leaderKey, key);
    });
    // Then, for some of these springers, they actually have the new parent
    // as their resulting parent. For them, we execute them in order for them
    // to make any adjustments to their relative order.
    var dependentOfKey = function dependentOfKey(key) {
      return diffToggles.getSpringer(editor, key).isDependentOf(options.gigNodeKey);
    };

    dependents = dragAlongSpringers.filter(dependentOfKey);

    dependents.forEach(function (key) {
      var diffToggle = diffToggles.getSpringer(editor, key);
      diffToggle.onToggle();
    });
  });

  return dependents;
};

var onToggle = function onToggle(editor, options) {
  return function () {
    var gigNodeKey = options.gigNodeKey,
        toggleCache = options.toggleCache,
        setToggleCache = options.setToggleCache;
    // This is where we jump to.
    // Find "the other" index.

    var targetSideIndex = toggleCache.accepted ? 0 : 1;

    var _toggleCache$data$tar = toggleCache.data[targetSideIndex],
        leftSiblingKey = _toggleCache$data$tar.leftSiblingKey,
        parentKey = _toggleCache$data$tar.parentKey;

    var dragAlong = toggleCache.dragAlong || [];

    // Note: the "other side" parentKey may refer to the leftSiblings parent which
    // could also be different depending on accept state. Since our accept state
    // is not necessarily the same as the other siblings accept state, we need to
    // never use the parentKey unless our sibling key is null.
    var actualParentKey = leftSiblingKey == null ? parentKey : getParentGigNodeByKey(editor, leftSiblingKey).key;

    var insertIndex = leftSiblingKey == null ? 0 : 1 + getChildrenGigNodesByKey(editor, actualParentKey).findIndex(function (node) {
      return node.key === leftSiblingKey;
    });

    var currentGigNode = getClosestGigNodeByKey(editor, gigNodeKey);

    var newData = produce(toggleCache.data, function (draft) {
      draft[1 - targetSideIndex] = getCurrLeftSiblingAndParentKeys(editor, gigNodeKey);
    });

    var dependents = void 0;
    editor.withoutNormalizing(function () {
      insertChildGigNode(editor, actualParentKey, currentGigNode, insertIndex);

      dependents = dragAlong ? executeDragAlongs(editor, options, currentGigNode.key, dragAlong) : // The caller relies on a populated dependents return value.
      [];

      setToggleCache(_extends({}, toggleCache, {
        data: newData,
        accepted: !isAccepted(options)(toggleCache)
      }));
    });

    return dependents;
  };
};

var setCacheFinalized = function setCacheFinalized(_ref) {
  var _extends2;

  var toggleCache = _ref.toggleCache,
      setToggleCache = _ref.setToggleCache;

  setToggleCache(_extends({}, toggleCache, (_extends2 = {}, _extends2[FINALIZED] = true, _extends2)));
};

// FIXME: TODO here and in childseq, if accepted !== isAccepted()
var onFinalize = function onFinalize(editor, options) {
  return function (accepted) {
    var recursive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    editor.focus();

    editor.withoutNormalizing(function () {
      // We only toggle dependents as part of toggling their leaders, and we
      // call finalize on all of them in this very function, therefore the
      // !isDependent exclusion here.
      var dependents = [];

      if (accepted && !isDependent(options)()) {
        dependents = onToggle(editor, options)();
      }

      // When finalizing others, the assumption is that the dependents move exactly
      // in sync with the leader key, and there is no need to check them for
      // finalized state. Just toggle them as well, and finalize them.
      dependents.forEach(function (key) {
        // They are toggled (accepted) together with their leaders, so no accept arg needed.
        diffToggles.getSpringer(editor, key).onFinalize(accepted, true);
      });

      setCacheFinalized(options);
    });

    // This is if we had others moving along - in this case, may want to
    // immediately update the cache with new dragAlong/dependent info.
    if (!recursive) {
      updateCache(editor);
    }
  };
};

/*
 * Get the current operation, which is the one not corresponding to current
 * accept state.
 */
var getOperation = function getOperation(options) {
  return function () {
    var toggleCache = options.toggleCache;
    // Find "the other" index.

    var targetSideIndex = toggleCache.accepted ? 0 : 1;

    return _extends({}, toggleCache.data[targetSideIndex], {
      dragAlong: toggleCache.dragAlong,
      dependent: toggleCache.dependent
    });
  };
};

var isDependent = function isDependent(options) {
  return function () {
    var toggleCache = options.toggleCache;

    return toggleCache.dependent != null;
  };
};

var isDependentOf = function isDependentOf(options) {
  return function (leaderCandidate) {
    var toggleCache = options.toggleCache;

    return isDependent(options)() && toggleCache.dependent === leaderCandidate;
  };
};

/*
 * Note: if no blocks for a target situation can be identified (we had but in
 * 6b327 excluded a situation where leftSibling is null, parent is mergeRoot
 * but mergeRoot with key 0 but which is not sent for display and thus resolves
 * to null too), missingKeys may be [null]
 */
var handleMissingKeys = function handleMissingKeys(options) {
  return function (missingKeys) {
    // For now we can't really figure out a replacement position.
    // Deactivate the toggle.
    setCacheFinalized(options);
  };
};

export default {
  calculateCache: calculateCache,
  isToggle: function isToggle(_ref2) {
    var gigNodeKey = _ref2.gigNodeKey,
        toggleCache = _ref2.toggleCache;

    return toggleCache != null && toggleCache[FINALIZED] !== true && !isDependent({ toggleCache: toggleCache })();
  },
  getToggle: function getToggle(editor, options) {
    return {
      isAccepted: isAccepted(options),
      onToggle: onToggle(editor, options),
      onFinalize: onFinalize(editor, options),
      getOperation: getOperation(options),
      isDependent: isDependent(options),
      isDependentOf: isDependentOf(options),
      handleMissingKeys: handleMissingKeys(options)
    };
  },
  updateCache: updateCache
};