var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  /*background: #f0f0f0;*/\n  border-radius: 3px;\n  padding: 0.1em;\n  display: inline-block;\n  /* Our outmost block render element, TreeNodeWrapper, is inline-block\n   * because otherwise the focus plugin does not neatly draw around it\n   * when focused. But then, we need to define any width constraints of\n   * a plugin in "absolute" terms otherwise it\'s going to be percentages\n   * of inner content.\n   */\n  max-width: 25vw;\n  box-sizing: border-box;\n'], ['\n  /*background: #f0f0f0;*/\n  border-radius: 3px;\n  padding: 0.1em;\n  display: inline-block;\n  /* Our outmost block render element, TreeNodeWrapper, is inline-block\n   * because otherwise the focus plugin does not neatly draw around it\n   * when focused. But then, we need to define any width constraints of\n   * a plugin in "absolute" terms otherwise it\'s going to be percentages\n   * of inner content.\n   */\n  max-width: 25vw;\n  box-sizing: border-box;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  line-height: 1em;\n  padding: 0.5em 0;\n  font-size: 0.7em;\n'], ['\n  line-height: 1em;\n  padding: 0.5em 0;\n  font-size: 0.7em;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  word-break: break-word;\n'], ['\n  word-break: break-word;\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  font-weight: bold;\n  margin-bottom: 0.3em;\n'], ['\n  font-weight: bold;\n  margin-bottom: 0.3em;\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: row;\n  white-space: nowrap;\n'], ['\n  display: flex;\n  flex-direction: row;\n  white-space: nowrap;\n']),
    _templateObject6 = _taggedTemplateLiteralLoose(['\n  color: #666;\n  margin-right: 0.3em;\n  font-size: 0.8em;\n\n  a {\n    text-decoration: underline;\n    color: #333;\n  }\n'], ['\n  color: #666;\n  margin-right: 0.3em;\n  font-size: 0.8em;\n\n  a {\n    text-decoration: underline;\n    color: #333;\n  }\n']),
    _templateObject7 = _taggedTemplateLiteralLoose(['\n  max-width: ', ';\n  & > * {\n    max-width: 100%;\n  }\n'], ['\n  max-width: ', ';\n  & > * {\n    max-width: 100%;\n  }\n']),
    _templateObject8 = _taggedTemplateLiteralLoose(['\n  padding: 0 0.8em 0 0.8em;\n  border: 1px solid #bbb;\n  border-radius: 8px;\n'], ['\n  padding: 0 0.8em 0 0.8em;\n  border: 1px solid #bbb;\n  border-radius: 8px;\n']),
    _templateObject9 = _taggedTemplateLiteralLoose(['\n  text-align: center;\n  color: \'#c3c1c4\';\n  margin-top: -1em;\n  margin-bottom: 1em;\n  font-size: 0.6em;\n  ', '\n'], ['\n  text-align: center;\n  color: \'#c3c1c4\';\n  margin-top: -1em;\n  margin-bottom: 1em;\n  font-size: 0.6em;\n  ', '\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { Fragment, useMemo } from 'react';
import { Spinner } from '@gigmade/msm-ui';
import styled from 'styled-components';

import useLoadMedia from './useLoadMedia';
import filesize from 'filesize';
import LoadFromCloud from './LoadFromCloud';

var stopEvent = function stopEvent(e) {
  e.preventDefault();
  e.stopPropagation();
};

var Wrapper = styled.div(_templateObject);

var InfoBar = styled.div(_templateObject2);

var NameWrapper = styled.div(_templateObject3);

var NameSpan = styled.span(_templateObject4);

var Details = styled.div(_templateObject5);

var Detail = styled.span(_templateObject6);

var MediaContainer = styled.div(_templateObject7, function (props) {
  return props.limitWidth ? '6.5rem' : 'none';
});

var DownloadContainer = styled.div(_templateObject8);

var DownloadMe = styled.div(_templateObject9, function (props) {
  return props.isLoading ? 'pointer-events: none;' : '\n  cursor: pointer;\n  text-decoration: underline;\n  ';
});

var EmptyObject = function EmptyObject(_ref) {
  var onClick = _ref.onClick,
      loading = _ref.loading;

  return React.createElement(
    DownloadContainer,
    null,
    React.createElement(LoadFromCloud, { color: '#c3c1c4' }),
    React.createElement(
      DownloadMe,
      { isLoading: loading, onClick: onClick, onMouseDown: stopEvent },
      'Download',
      loading ? 'ing...' : ''
    )
  );
};

var BreakableNameText = function BreakableNameText(_ref2) {
  var _ref2$name = _ref2.name,
      name = _ref2$name === undefined ? '' : _ref2$name;

  var parts = useMemo(function () {
    return name.replace(/[-_.]/g, function (x) {
      return '/' + x;
    }).split('/');
  }, [name]);

  return React.createElement(
    Fragment,
    null,
    parts[0],
    parts.slice(1).map(function (part, i) {
      return React.createElement(
        Fragment,
        { key: i },
        React.createElement('wbr', null),
        part
      );
    })
  );
};

var MediaWrapper = function MediaWrapper(_ref3) {
  var value = _ref3.value,
      domProps = _ref3.domProps,
      Renderer = _ref3.renderer,
      fileLoader = _ref3.fileLoader;
  var _value$isLoading = value.isLoading,
      uploading = _value$isLoading === undefined ? false : _value$isLoading,
      mimeType = value.mimeType,
      name = value.name,
      size = value.size,
      hash = value.hash;
  // Could be whole name if no dots. Will shorten inside svg.

  var extension = name.substr(name.lastIndexOf('.') + 1).toLowerCase();

  var _useLoadMedia = useLoadMedia({ fileLoader: fileLoader, hash: hash }),
      src = _useLoadMedia.src,
      fetch = _useLoadMedia.fetch,
      fetching = _useLoadMedia.loading;

  var renderer = src ?
  // Note: Only the ImageType is doing something with the src prop,
  // only AttachmentType uses the extension prop.
  React.createElement(Renderer, { src: src, extension: extension }) : React.createElement(EmptyObject, {
    loading: fetching,
    onClick: function onClick(e) {
      stopEvent(e);
      fetch();
    }
  });

  return React.createElement(
    Wrapper,
    _extends({}, domProps, { contentEditable: false }),
    React.createElement(
      MediaContainer,
      { limitWidth: !src },
      renderer
    ),
    React.createElement(
      InfoBar,
      null,
      React.createElement(
        NameWrapper,
        null,
        (uploading || fetching) && React.createElement(Spinner, null),
        React.createElement(
          NameSpan,
          null,
          React.createElement(BreakableNameText, { name: name })
        )
      ),
      React.createElement(
        Details,
        null,
        React.createElement(
          Detail,
          null,
          filesize(size, { round: 0 })
        ),
        React.createElement(
          Detail,
          null,
          mimeType
        ),
        !(uploading || fetching) && src && React.createElement(
          Detail,
          { onClick: function onClick(e) {
              return e.stopPropagation();
            }, onMouseDown: stopEvent },
          React.createElement(
            'a',
            {
              download: name,
              href: src,
              target: '_blank',
              rel: 'noopener noreferrer'
            },
            'Download'
          )
        )
      )
    )
  );
};

export default MediaWrapper;