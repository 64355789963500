var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useContext } from 'react';
import { DiffIndicatorContext } from '../editPlugins/diffIndicator';

import AcceptIgnore, { ShowOnHover } from './AcceptIgnore';
import getMoveOverlayPositions from './getMoveOverlayPositions';
import CrossThrough from './CrossThrough';
import diffToggles from './diffToggles';
import shapeByType, { INDENT, DEDENT } from './shapeByType';
import Arrow from './Arrow';
import times from 'lodash/times';
import { getPreviousGigNodeByKey, getParentGigNodeByKey, isEmptyTextGigNodeByKey } from '../utils';
import { hasDefaultContentType } from '../utils/node';

import { ALT, INVERT, SWAP, DELETE, INSERT, SPRINGER } from '@gigmade/msm-merge-util';

var defaultTippyOptions = {
  placement: 'top',
  flipBehavior: ['bottom', 'right', 'left'],
  followCursor: 'initial'
};

function labelByType(type, indentType) {
  switch (type) {
    /* eslint-disable no-fallthrough */
    case SPRINGER:
      if (indentType) {
        return 'move section ' + (indentType === DEDENT ? 'left' : 'right');
      }
    case INVERT:
      return 'move section';
    case ALT:
      return 'order section';
    case SWAP:
      return 'swap sections';
    case DELETE:
      return 'delete section';
    case INSERT:
      return 'add section';
    default:
      throw new Error('Unknown child sequence type');
  }
}

var DeleteOverlay = function DeleteOverlay(_ref) {
  var deleteToggle = _ref.deleteToggle,
      editor = _ref.editor,
      node = _ref.node,
      dimensions = _ref.dimensions;
  var onFinalize = deleteToggle.onFinalize;

  var _useContext = useContext(DiffIndicatorContext),
      diffPositions = _useContext.diffPositions;
  // In the `hasDefaultContentType(node)` case, the inline plugin takes care of
  // sending our toggle's "block `onFinalize` function" to the text plugin which
  // will replace the default inline based one with it.

  if (hasDefaultContentType(node) && !isEmptyTextGigNodeByKey(editor, node.key)) {
    return null;
  }

  var Overlay = shapeByType(DELETE);
  var moveOverlayProps = getMoveOverlayPositions({
    keys: [node.key],
    type: DELETE,
    positions: diffPositions
  });

  return React.createElement(
    AcceptIgnore,
    _extends({
      type: labelByType(DELETE),
      onFinalize: onFinalize,
      interactiveBorder: 10
    }, defaultTippyOptions),
    React.createElement(
      ShowOnHover,
      { content: React.createElement(Overlay, moveOverlayProps) },
      React.createElement(CrossThrough, { dimensions: dimensions })
    )
  );
};

var InsertOverlay = function InsertOverlay(_ref2) {
  var insertToggle = _ref2.insertToggle,
      node = _ref2.node,
      dimensions = _ref2.dimensions;
  var onFinalize = insertToggle.onFinalize;

  var _useContext2 = useContext(DiffIndicatorContext),
      diffPositions = _useContext2.diffPositions;

  if (hasDefaultContentType(node)) {
    return null;
  }

  var Overlay = shapeByType(DELETE);
  var moveOverlayProps = getMoveOverlayPositions({
    keys: [node.key],
    type: INSERT,
    positions: diffPositions
  });

  return React.createElement(
    AcceptIgnore,
    _extends({
      type: labelByType(INSERT),
      onFinalize: onFinalize,
      interactiveBorder: 10
    }, defaultTippyOptions),
    React.createElement(
      ShowOnHover,
      { content: React.createElement(Overlay, moveOverlayProps) },
      React.createElement(CrossThrough, { rotate: 45, dimensions: dimensions })
    )
  );
};

var SpringerOverlay = function SpringerOverlay(_ref3) {
  var springerToggle = _ref3.springerToggle,
      editor = _ref3.editor,
      node = _ref3.node,
      parent = _ref3.parent,
      dimensions = _ref3.dimensions;
  var onFinalize = springerToggle.onFinalize,
      getOperation = springerToggle.getOperation,
      handleMissingKeys = springerToggle.handleMissingKeys;

  var _useContext3 = useContext(DiffIndicatorContext),
      diffPositions = _useContext3.diffPositions;

  function isDedent(from, to) {
    var fromParent = getParentGigNodeByKey(editor, from);
    return fromParent && fromParent.key === to;
  }

  function isIndent(from, to) {
    var prevNode = getPreviousGigNodeByKey(editor, from);
    return prevNode && prevNode.key === to;
  }

  var type = SPRINGER;

  var _getOperation = getOperation(),
      leftSiblingKey = _getOperation.leftSiblingKey,
      parentKey = _getOperation.parentKey,
      dragAlong = _getOperation.dragAlong;

  // If leftSiblingKey is defined, its bottom is our move target.
  // Otherwise, it's the parent's bottom.


  var to = leftSiblingKey == null ? parentKey : leftSiblingKey;
  var from = node.key;

  var _getMoveOverlayPositi = getMoveOverlayPositions({
    parent: parent,
    keys: { from: from, to: to, dragAlong: dragAlong },
    type: type,
    positions: diffPositions
  }),
      notFoundKeys = _getMoveOverlayPositi.notFoundKeys,
      movesUp = _getMoveOverlayPositi.movesUp,
      moveOverlayProps = _objectWithoutProperties(_getMoveOverlayPositi, ['notFoundKeys', 'movesUp']);

  if (notFoundKeys && notFoundKeys.length) {
    handleMissingKeys(notFoundKeys);
    return null;
  }

  // Since we try to place indent springers after their prospective parents,
  // it means if the "to" is the node just before, this is an indent change
  // Plus, some springers might be placed within a sequence of children but become
  // indented as siblings to their parents
  // const isIndentChange = prevNode && prevNode.key === to
  var indentType = isDedent(from, to) ? DEDENT : isIndent(from, to) ? INDENT : null;

  var Overlay = shapeByType(type, indentType);

  var rotate = indentType ? indentType === DEDENT ? 180 : 0 : movesUp ? 90 : 270;

  return React.createElement(
    AcceptIgnore,
    _extends({
      type: labelByType(type, indentType),
      onFinalize: onFinalize,
      interactiveBorder: 10
    }, defaultTippyOptions),
    React.createElement(
      ShowOnHover,
      { content: React.createElement(Overlay, moveOverlayProps) },
      React.createElement(Arrow, { rotate: rotate, dimensions: dimensions })
    )
  );
};

// TODO: Less redirection with dimensions prop?
// Just register a handler in the indicator?
var ChildSeqOverlay = function ChildSeqOverlay(_ref4) {
  var childSeqToggle = _ref4.childSeqToggle,
      parent = _ref4.parent,
      dimensions = _ref4.dimensions;
  var getToggleCount = childSeqToggle.getToggleCount,
      getOperation = childSeqToggle.getOperation,
      onFinalize = childSeqToggle.onFinalize,
      handleMissingKeys = childSeqToggle.handleMissingKeys,
      handleMovedOutKeys = childSeqToggle.handleMovedOutKeys;

  var _useContext4 = useContext(DiffIndicatorContext),
      diffPositions = _useContext4.diffPositions;

  var top = dimensions.top,
      height = dimensions.height;

  // create a div with countx height, 1x width

  return times(getToggleCount(), function (i) {
    var cueDimensions = _extends({}, dimensions, {
      top: top + i * height
    });

    var _getOperation2 = getOperation(i),
        from = _getOperation2.from,
        to = _getOperation2.to,
        type = _getOperation2.type;

    var _getMoveOverlayPositi2 = getMoveOverlayPositions({
      parent: parent,
      keys: { from: from, to: to },
      type: type,
      positions: diffPositions
    }),
        notFoundKeys = _getMoveOverlayPositi2.notFoundKeys,
        movedKeys = _getMoveOverlayPositi2.movedKeys,
        movesUp = _getMoveOverlayPositi2.movesUp,
        moveOverlayProps = _objectWithoutProperties(_getMoveOverlayPositi2, ['notFoundKeys', 'movedKeys', 'movesUp']);

    if (notFoundKeys && notFoundKeys.length) {
      handleMissingKeys(i, notFoundKeys);
      return null;
    }

    if (movedKeys && movedKeys.length) {
      handleMovedOutKeys(i, movedKeys);
      return null;
    }

    var Overlay = shapeByType(type);

    // Alternative: keep AcceptIgnore in content={}
    return React.createElement(
      AcceptIgnore,
      _extends({
        key: i,
        type: labelByType(type),
        onFinalize: onFinalize(i),
        interactiveBorder: 10
      }, defaultTippyOptions),
      React.createElement(
        ShowOnHover,
        { content: React.createElement(Overlay, _extends({ key: 0 }, moveOverlayProps)) },
        React.createElement(Arrow, { rotate: movesUp ? 90 : 270, dimensions: cueDimensions })
      )
    );
  });
};

/*
 * This returns a component that accepts `dimensions` props
 */
export default (function (_ref5) {
  var parent = _ref5.parent,
      editor = _ref5.editor,
      node = _ref5.currentGigNode,
      dimensions = _ref5.dimensions;

  return diffToggles.isDelete(editor, node.key) ? DeleteOverlay({
    deleteToggle: diffToggles.getDelete(editor, node.key),
    editor: editor,
    node: node,
    dimensions: dimensions
  }) : diffToggles.isInsert(editor, node.key) ? InsertOverlay({
    insertToggle: diffToggles.getInsert(editor, node.key),
    node: node,
    dimensions: dimensions
  }) : diffToggles.isSpringer(editor, node.key) ? SpringerOverlay({
    springerToggle: diffToggles.getSpringer(editor, node.key),
    parent: parent,
    editor: editor,
    node: node,
    dimensions: dimensions
  }) : diffToggles.isShortMoveRep(editor, node.key) ? ChildSeqOverlay({
    childSeqToggle: diffToggles.getShortMoveRep(editor, node.key),
    parent: parent,
    dimensions: dimensions
  }) : null;
});