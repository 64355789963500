import React, { useMemo } from 'react';
import { DndProvider } from './DndContext';

export default (function (_ref) {
  var children = _ref.children,
      tree = _ref.tree,
      onAfterCallback = _ref.onAfterCallback,
      onBeforeCallback = _ref.onBeforeCallback;

  var callbacks = useMemo(function () {
    return { onAfterCallback: onAfterCallback, onBeforeCallback: onBeforeCallback };
  }, [onAfterCallback, onBeforeCallback]);

  return React.createElement(
    DndProvider,
    { callbacks: callbacks, tree: tree },
    children
  );
});