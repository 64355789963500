function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

/*
 * This is about dealing with newlines.
 * Background:
 * We don’t allow empty lines because they have no diffing semantics associated
 * and may confuse comparisons.
 * However, I need to preserve empty lines close to the cursor to not mess up
 * the editing experience for users. The choice is to keep previous and next lines.
 * This is done in a debounced global clean-up.
 * The challenge was to "check left sibling of a node when the cursor jumped away”,
 * and handling paste/other scenarios where empty lines are introduced.
 *
 * On exporting from the editor, we use a second editor controller which has
 * empty lines removed independently of the user's cursor.
 */
import { Editor as Controller } from '@gigmade/slate';
import { cleanEditorState } from '../utils';
// import cleanEmptyNode from './cleanEmptyNode'
import debounce from 'lodash/debounce';

var CLEAN_INTERVAL = 150;

/*
 * This function cleans up any newlines, including selected ones, so I'm
 * operating on a clone controller in order not to modify the active
 * editor's state and remove newlines that a user currently operates on.
 */
var makeGigGetCleanValue = function makeGigGetCleanValue(plugins, copyProps) {
  var newEditor = void 0;

  return function (editor, postProcessing) {
    if (!newEditor) {
      newEditor = new Controller({ plugins: plugins });
      newEditor.deselect();
    }

    // This is important e.g. for `postProcess`/`removePendingStructure`
    // because the props are used to check whether the editor is currently
    // diffing.
    newEditor.props = Object.assign.apply(Object, [{}].concat(copyProps.map(function (key) {
      var _ref;

      return _ref = {}, _ref[key] = editor.props[key], _ref;
    })));

    newEditor.setValue(editor.value);
    newEditor.withoutSaving(function () {
      cleanEditorState(newEditor, { editMode: false });
      if (postProcessing) {
        postProcessing(newEditor);
      }
    });
    return newEditor.value;
  };
};

var debouncedClean = debounce(function (editor, _ref2) {
  var getCleanNodePaused = _ref2.getCleanNodePaused,
      options = _objectWithoutProperties(_ref2, ['getCleanNodePaused']);

  if (!getCleanNodePaused()) {
    cleanEditorState(editor, options);
  }
}, CLEAN_INTERVAL);

export default (function (_ref3) {
  var plugins = _ref3.plugins,
      copyProps = _ref3.copyProps,
      getCleanNodePaused = _ref3.getCleanNodePaused;

  return {
    queries: {
      gigGetCleanValue: makeGigGetCleanValue(plugins, copyProps)
    },
    onChange: function onChange(editor, next) {
      next();
      debouncedClean(editor, { editMode: true, getCleanNodePaused: getCleanNodePaused });
    }
    /*
     * cleanEmptyNode doesn't work as expected.
     * It was an attempt to streamline the cleaning of empty lines
     * with the normalizeNode. This was in order to have further
     * restrictions such as not cleaning table cells, which are
     * limited to the table plugin and the table plugin can
     * choose whether or not to run this regular cleaning.
     * TODO: We can try again, and inside cleanEmptyNode,
     * using a (long living) cache of keys that have empty
     * text. Not sure how long keys live, otherwise use ids?
     * Then on each run we check if there are keys outside
     * selection vicinity and delete those.
     */
    // normalizeNode: cleanEmptyNode({ editMode: true })
  };
});