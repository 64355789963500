var _toggleDefinitions;

import { forEachGigNode, getGigNodeByKey, unwrapRemoveGigNodeByKey, getChildrenGigNodesByKey } from '../utils';
import { cacheExists, getToggleCache, setCache, merge, addToCache } from './diffCache';

import getDiffObj from './getDiffObj';
import { DELETE, INSERT, SPRINGER, CHILD_SEQUENCE, ROOT } from '@gigmade/msm-merge-util';

import springerToggles from './springerToggles';
import childSeqToggles from './childSeqToggles';

import produce from 'immer';

var FINALIZED = 'finalized';

function moveSectionBeforeFinalize(editor, currentGigNode) {
  if (editor.value.selection.isBlurred) {
    // Later, move to end of content. But not to end of gigNode
    // because that could mean beyond children.
    editor.moveToStartOfNode(currentGigNode);
    editor.moveToEndOfNextText();
    editor.focus();
  }
}

var toggleDefinitions = (_toggleDefinitions = {}, _toggleDefinitions[SPRINGER] = springerToggles, _toggleDefinitions[CHILD_SEQUENCE] = childSeqToggles, _toggleDefinitions[INSERT] = {
  calculateCache: function calculateCache(editor, gigNodeKey, insertKeyInfo) {
    return [{
      key: gigNodeKey,
      cache: { data: insertKeyInfo.accepted }
    }];
  },
  isToggle: function isToggle(_ref) {
    var toggleCache = _ref.toggleCache;

    return toggleCache != null && toggleCache[FINALIZED] !== true;
  },
  getToggle: function getToggle(editor, _ref2) {
    var gigNodeKey = _ref2.gigNodeKey,
        toggleCache = _ref2.toggleCache,
        setToggleCache = _ref2.setToggleCache;
    return {
      isAccepted: function isAccepted() {
        return toggleCache.data;
      },
      onToggle: function onToggle() {
        setToggleCache(produce(toggleCache, function (draft) {
          draft.data = !toggleCache.data;
        }));
      },
      onFinalize: function onFinalize(accepted) {
        var currentGigNode = getGigNodeByKey(editor, gigNodeKey);
        editor.withoutNormalizing(function () {
          moveSectionBeforeFinalize(editor, currentGigNode);

          // editor.moveToStartOfNextBlock()
          setToggleCache(produce(toggleCache, function (draft) {
            draft[FINALIZED] = true;
          }));
          if (!accepted) {
            editor.moveToEndOfPreviousBlock();
            unwrapRemoveGigNodeByKey(editor, currentGigNode.key);
          }
        });
      }
    };
  }
}, _toggleDefinitions[DELETE] = {
  calculateCache: function calculateCache(editor, gigNodeKey, deleteKeyInfo) {
    return [{
      key: gigNodeKey,
      cache: { data: deleteKeyInfo.accepted }
    }];
  },
  isToggle: function isToggle(_ref3) {
    var toggleCache = _ref3.toggleCache;

    return toggleCache != null && toggleCache[FINALIZED] !== true;
  },
  getToggle: function getToggle(editor, _ref4) {
    var gigNodeKey = _ref4.gigNodeKey,
        toggleCache = _ref4.toggleCache,
        setToggleCache = _ref4.setToggleCache;
    return {
      isAccepted: function isAccepted() {
        return toggleCache.data;
      },
      onToggle: function onToggle() {
        setToggleCache(produce(toggleCache, function (draft) {
          draft.data = !toggleCache.data;
        }));
      },
      onFinalize: function onFinalize(accepted) {
        var currentGigNode = getGigNodeByKey(editor, gigNodeKey);
        editor.withoutNormalizing(function () {
          moveSectionBeforeFinalize(editor, currentGigNode);

          setToggleCache(produce(toggleCache, function (draft) {
            draft[FINALIZED] = true;
          }));

          if (accepted) {
            editor.moveToEndOfPreviousBlock();
            unwrapRemoveGigNodeByKey(editor, currentGigNode.key);
          }
        });
      }
    };
  }
}, _toggleDefinitions);

/*
 * This is for cache updates that require the cache to be present.
 */
export var updateDiffCache = function updateDiffCache(editor) {
  if (!cacheExists(editor)) {
    return;
  }

  Object.keys(toggleDefinitions).forEach(function (name) {
    if (toggleDefinitions[name].updateCache) {
      toggleDefinitions[name].updateCache(editor);
    }
  });
};

export var initializeDiffCache = function initializeDiffCache(editor) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      var diffCache = {};

      forEachGigNode(editor, function (gigNode) {
        var keyInfo = getDiffObj(gigNode).info;

        if (!keyInfo) {
          return;
        }

        // Initialize for all keys in keyInfo
        Object.keys(keyInfo).forEach(function (name) {
          if (name === ROOT) {
            // Add a note on this key being root
            diffCache = merge(diffCache, gigNode.key, ROOT, true);
            return;
          }

          var items = toggleDefinitions[name].calculateCache(editor, gigNode.key, keyInfo[name]);
          items.forEach(function (_ref5) {
            var key = _ref5.key,
                cache = _ref5.cache;

            diffCache = merge(diffCache, key, name, cache);
          });
        });
      });

      editor.withoutSaving(function () {
        editor.withoutNormalizing(function () {
          // This executes all the previous writes.
          setCache(editor, diffCache);
          updateDiffCache(editor);
          resolve();
        });
      });
    }, 0);
  });
};

var optionsFrom = function optionsFrom(editor, gigNodeKey, name) {
  return {
    gigNodeKey: gigNodeKey,
    toggleCache: getToggleCache(editor, gigNodeKey, name),
    setToggleCache: function setToggleCache(cacheItem) {
      return addToCache(editor, gigNodeKey, name, cacheItem);
    }
  };
};

var isToggle = function isToggle(editor, gigNodeKey, name) {
  return !cacheExists(editor) ? false : toggleDefinitions[name].isToggle(optionsFrom(editor, gigNodeKey, name));
};

var getToggle = function getToggle(editor, gigNodeKey, name) {
  return toggleDefinitions[name].getToggle(editor, optionsFrom(editor, gigNodeKey, name));
};

var isAcceptedToggle = function isAcceptedToggle(editor, gigNodeKey, name) {
  if (!isToggle(editor, gigNodeKey, name)) {
    return false;
  }

  var toggle = getToggle(editor, gigNodeKey, name);
  return toggle.isAccepted();
};

var isInsert = function isInsert(editor, gigNodeKey) {
  return isToggle(editor, gigNodeKey, INSERT);
};

var isDelete = function isDelete(editor, gigNodeKey) {
  return isToggle(editor, gigNodeKey, DELETE);
};
var isSpringer = function isSpringer(editor, gigNodeKey) {
  return isToggle(editor, gigNodeKey, SPRINGER);
};
// The name stems from the fact that we only annotate 1 affected
// sibling when multiple of them move at the same time.
var isShortMoveRep = function isShortMoveRep(editor, gigNodeKey) {
  return isToggle(editor, gigNodeKey, CHILD_SEQUENCE);
};

var getInsert = function getInsert(editor, gigNodeKey) {
  return getToggle(editor, gigNodeKey, INSERT);
};
var getDelete = function getDelete(editor, gigNodeKey) {
  return getToggle(editor, gigNodeKey, DELETE);
};
var getSpringer = function getSpringer(editor, gigNodeKey) {
  return getToggle(editor, gigNodeKey, SPRINGER);
};
var getShortMoveRep = function getShortMoveRep(editor, gigNodeKey) {
  return getToggle(editor, gigNodeKey, CHILD_SEQUENCE);
};

var isAcceptedInsert = function isAcceptedInsert(editor, gigNodeKey) {
  return isAcceptedToggle(editor, gigNodeKey, INSERT);
};
var isAcceptedDelete = function isAcceptedDelete(editor, gigNodeKey) {
  return isAcceptedToggle(editor, gigNodeKey, DELETE);
};

// Describe whether this is an artificial merge root node that is unneeded,
// i.e. ends up with only one child.
var isRemovableMergeRoot = function isRemovableMergeRoot(editor, gigNodeKey) {
  var isRoot = getToggleCache(editor, gigNodeKey, ROOT);

  if (!isRoot) {
    return false;
  }

  var children = getChildrenGigNodesByKey(editor, gigNodeKey);

  // Since we convert accepted inserts/deletes into regular nodes and those
  // return isToggle === false, we only need to filter out open inserts.
  var finalizedChildren = children.filter(function (node) {
    return !isToggle(editor, node.key, INSERT);
  });

  // Only if we have 2 finalized children, we need to keep the mergeRoot node.
  return finalizedChildren.size < 2;
};

var hasChange = function hasChange(editor, gigNodeKey) {
  return isInsert(editor, gigNodeKey) || isDelete(editor, gigNodeKey) || isSpringer(editor, gigNodeKey) || isShortMoveRep(editor, gigNodeKey);
};

var diffToggles = {
  isToggle: isToggle,
  isInsert: isInsert,
  isAcceptedInsert: isAcceptedInsert,
  isAcceptedDelete: isAcceptedDelete,
  isDelete: isDelete,
  isSpringer: isSpringer,
  isShortMoveRep: isShortMoveRep,
  isRemovableMergeRoot: isRemovableMergeRoot,
  hasChange: hasChange,
  getToggle: getToggle,
  getInsert: getInsert,
  getDelete: getDelete,
  getSpringer: getSpringer,
  getShortMoveRep: getShortMoveRep
};

export default diffToggles;

export var diffToggleProps = function diffToggleProps(editor, gigNodeKey) {
  var toProps = ['isInsert', 'isAcceptedInsert', 'isAcceptedDelete', 'isDelete', 'isSpringer', 'isShortMoveRep', 'isRemovableMergeRoot', 'hasChange'];

  var toFns = ['isToggle', 'getToggle', 'getInsert', 'getDelete', 'getSpringer', 'getShortMoveRep'];

  return Object.assign.apply(Object, ['{}'].concat(toProps.map(function (key) {
    var _ref6;

    return _ref6 = {}, _ref6[key] = diffToggles[key](editor, gigNodeKey), _ref6;
  }), toFns.map(function (key) {
    var _ref7;

    return _ref7 = {}, _ref7[key] = diffToggles[key].bind(diffToggles, editor, gigNodeKey), _ref7;
  })));
};