import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import getChildrenGigNodes from './node/getChildrenGigNodes';

/*
 * Return next gigNode, whether it's a sibling or a descendant or a follow-on ancestor.
 */
export default (function (editor, key) {
  var document = editor.value.document;


  var thisGigNode = getClosestGigNodeByKey(editor, key);

  // The getNextNode does not check children but for us those are the next nodes.
  var thisChildren = getChildrenGigNodes(thisGigNode);
  if (thisChildren.size) {
    return thisChildren.first();
  }

  // NOTE: slatejs ambiguity I think: getNextBlock does NOT check descendants.
  // getNextNode does.
  var nextNode = document.getNextNode(thisGigNode.key);
  var nextGigNode = void 0;

  // Written this way because no nextGigNode should be calculated if
  // there is no nextNode.
  while (nextNode && (nextGigNode = getClosestGigNodeByKey(editor, nextNode.key)) === thisGigNode) {
    nextNode = document.getNextNode(nextNode.key);
  }

  return nextNode ? nextGigNode : null;
});