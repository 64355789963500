/*
 * Ensure that every gigNode has a uuid.
 */

import { uuid, isUuid } from '../../utils/helpers';
import { isGigNode } from '../../utils/node';

var addUuid = function addUuid(editor, node) {
  var data = node.data,
      key = node.key;

  if (!data || !data.get('id') || !isUuid(data.get('id'))) {
    editor.setNodeByKey(key, { data: data.set('id', uuid()) });
  }
};

/*
 * Since gigNode is now a property in node.data, not a type,
 * we need more advanced logic to add the node uuid.
 */
export default (function () {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$debug = _ref.debug,
      debug = _ref$debug === undefined ? false : _ref$debug;

  return {
    normalizeNode: function normalizeNode(node, editor, next) {
      if (node.object !== 'block') {
        return next();
      }
      if (!isGigNode(node)) {
        return next();
      }
      var data = node.data;

      var id = data.get('id');
      if (id && isUuid(id)) {
        return next();
      }
      if (debug) {
        console.log('Adding a uuid to node ' + node.key);
      }
      return function () {
        return addUuid(editor, node);
      };
    }
  };
});