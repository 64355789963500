function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React from 'react';

export default (function (contentPlugins) {
  var OuterDoms = contentPlugins.map(function (plugin) {
    return plugin.GigNodeContainer;
  }).filter(Boolean);

  return function (_ref) {
    var children = _ref.children,
        props = _objectWithoutProperties(_ref, ['children']);

    return OuterDoms.reduce(function (element, Component) {
      return React.createElement(
        Component,
        props,
        element
      );
    }, children);
  };
});

// hmmmm. keep it a list and use obj in inject() thing???
// solve editPlugins then take it from there.