var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  /* Pass clicks through to underlying elements. */\n  /* pointer-events: none; */\n  position: absolute;\n  top: ', 'px;\n  left: ', 'px;\n  width: ', 'px;\n  height: ', 'px;\n'], ['\n  /* Pass clicks through to underlying elements. */\n  /* pointer-events: none; */\n  position: absolute;\n  top: ', 'px;\n  left: ', 'px;\n  width: ', 'px;\n  height: ', 'px;\n']);

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colorScheme } from '@gigmade/msm-ui';

var Svg = styled.svg(_templateObject, function (props) {
  return props.top;
}, function (props) {
  return props.left;
}, function (props) {
  return props.width;
}, function (props) {
  return props.height;
});

var strokeWidth = 2;
var sH = strokeWidth / 2;

export default forwardRef(function (_ref, ref) {
  var _ref$rotate = _ref.rotate,
      rotate = _ref$rotate === undefined ? 0 : _ref$rotate,
      dimensions = _ref.dimensions,
      domProps = _objectWithoutProperties(_ref, ['rotate', 'dimensions']);

  var height = dimensions.height,
      width = dimensions.width;


  return React.createElement(
    Svg,
    _extends({
      ref: ref
    }, domProps, {
      fill: 'none',
      strokeWidth: strokeWidth,
      stroke: colorScheme.compare.changeLine
    }, dimensions, {
      x: '0px',
      y: '0px',
      viewBox: '0 0 ' + width + ' ' + height
    }),
    React.createElement(
      'g',
      { transform: 'rotate(-' + rotate + ' ' + width / 2 + ' ' + height / 2 + ')' },
      React.createElement('line', { x1: sH, y1: sH, x2: width - sH, y2: height - sH }),
      React.createElement('line', { x1: sH, y1: height - sH, x2: width - sH, y2: sH })
    )
  );
});