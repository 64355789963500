var _templateObject = _taggedTemplateLiteralLoose(['\n  display: inline-block;\n  vertical-align: middle;\n  margin: 0 0.1rem;\n  height: 50px;\n'], ['\n  display: inline-block;\n  vertical-align: middle;\n  margin: 0 0.1rem;\n  height: 50px;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  max-width: 4.5rem;\n  border: 1px solid #bbb;\n  border-radius: 8px;\n'], ['\n  max-width: 4.5rem;\n  border: 1px solid #bbb;\n  border-radius: 8px;\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

/* eslint-disable jsx-a11y/alt-text*/
import React from 'react';
import MediaWrapper from './MediaWrapper';
import File from './File';

import styled from 'styled-components';

var SidebarImg = styled.img(_templateObject);

var Container = styled.div(_templateObject2);

var Sidebar = function Sidebar(_ref) {
  var value = _ref.value;

  return React.createElement(SidebarImg, { src: value.src });
};

var Renderer = function Renderer(_ref2) {
  var extension = _ref2.extension;
  return React.createElement(
    Container,
    null,
    React.createElement(File, { extension: extension })
  );
};

var createMain = function createMain(fileLoader) {
  return function (_ref3) {
    var value = _ref3.value,
        domProps = _ref3.domProps;

    return React.createElement(MediaWrapper, {
      fileLoader: fileLoader,
      domProps: domProps,
      value: value,
      renderer: Renderer
    });
  };
};

var defineNode = {
  content: {
    isVoid: true
  }

  /*
   * "Everything else" is an attachment.
   */
};var mimeSelector = function mimeSelector(mime) {
  return true;
};

export default (function (_ref4) {
  var fileLoader = _ref4.fileLoader;
  return {
    Main: createMain(fileLoader),
    Sidebar: Sidebar,
    defineNode: defineNode,
    similarity: ['json'],
    mimeSelector: mimeSelector
  };
});