function createNodeCounter() {
  let count = []
  return depth => {
    const level = depth + 1
    if (level <= count.length) {
      count = count.slice(0, level)
    }

    if (level > count.length) {
      count.push(0)
    }

    count[depth]++

    return count.join('.')
  }
}

export const addCounts = (nodes, selectedNodes) => {
  const nextCount = createNodeCounter()

  const nodesWithCount = nodes.map(node => {
    return {
      node,
      count: nextCount(node.depth),
    }
  })

  const nodeIndices = selectedNodes.map(node => nodes.indexOf(node))
  return nodeIndices.map(index => nodesWithCount[index])
}

/*
 * Get parent of the node in nodes at index.
 */
const getParent = (nodes, index) => {
  let parent = null
  let depth = nodes[index].depth

  while (--index >= 0 && !parent) {
    const prev = nodes[index]
    if (prev.depth < depth) {
      parent = prev
    }
  }
  return parent
}

const getSelectedIndex = (nodes, selectedId) => {
  if (selectedId == null) {
    return -1
  }
  const selectedIndex = nodes.findIndex(n => n.id === selectedId)
  return selectedIndex
}

const getSiblings = (nodes, index, isLeft) => {
  const isValid = index => {
    return isLeft ? index >= 0 : index < nodes.length
  }

  const add = (result, nodes) =>
    isLeft ? result.unshift(nodes) : result.push(nodes)

  const siblings = []
  const depth = nodes[index].depth
  while (isValid(isLeft ? --index : ++index)) {
    const sibling = nodes[index]
    // if the "sibling" is higher up, it's not a sibling; then we've broken out of the parent
    // and no chance to continue and find another sibling of course.
    if (sibling.depth < depth) {
      break
    }
    if (sibling.depth === depth) {
      add(siblings, sibling)
    }
  }
  return siblings
}

const getAncestors = (nodes, index, getIndices = false) => {
  const ancestors = []
  let depth = nodes[index].depth
  while (--index >= 0) {
    const prev = nodes[index]
    if (prev.depth < depth) {
      ancestors.unshift(getIndices ? index : prev)
      depth = prev.depth
    }
  }
  return ancestors
}

/*
 * Only get leaves (plus the current location.)
 */
export const getAllButLeavesAndSiblings = (nodes, selectedId) => {
  // get all, filter if !leaf or same parent as selectedId
  const selectedIndex = getSelectedIndex(nodes, selectedId)
  if (selectedIndex === -1) {
    return []
  }

  const parentNode = getParent(nodes, selectedIndex)
  const length = nodes.length

  return nodes.filter((n, i) => {
    // Either a leaf
    return (
      (i < length - 1 && nodes[i + 1].depth > n.depth) ||
      // Or a sibling including selected node
      getParent(nodes, i) === parentNode
    )
  })
}

export const getAncestorAndSiblingNodes = (nodes, selectedId) => {
  const selectedIndex = getSelectedIndex(nodes, selectedId)
  if (selectedIndex === -1) {
    return []
  }
  return [
    ...getAncestors(nodes, selectedIndex),
    ...getSiblings(nodes, selectedIndex, true),
    nodes[selectedIndex],
    ...getSiblings(nodes, selectedIndex, false),
  ]
}

export const getAncestorSiblings = (nodes, selectedId) => {
  const selectedIndex = getSelectedIndex(nodes, selectedId)

  if (selectedIndex === -1) {
    // Avoid an empty TOC
    return [nodes[0]]
  }

  function getAncestorChildren(ancestorIndices) {
    const selectedNode = nodes[selectedIndex]

    if (ancestorIndices.length === 0) {
      return [
        ...getSiblings(nodes, selectedIndex, true),

        selectedNode,
        ...getSiblings(nodes, selectedIndex, false),
      ]
    }

    const [currentAncestorIdx, ...rest] = ancestorIndices
    const currentAncestor = nodes[currentAncestorIdx]

    // This contains our parent.
    // In this case we decide not to show siblings.
    // if (ancestorIndices.length === 1) {
    //   return [currentAncestor, selectedNode]
    // }

    return [
      ...getSiblings(nodes, currentAncestorIdx, true),
      currentAncestor,
      ...getAncestorChildren(rest),
      ...getSiblings(nodes, currentAncestorIdx, false),
    ]
  }

  const ancestorIndices = getAncestors(nodes, selectedIndex, true)
  return getAncestorChildren(ancestorIndices)
}
