var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React, { useState, useRef, useEffect, useReducer, useMemo, useCallback, createContext } from 'react';
import { Editor } from '@gigmade/slate-react';
// import { KeyUtils } from '@gigmade/slate'
import editPlugins from './editPlugins';
import { useDebounce } from 'use-debounce';
import { documentToSlate } from './convert';
import { diffLoading } from './diff';
import onDebug from './onDebug';
import prepareContentPlugins from './prepareContentPlugins';
import useDebouncedCallback from 'react-use/lib/useDebounce';
import useConsensus from './useConsensus';
import isEqual from 'lodash/isEqual';

export var SlateContext = createContext();

var CLIENT_ONCHANGE_DELAY = 150;
var REFRESH_EDITOR_DELAY = 350;

var getInitialNodesState = function getInitialNodesState() {
  return {
    valueFromNodesLoading: false,
    value: null
  };
};

var reducer = function reducer(state, action) {
  switch (action.type) {
    case 'valueFromNodes':
      {
        var value = action.payload.value;

        return _extends({}, state, { value: value, valueFromNodesLoading: true });
      }
    case 'valueLoaded':
      {
        return _extends({}, state, { valueFromNodesLoading: false });
      }
    case 'setValueFromSlate':
      {
        var _value = action.payload.value;

        return _extends({}, state, { value: _value });
      }
    default:
      {
        throw new Error('unknown action dispatched.');
      }
  }
};

export default (function (_ref) {
  var _ref$debug = _ref.debug,
      debug = _ref$debug === undefined ? false : _ref$debug,
      _ref$readOnly = _ref.readOnly,
      readOnly = _ref$readOnly === undefined ? false : _ref$readOnly,
      clientNodes = _ref.node,
      isDiffing = _ref.isDiffing,
      undoable = _ref.undoable,
      clientOnChange = _ref.onChange,
      _ref$textPlaceholder = _ref.textPlaceholder,
      textPlaceholder = _ref$textPlaceholder === undefined ? 'Untitled' : _ref$textPlaceholder,
      _ref$titlePlaceholder = _ref.titlePlaceholder,
      titlePlaceholder = _ref$titlePlaceholder === undefined ? 'Write something' : _ref$titlePlaceholder,
      fileLoader = _ref.fileLoader,
      clientPlugins = _ref.plugins,
      pauseSave = _ref.pauseSave,
      addMessage = _ref.addMessage,
      RootWrapper = _ref.RootWrapper;

  var editorRef = useRef();

  ///////////
  // 1. Prep work as our node lifecycle needs some of these.
  ///////////

  var contentPlugins = useMemo(function () {
    return prepareContentPlugins(clientPlugins, {
      debug: debug,
      fileLoader: fileLoader
    });
  }, [debug, clientPlugins, fileLoader]);

  // If we call addMessage synchronously, it will immediately call our
  // SlateEditor again due to its updated context.
  // I think it's because the editor flushes asynchronously, so setting
  // react context synchronously will prevent the editor from completing
  // its work.
  var delayedAddMessage = useCallback(function (args) {
    window.setTimeout(function () {
      return addMessage(args);
    }, 0);
  }, [addMessage]);

  var _useConsensus = useConsensus(),
      pauseCleanNodeRaw = _useConsensus[0],
      cleanNodePaused = _useConsensus[1];

  // Same as addMessage: call resume asynchronously.


  var pauseCleanNode = useCallback(function () {
    var resume = pauseCleanNodeRaw();

    return function () {
      window.setTimeout(resume, 0);
    };
  }, [pauseCleanNodeRaw]);

  var cleanNodePausedRef = useRef(cleanNodePaused);

  useEffect(function () {
    cleanNodePausedRef.current = cleanNodePaused;
  }, [cleanNodePaused]);

  // This is dependency-free and always the latest state.
  var getCleanNodePaused = useCallback(function () {
    return cleanNodePausedRef.current;
  }, []);

  var plugins = useMemo(function () {
    return editPlugins({
      RootWrapper: RootWrapper,
      fileLoader: fileLoader,
      debug: debug,
      pauseSave: pauseSave,
      getCleanNodePaused: getCleanNodePaused,
      addMessage: delayedAddMessage,
      textPlaceholder: textPlaceholder,
      titlePlaceholder: titlePlaceholder,
      contentPlugins: contentPlugins
    });
  }, [RootWrapper, fileLoader, debug, pauseSave, getCleanNodePaused, delayedAddMessage, textPlaceholder, titlePlaceholder, contentPlugins]);

  useEffect(function () {
    onDebug({ debug: debug, editorRef: editorRef });
  }, [debug]);

  ///////////
  // 2. Actual nodes/value lifecycle.
  ///////////

  var _useReducer = useReducer(reducer, getInitialNodesState()),
      nodesState = _useReducer[0],
      dispatch = _useReducer[1];

  var valueFromNodesLoading = nodesState.valueFromNodesLoading,
      value = nodesState.value;

  var _useDebounce = useDebounce(value, REFRESH_EDITOR_DELAY),
      rerenderSlowValue = _useDebounce[0];

  var _useState = useState(0),
      editorUpdateTick = _useState[0],
      setEditorTick = _useState[1];

  useEffect(function () {
    setEditorTick(function (i) {
      return i + 1;
    });
  }, [rerenderSlowValue]);

  useEffect(function () {
    if (!clientNodes) {
      return;
    }

    var value = documentToSlate(clientNodes, contentPlugins);
    dispatch({ type: 'valueFromNodes', payload: { value: value } });
  }, [clientNodes, contentPlugins]);

  var _useState2 = useState(null),
      previousClientNodes = _useState2[0],
      setPreviousClientNodes = _useState2[1];

  useDebouncedCallback(function () {
    if (
    // editor is not ready.
    !editorRef.current ||
    // This could occur at the very beginning, before any node is loaded.
    value == null ||
    // valueFromNodesLoading: we are still loading value from a node.
    valueFromNodesLoading ||
    // we are diffing but the inserts are not marked yet which would lead to
    // bad gigExtractNodes behaviour.
    diffLoading(editorRef.current)) {
      return;
    }

    var nodes = editorRef.current.gigExtractNodes();

    // Did the node change since we last called clientOnChange?
    var equalNodes = isEqual(nodes, previousClientNodes);

    // If the node is deep equal, we send the old node instance to avoid
    // unnecessary renderings/calculations.
    var sendNodes = equalNodes ? previousClientNodes : nodes;

    // And also, only if node is equal, we leave the previousClientNodes
    // untouched so that the next comparison will keep the same behaviour.
    if (!equalNodes) {
      setPreviousClientNodes(nodes);
    }

    clientOnChange({
      node: sendNodes,
      selectedId: editorRef.current.gigGetSelectedId(),
      editorState: editorRef.current
    });
  }, CLIENT_ONCHANGE_DELAY, [value, clientOnChange, valueFromNodesLoading]);

  useEffect(function () {
    if (!valueFromNodesLoading) {
      return;
    }

    // initializeDiffCache(editorRef.current)
    editorRef.current.run('onValueFromNode');
    if (!undoable) {
      editorRef.current.moveToStart();
      editorRef.current.focus();
    }

    dispatch({ type: 'valueLoaded' });
  }, [valueFromNodesLoading, undoable]);

  var onChange = useCallback(function (_ref2) {
    var value = _ref2.value;

    dispatch({ type: 'setValueFromSlate', payload: { value: value } });
  }, []);

  // Temp hack: cleanNodePaused currently means dragging and we also don't want to edit
  var readOnlyInternal = readOnly || valueFromNodesLoading || cleanNodePaused;

  var slateContext = useMemo(function () {
    return { tick: editorUpdateTick, pauseCleanNode: pauseCleanNode };
  }, [editorUpdateTick, pauseCleanNode]);

  return value == null ? null : React.createElement(
    SlateContext.Provider,
    { value: slateContext },
    React.createElement(Editor, {
      ref: editorRef,
      plugins: plugins,
      isDiffing: isDiffing,
      value: value,
      onChange: onChange,
      readOnly: readOnlyInternal
    })
  );
});