var Container = function Container(_ref) {
  var children = _ref.children;

  return React.createElement(
    "div",
    null,
    children
  );
};

var Main = function Main(_ref2) {
  var children = _ref2.children;

  return React.createElement(
    "div",
    null,
    children
  );
};

export default (function () {
  return {
    Main: null,
    Container: Container,
    defineNode: {
      content: false
    }
  };
});