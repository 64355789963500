var _templateObject = _taggedTemplateLiteralLoose(['\n  /* border only around the actual block */\n  display: inline-block;\n  /* match the border size */\n  margin-left: 2px;\n  box-shadow: ', ';\n'], ['\n  /* border only around the actual block */\n  display: inline-block;\n  /* match the border size */\n  margin-left: 2px;\n  box-shadow: ', ';\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

/*
 * Better in future:
 * Grey out entire line, just like paper.
 */
import styled from 'styled-components';

export default styled.div(_templateObject, function (props) {
  return props.isFocused ? '0 0 0 2px blue;' : 'none';
});