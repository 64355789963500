export var isTab = function isTab(event) {
  return event.key === 'Tab' && !event.shiftKey;
};
export var isShiftTab = function isShiftTab(event) {
  return event.key === 'Tab' && event.shiftKey;
};
export var isEnter = function isEnter(event) {
  return event.key === 'Enter';
};
export var isUp = function isUp(event) {
  return event.key === 'ArrowUp' && !event.shiftKey;
};
export var isShiftUp = function isShiftUp(event) {
  return event.key === 'ArrowUp' && event.shiftKey;
};
export var isDown = function isDown(event) {
  return event.key === 'ArrowDown' && !event.shiftKey;
};
export var isShiftDown = function isShiftDown(event) {
  return event.key === 'ArrowDown' && event.shiftKey;
};
export var isDel = function isDel(event) {
  return event.key === 'Delete';
};
export var isBackspace = function isBackspace(event) {
  return event.key === 'Backspace';
};
export var isLeft = function isLeft(event) {
  return event.key === 'ArrowLeft' && !event.shiftKey;
};
export var isShiftLeft = function isShiftLeft(event) {
  return event.key === 'ArrowLeft' && event.shiftKey;
};
export var isRight = function isRight(event) {
  return event.key === 'ArrowRight' && !event.shiftKey;
};
export var isShiftRight = function isShiftRight(event) {
  return event.key === 'ArrowRight' && event.shiftKey;
};
export var hasShift = function hasShift(event) {
  return event.shiftKey;
};
export var isArrow = function isArrow(event) {
  return isUp(event) || isDown(event) || isRight(event) || isLeft(event);
};