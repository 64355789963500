// KEY addresses a node.
export var KEY = 'key';

export var TYPE = 'type';
export var ROOT = 'root';

export var DEPTH = 'depth';

// diff3
export var TYPE3 = 'type3';

// match3Obj
export var ORPHAN = 'orphan';
export var MATCH = 'match';
export var COMPARE = 'compare';
export var HEAD = 'head';
export var IDX = 'index';
export var COMPAREIDX = 'compareIdx';
export var SIDE = 'side';

export var FREEZE = 'freeze';
export var HIDE = 'hide';
export var BLOCK = 'block';
export var DIFF3 = 'diff3';

// new match3Obj
export var ORIGIN = 'origin';

// where springers move:
// export const PARENT_KEYS = 'parentKeys'
export var TOGGLES = 'toggles';
export var MOVE = 'move';

// Various toggles
export var CHILD_STATES = 'childStates';
export var CHILD_SEQUENCE = 'childSeq';
// And for the better/individual children sequence toggles
export var SWAP = 'swap';
export var INVERT = 'inv';
export var SEQ = 'seq';
export var ALT = 'alt';

export var SPRINGER = 'springer';
export var INLINES = 'inlines';
export var DEEP = 'deep';

export var INSERT = 'insert';
export var DELETE = 'delete';

export var ISACCEPTED = 'accepted';

// calculating aggregate accept state.
export var UNDEFINED = undefined;

// diff states
export var MIXED = 'mixed';
export var ALTERNATIVE = 'alternative';