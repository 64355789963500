export default (function (editor, key, matchFn) {
  var document = editor.value.document;

  // NOTE: document.getClosest does NOT take the current node if it matches.

  var thisBlock = document.getNode(key);

  if (matchFn(thisBlock)) {
    return thisBlock;
  }

  var block = document.getClosest(key, matchFn);

  return block ? block : null;
});