/*
 * Set props of current gigNode for use in render func.
 * Note: this does not need access to contentPlugins because of the
 * `injectContentPlugins` slate plugin.
 */
import { getChildrenGigNodesByKey, getGigNodeDepthChange, getClosestGigNodeByKey, getAncestorGigNodesByKey, getGigNodeContentPluginByKey, getThisGigNodeRenderers, getUserPath } from '.';

// Find first occurrence of a renderer string and send it.
// If multiple definitions exist that try to wrap the node in a
// react container, only take the closest ancestor's definition.
// For an unfinished version how to render multiple wrappers, see
// commit 267ac6c338 (renderContent/index.js).
var getRenderer = function getRenderer(renderers, typeStr) {
  var renderer = renderers.find(function (renderer) {
    return renderer[typeStr];
  });
  if (!renderer) {
    return { Container: null, flags: {} };
  }

  var _renderer$typeStr = renderer[typeStr],
      Container = _renderer$typeStr.Container,
      flagStrings = _renderer$typeStr.flags;
  // Convert the flag strings to booleans.

  var flags = Object.assign.apply(Object, [{}].concat((flagStrings || []).map(function (flag) {
    var _ref;

    return _ref = {}, _ref[flag] = true, _ref;
  })));

  // We'll have a flags object even if we have no corresponding xType
  // definitions.
  return { Container: Container, flags: flags };
};

export default (function (editor, key) {
  var currentGigNode = getClosestGigNodeByKey(editor, key);
  if (!currentGigNode) throw new Error('getClosestGigNodeByKey(editor, key) returns null for key=' + key);
  var path = editor.value.document.getPath(currentGigNode.key);

  var children = getChildrenGigNodesByKey(editor, path);
  var nodeIsFirst = path.size === 1 && path.get(0) === 0;

  var nodeData = currentGigNode.data;
  var nodeValue = nodeData.get('value');
  var contentType = nodeData.get('contentType');
  // const diffProps = nodeData.get('diffProps')

  var nodeRenderers = getGigNodeContentPluginByKey(editor, path);
  var ancestorRenderers = getThisGigNodeRenderers(editor, path);
  // TODO: redundancy with ancestorRenderers
  var ancestors = getAncestorGigNodesByKey(editor, path, {
    skipNonGigNodes: true,
    bottomUp: true,
    includeRoot: true,
    includeSelf: true
  });

  // Since we already have calculated ancestors, we dont need to call getParentGigNodeByKey
  // parent is always the parent of currentGigNode, which is first in the list of ancestors
  var parent = ancestors.get(1, null);

  var OuterContainer = nodeRenderers.OuterContainer,
      ChildrenContainer = nodeRenderers.ChildrenContainer,
      ContentRenderer = nodeRenderers.Main,
      InlineRenderer = nodeRenderers.InlineRenderer;


  var userPath = getUserPath(editor, path);
  var depth = userPath.size;
  var depthChange = getGigNodeDepthChange(editor, path);

  // In this variant, we allow e.g. a gigNode level instruction on another level
  // than a children level instruction.
  var outerDom = getRenderer(ancestorRenderers, 'outer');
  var childrenDom = getRenderer(ancestorRenderers, 'children');

  return {
    children: children,
    parent: parent,
    // nodeData,
    nodeValue: nodeValue,
    contentType: contentType,
    currentGigNode: currentGigNode,
    depth: depth,
    userPath: userPath,
    depthChange: depthChange,
    // Note: passing this back because this is always the gigNode path.
    path: path,
    ancestors: ancestors,
    nodeIsFirst: nodeIsFirst,
    OuterContainer: OuterContainer,
    ChildrenContainer: ChildrenContainer,
    ContentRenderer: ContentRenderer,
    InlineRenderer: InlineRenderer,
    outerDom: outerDom,
    childrenDom: childrenDom
  };
});