import { getClosestGigNodeByKey, getParentGigNodeByKey, insertChildGigNode } from './';
import { getGigNodeChildIndex, getChildrenGigNodes } from './node';

/*
 * addIndex:
 * When the unwrapped node becomes a sibling of its parent, 0 is the default
 * `addIndex` and means the unwrapped node is placed immediately after its old
 * parent.
 */
export default (function (editor, key) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$addIndex = _ref.addIndex,
      addIndex = _ref$addIndex === undefined ? 0 : _ref$addIndex,
      _ref$allowTwoRoots = _ref.allowTwoRoots,
      allowTwoRoots = _ref$allowTwoRoots === undefined ? false : _ref$allowTwoRoots;

  var document = editor.value.document;

  var thisGigNode = getClosestGigNodeByKey(editor, key);

  var parent = getParentGigNodeByKey(editor, thisGigNode.key);

  if (!parent) {
    if (!allowTwoRoots) {
      throw new Error('Trying to unwrap root node.');
    }
    insertChildGigNode(editor, document.key, thisGigNode, 0);
    return;
  }

  // No grandParent gig node means that the parent is the root gigNode.
  // Place the node at index 0 or 1 depending on placeBefore.
  // Let schema validation to the rest (i.e. turn the node at index 1 into a
  // child of the node at index 0)
  // The reason we are not throwing here is that this function may be called
  // by unwrapRemoveGigNodeByKey which does the unwrap, then removes the node.
  var grandParent = getParentGigNodeByKey(editor, parent.key) || document;

  var parentIndex = getGigNodeChildIndex(grandParent, parent.key);
  var defaultInsertIndex = parentIndex + 1;

  // Clamp the relative `addIndex` value to reasonable range.
  var min = -defaultInsertIndex;
  var max = getChildrenGigNodes(grandParent).size - defaultInsertIndex;
  var effectiveAddIndex = Math.min(Math.max(min, addIndex), max);

  insertChildGigNode(editor, grandParent.key, thisGigNode, defaultInsertIndex + effectiveAddIndex);

  // Tell caller where we effectively inserted.
  return effectiveAddIndex;
});