/*
 * This is general nodes structure based on nodes and their contentType
 * definition.
 */
import { wrapAndDedent, dedentByKey, unwrapRemoveGigNodeByKey, appendGigNodeChild } from '../../utils';
import { isGigNode, getContentType, isChildrenWrapper } from '../../utils/node';

var isValidChildrenWrapper = function isValidChildrenWrapper(node) {
  return node.nodes.size > 0 && node.nodes.every(isGigNode);
};

/*
#1 find a (valid) content node if type has one. Note the index.
#2 find a (valid) children node if exists. Note the index.

Perhaps, re-arrange so that order is correct.

Then, walk through further nodes.
- For each children, ähnlich unwrapRemoveGigNodesByParentKey
Dann such nochmal node und starte nochmals ab index 2 oder wo.
- If gigNode, appendGigNodeChild to dad gigNode
- If content, wrapAndDedent it.
- if other, remove it.

 * Side-note:
 * Multiple type:content nodes inside a gigNode is either done via
 * newline (then it's one) or plaintext paste (then it's any number).
 * We want to assign each content a gigNode.
 */
export default (function () {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$debug = _ref.debug,
      debug = _ref$debug === undefined ? false : _ref$debug;

  return {
    normalizeNode: function normalizeNode(gigNode, editor, next) {
      // Look for a gigNode.
      if (gigNode.object !== 'block') {
        return next();
      }

      if (!isGigNode(gigNode)) {
        return next();
      }

      // TODO: Does a gigNode without content always have to have children?
      if (gigNode.nodes.size === 0) {
        if (debug) {
          console.log('node ' + gigNode.key + ' has no nodes, removing.');
        }
        return function () {
          editor.removeNodeByKey(gigNode.key);
        };
      }

      // Next phase: find valid gigNode.nodes.

      // Do we expect a content child or not?
      var content = editor.gigGetContentPlugins()[getContentType(gigNode)].defineNode.content;

      // These will be the first keys we'll need to see in as gigNode children.

      var validContentKey = void 0,
          validChildrenKey = void 0,
          validContentIndex = void 0,
          validChildrenIndex = void 0,
          validContentNode = void 0;

      // Verify we have a content if needed.
      if (Boolean(content)) {
        var typeContent = content.isVoid ? 'voidContent' : 'content';
        var node = gigNode.nodes.find(function (n) {
          return n.type === typeContent;
        });

        if (!node) {
          // There are currently 2 cases leading to this:
          // Note: both cases can deal with same handling.
          // If we ever have a case that needs other handling, and we cannot
          // distinguish it with focusBlock.key or content block emptiness etc.,
          // can make a onKeyDown: { lastKeyDown = event.key; return next() }
          // function and keep the result around for checking.
          // 1. When hitting Delete at the end of a node.
          // This can happen without an explanation if we forward delete a node
          // and the next one has gigNode children.
          // Looks like the content of that next gigNode gets deleted with the
          // delete command.
          // 2. When hitting backspace at the start of the first child node.
          // Hitting backspace on the start of the first child line, with both
          // title and first child being text, causes this issue. An example is
          // at https://jsfiddle.net/gLk4dh29/1/
          // (When checking the fiddle, ignore the late normalize issue - it is
          // about the bad structure mentioned there.)
          if (debug) {
            console.log('node ' + gigNode.key + ' has no content but should, unwrapping.');
          }
          return function () {
            unwrapRemoveGigNodeByKey(editor, gigNode.key);
          };
        }

        // Got a valid content
        validContentKey = node.key;
        validContentIndex = gigNode.nodes.indexOf(node);
        validContentNode = node;
      }

      // Note children index if any.
      var childrenNode = gigNode.nodes.find(function (node) {
        return isChildrenWrapper(node);
      });
      // size check: if this fails, an empty children wrapper node will be removed later.
      if (childrenNode && isValidChildrenWrapper(childrenNode)) {
        validChildrenKey = childrenNode.key;
        validChildrenIndex = gigNode.nodes.indexOf(childrenNode);
      }

      // TODO: Haven't encountered this, but may need handler if children wrapper is
      // encountered before content node.
      if (validContentKey && validChildrenKey && validContentIndex > validChildrenIndex) {
        return function () {
          editor.withoutNormalizing(function () {
            throw new Error('Encountered inverted content and children. FIXME (sortChildren).');
          });
        };
      }

      var contentNodeCount = 0;

      var invalidHandlers = gigNode.nodes.map(function (node) {
        if (node.key === validContentKey || node.key === validChildrenKey) {
          return false;
        }

        if (isChildrenWrapper(node)) {
          return function () {
            // Append gigNode children and remove node itself.
            var gigChildren = node.nodes.filter(isGigNode);
            gigChildren.forEach(function (child) {
              appendGigNodeChild(editor, gigNode.key, child);
            });
            editor.removeNodeByKey(node.key);
          };
        }

        if (isGigNode(node)) {
          return function () {
            dedentByKey(editor, node.key, { addIndex: contentNodeCount++ });
          };
        }

        // This happens when we press enter: a second content is created
        // right next to our previous.
        //
        // FIXME: any better way of detecting text content?
        if (node.type === 'content' && gigNode.type === 'text') {
          return function () {
            // Now, since we potentially have metadata in `data` that belongs
            // to one of the contents, we keep that with the old first content
            // *unless* the first content is empty, in which case we'd treat
            // that as the new content.
            var contentKeyToWrap = node.key;
            var adjust = 0;
            if (!validContentNode.text.length) {
              contentKeyToWrap = validContentKey;
              adjust = -1;
            }

            wrapAndDedent(editor, contentKeyToWrap, {
              addIndex: adjust + contentNodeCount++
            });
          };
        }

        // Just remove unknown nodes.
        return function () {
          return editor.removeNodeByKey(node.key);
        };
      }).filter(Boolean);

      if (invalidHandlers.size === 0) {
        return next();
      }

      return function () {
        return editor.withoutNormalizing(function () {
          invalidHandlers.forEach(function (handler) {
            handler();
          });
        });
      };
    }
  };
});