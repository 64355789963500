import { SEQ, INVERT, SWAP, ALT } from '@gigmade/msm-merge-util';
// SEQ = 'seq'
// INVERT = 'inv'
// SWAP = 'swap'
// ALT = 'alt'
// s = [[INVERT, 1, [SWAP, 2, 3, [SWAP, 4, 5, 6]]]]

var outerKey = function outerKey(item) {
  var last = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!Array.isArray(item)) {
    return item;
  }

  var outerItem = last ? item[item.length - 1] : item[1];

  if (Array.isArray(outerItem)) {
    return outerKey(outerItem, last);
  }

  return outerItem;
};

var firstKey = outerKey;

var allKeys = function allKeys(items) {
  var keys = [];
  if (!Array.isArray(items)) {
    return [items];
  }

  items.slice(1).forEach(function (item) {
    keys.push.apply(keys, allKeys(item));
  });

  return keys;
};

var parseChildSeq = function parseChildSeq(sequence) {
  if (sequence[0] === ALT) {
    return [[ALT, sequence[1][0], sequence[1], sequence[2]]];
  }

  var changes = [];

  sequence.forEach(function (item) {
    if (!Array.isArray(item)) {
      // continue loop
      return;
    }

    if (item[0] === INVERT) {
      changes.push([item[0], firstKey(item[1]), allKeys(item[1]), allKeys(item[2])]);
    }

    if (item[0] === SWAP) {
      changes.push([item[0], firstKey(item[1]), allKeys(item[1]), allKeys(item[2]), allKeys(item[3])]);
    }

    // Note, for item[0] === SEQ we are just passing through.

    // And recurse
    changes.push.apply(changes, parseChildSeq(item.slice(1)));
  });

  return changes;
};

export default parseChildSeq;