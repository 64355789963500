var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { cloneElement, Children, Fragment } from 'react';
import { Place } from './styles';
import { DATA_PLACE_ID } from './constants';
import { useDnd } from './DndContext';

export default (function (_ref) {
  var _ref2;

  var children = _ref.children,
      dragId = _ref.dragId,
      _ref$layout2d = _ref.layout2d,
      layout2d = _ref$layout2d === undefined ? false : _ref$layout2d,
      domProps = _objectWithoutProperties(_ref, ['children', 'dragId', 'layout2d']);

  var child = void 0;

  try {
    child = Children.only(children);
  } catch (e) {
    throw new Error('Each NestedFlippedContent must wrap a single child');
  }

  var _useDnd = useDnd(),
      state = _useDnd[0];

  var springIndexForPlace = state.springIndexForPlace,
      stylesPlace = state.stylesPlace;

  // See Layout2dNode
  // const passedStyle = (domProps || {}).style
  // lodash deep merge?

  var newChild = cloneElement(child, _extends({}, domProps));

  return React.createElement(
    Fragment,
    null,
    newChild,
    springIndexForPlace[dragId] && stylesPlace[dragId] && React.createElement(Place, _extends({}, (_ref2 = {}, _ref2[DATA_PLACE_ID] = springIndexForPlace[dragId]['inside'], _ref2), {
      style: _extends({}, stylesPlace[dragId]['inside']),
      layout2d: layout2d.toString(),
      contentEditable: false
    }))
  );
});