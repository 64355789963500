import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import getChildrenGigNodes from './node/getChildrenGigNodes';
import isRootGigNodeByKey from './isRootGigNodeByKey';
import unwrapGigNodeByKey from './unwrapGigNodeByKey';

/*
 * Unwrap all children gigNodes from a node with key.
 */
export default (function (editor, key) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
      _ref$allowTwoRoots = _ref.allowTwoRoots,
      allowTwoRoots = _ref$allowTwoRoots === undefined ? false : _ref$allowTwoRoots;

  editor.withoutNormalizing(function () {
    var thisGigNode = getClosestGigNodeByKey(editor, key);
    if (isRootGigNodeByKey(editor, thisGigNode.key) && !allowTwoRoots) {
      throw new Error('Cannot unwrap root gigNode.');
    }

    // Only gigNode nodes, not content node.
    var children = getChildrenGigNodes(thisGigNode);

    // In reverse so order doesn't get messed up.
    for (var i = children.size - 1; i >= 0; i--) {
      unwrapGigNodeByKey(editor, children.get(i).key, { allowTwoRoots: allowTwoRoots });
    }
  });
});