/*
 * Return previous gigNode, whether it's a sibling or a parent.
 */
import isRootGigNodeByKey from './isRootGigNodeByKey';
import getClosestGigNodeByKey from './getClosestGigNodeByKey';

export default (function (editor, key) {
  if (isRootGigNodeByKey(editor, key)) {
    return null;
  }
  var document = editor.value.document;

  var prevBlock = document.getPreviousBlock(key);
  if (!prevBlock) {
    return null;
  }

  return getClosestGigNodeByKey(editor, prevBlock.key);
});