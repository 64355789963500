/*
 * From a list of keys, get the left/rightmost per the children sequence
 * in the given parentGigNode.
 */
import { getChildrenGigNodes } from '../utils/node';
import sortBy from 'lodash/sortBy';

export var getChildKeys = function getChildKeys(parentGigNode) {
  return getChildrenGigNodes(parentGigNode).map(function (n) {
    return n.key;
  }).toArray();
};

var sorter = function sorter(nodeChildren, key) {
  return nodeChildren.indexOf(key);
};

// keys that are not yet deleted.
export var activeKeys = function activeKeys(nodeChildren, keys) {
  return keys.filter(function (key) {
    return sorter(nodeChildren, key) !== -1;
  });
};

export function getRightMostKey(parentGigNode, keys) {
  var childKeys = getChildKeys(parentGigNode);

  return sortBy(activeKeys(childKeys, keys), function (key) {
    return -sorter(childKeys, key);
  })[0];
}

export function getLeftMostKey(parentGigNode, keys) {
  var childKeys = getChildKeys(parentGigNode);

  return sortBy(activeKeys(childKeys, keys), function (key) {
    return sorter(childKeys, key);
  })[0];
}

export function getKeysSortedBy(parentGigNode, keys) {
  var childKeys = getChildKeys(parentGigNode);

  var activeSeq = activeKeys(childKeys, keys);
  var extendedSeq = [];

  // Since we're arranging everything, need to keep tabs of the newly edited
  // nodes.
  // First, add left-most newly added keys that are not in validSeq.
  var index = 0;
  while (index < childKeys.length && activeSeq.indexOf(childKeys[index]) === -1) {
    extendedSeq.push(childKeys[index++]);
  }

  activeSeq.forEach(function (key) {
    extendedSeq.push(key);
    var index = childKeys.indexOf(key);
    // Add all newly added keys, i.e. those that are not in validSeq yet, to extendedSeq
    // near their left-closest siblings in validSeq.
    while (index < childKeys.length - 1 && activeSeq.indexOf(childKeys[++index]) === -1) {
      extendedSeq.push(childKeys[index]);
    }
  });

  return extendedSeq;
}

export default function getOuterKeys(parentGigNode, keys) {
  return [getLeftMostKey(parentGigNode, keys), getRightMostKey(parentGigNode, keys)];
}