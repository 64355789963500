import React from 'react';
// import styled from 'styled-components'
import './TitleEmphasis.css';

/*
 * On title only, add a h1, else pass through children.
 */
export default (function (_ref) {
  var isMap = _ref.isMap,
      isTitle = _ref.isTitle,
      children = _ref.children;

  // const TitleRenderer = styled.h1`
  //   width: 100%;
  //   outline: none;
  //   max-width: 100%;
  //   border: none;
  //   display: block;
  //   font-weight: normal;
  //   padding: 1.5rem 1.5rem 1rem 0;
  //   line-height: 1em;
  //   font-size: 1.5rem;
  //   padding-top: 0;
  //   margin-top: 0;
  //   & p {
  //     font-size: 1.5rem;
  //   }
  // `

  // TODO: Wrapping the title with a styled-components h1 makes the cursor
  // jump to the beginning of the line as soon as I enter text.
  // The className version works around this but it might be worthwhile to
  // track this down.
  // return isTitle ? <TitleRenderer>{children}</TitleRenderer> : children
  return isTitle ? React.createElement(
    'div',
    { className: 'main_title ' + (isMap ? 'map' : '') },
    children
  ) : children;
});