import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import getChildrenGigNodes from './node/getChildrenGigNodes';

export default (function (editor, key) {
  var document = editor.value.document;
  // In validation, we need to check document's gigNode children,
  // so providing this as a fallback.

  var thisGigNode = getClosestGigNodeByKey(editor, key) || document;
  return getChildrenGigNodes(thisGigNode);
});