var _templateObject = _taggedTemplateLiteralLoose(['\n  /* Add a thin left border for any depth higher than 1. */\n  /*margin-top: 1px;*/\n  /* min-width not required unless the editor is display:inline-block */\n  /* min-width: 10em; */\n  padding-top: 0.2em;\n  padding-right: 0.3em;\n  /* padding-left = distance card to text */\n  padding-left: 0.4em;\n  ', '\n  ', '\n  /* For root node we check for padding-bottom click to append a node. */\n  padding-bottom: ', 'em;\n'], ['\n  /* Add a thin left border for any depth higher than 1. */\n  /*margin-top: 1px;*/\n  /* min-width not required unless the editor is display:inline-block */\n  /* min-width: 10em; */\n  padding-top: 0.2em;\n  padding-right: 0.3em;\n  /* padding-left = distance card to text */\n  padding-left: 0.4em;\n  ', '\n  ', '\n  /* For root node we check for padding-bottom click to append a node. */\n  padding-bottom: ', 'em;\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import styled from 'styled-components';

var vDistance = 0.7;

var getColor = function getColor(d) {
  switch (d % 2) {
    case 0:
      return '#f3f3f3';
    default:
      return '#ddd';
  }
};

/*
 * Props:
 * @depth
 * @depthChange
 * @nodeIsFirst
 * (dom props as desired by caller)
 */
export default styled.div(_templateObject, function (props) {
  return props.noMargin ? '' : '\n    /* margin-left = distance card to nested card */\n    margin-left: 0.2em;\n    /* top padding to help lay out tree structure. */\n    margin-top: ' + (props.depthChange <= 0 || true ? '0.2em' : props.depthChange * vDistance + 'em') + ';\n  ';
}, function (props) {
  return props.depth <= 0 ? '' : '\n        background: ' + getColor(props.depth > 1 ? props.depth : -1) + ';\n        box-shadow: 0 1px 0 rgba(9,30,66,.25);\n        border-radius: 0.3em;\n  ';
}, function (props) {
  return props.nodeIsFirst ? '3' : '0.3';
});