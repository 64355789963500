function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import { Value } from '@gigmade/slate';
import createNodeWithEmptyChildrenContainer, { addChild } from '../utils/helpers/createGigNodeJson';

/*
    SimpleBlockTree: Array<SimpleBlock>

    SimpleBlock: {
      ...RegularContent,
      depth: number  // title is 0
    }
*/

/*
 * for simple node and children:
const hasNoChildren = node =>
  !node.children.length

const removeEmptyChildrenWrapper = node =>
  delete node.children

const createNodeWithEmptyChildrenContainer = content => {
  const node = { content, children: [] }
  return node
}

const markAsRootNode = node => {
  node.parent = null
}

const addChild = (parent, node) => {
  node.parent = parent
  node.parent.children.push(node)
}

const finalize = output => output
 *
 */

var markAsRootNode = function markAsRootNode(node) {
  // no op
};

var hasNoChildren = function hasNoChildren(node) {
  // We don't create empty children containers so this can return anything.
  return false;
};

var removeEmptyChildrenWrapper = function removeEmptyChildrenWrapper(node) {
  // No-op
};

var finalize = function finalize(output) {
  return Value.fromJSON({
    document: {
      // Catch empty output.
      nodes: output.length ? [output[0]] : []
    }
  });
};

export default function blocksToFlat(list, contentPlugins) {
  var output = [];
  // For any given depth, contains the currently last encountered parent at that
  // depth in lastParentByDepth[depth]
  var lastParentByDepth = [];
  list.forEach(function (_ref, origIndex) {
    var depth = _ref.depth,
        rest = _objectWithoutProperties(_ref, ['depth']);

    var node = createNodeWithEmptyChildrenContainer(rest, contentPlugins);

    output.push(node);
    if (depth >= lastParentByDepth.length + 1) {
      throw new Error('Cannot nest node, depth change higher than +1');
    }
    // Removing empty children for easier visuals, it's not needed except for equality checks
    // when testing our algos.
    if (lastParentByDepth[depth] && hasNoChildren(lastParentByDepth[depth])) {
      removeEmptyChildrenWrapper(lastParentByDepth[depth]);
    }
    // Replacing earlier parents at same depth with node.
    lastParentByDepth[depth] = node;
    // Default for root nodes (depth === 0).
    if (depth === 0) {
      markAsRootNode(node);
    } else if (depth > 0) {
      addChild(lastParentByDepth[depth - 1], node, contentPlugins);
    } else {
      throw new Error('invalid depth value');
    }
  });
  // Finally, removing remaining empty children arrays.
  lastParentByDepth.forEach(function (node) {
    if (hasNoChildren(node)) {
      removeEmptyChildrenWrapper(node);
    }
  });

  return finalize(output);
}