/*
 * The concept of empty is currently only used for text nodes.
 * These are "empty" if their content is empty. They could still have children.
 * On empty, they will be unwrapped, i.e. their children will be made siblings
 * and the node will be deleted.
 *
 * In future, we may design a "isEmpty" function for tables and the like.
 * But there, I'll assume that the children can be neither ignored nor
 * unwrapped.
 */
import isEmptiableGigNode from './isEmptiableGigNode';
import getGigNodeContentPluginByKey from './getGigNodeContentPluginByKey';
import getClosestGigNodeByKey from './getClosestGigNodeByKey';

export default (function (editor, key) {
  if (!isEmptiableGigNode(editor, key)) {
    return;
  }
  var thisGigNode = getClosestGigNodeByKey(editor, key);
  var contentIsEmpty = getGigNodeContentPluginByKey(editor, key).defineNode.contentIsEmpty;

  return contentIsEmpty(thisGigNode);
});