// @flow

import React from 'react'
import styled from 'styled-components'
import Tooltip from 'rc-tooltip'

import useTOCNodes from '../connectors/useTOCNodes'
import useWipNode from '../connectors/useWipNode'
import useToggle from 'react-use/lib/useToggle'

const BlockTree = styled.ul`
  margin: 0;
  padding: 1em;
`

const BlockTreeNode = styled.li`
  padding-left: 0; /*$ {props => 0.5 * props.depth}em;*/
  list-style-type: none;
  font-size: 0.9em;
  line-height: 1.5em;
  color: ${props => (props.isCurrent ? '#333' : '#999')};
  overflow: hidden;
  display: block;
  white-space: nowrap;
  max-width: 100%;
  &:after {
    content: ' ';
  }
  text-overflow: ellipsis;
`

const Counter = styled.span`
  font-size: 0.3em;
  display: inline-block;
  padding-right: 2.5em;
`

export const Wrapper = styled.div`
  flex-shrink: 1;
  max-width: 0%;
  min-width: 25%;
  max-height: 100%;
  overflow: auto;
  flex-grow: 1;
  padding: 1rem;
`

const Toggle = styled.span`
  font-size: 14px;
  cursor: pointer;
  transition: 300ms;
  color: ${props => (props.isOpen ? '#333' : '#999')};
`

export default () => {
  const [isOpen, toggleOpen] = useToggle(false)
  const { selectedId } = useWipNode()
  const { countedNodes } = useTOCNodes()

  return (
    <Wrapper>
      <BlockTree>
        <Tooltip
          overlay="Table of Contents"
          overlayStyle={isOpen ? { display: 'none' } : {}}
          placement="bottom"
          mouseEnterDelay={1}
        >
          <Toggle
            isOpen={isOpen}
            className="icon-th-list"
            onClick={toggleOpen}
          />
        </Tooltip>
        {isOpen ? (
          <div>
            {countedNodes.map(({ node, count }) => {
              // const count = nextCount(node.depth)
              return (
                <BlockTreeNode
                  key={node.id}
                  isCurrent={node.id === selectedId}
                  depth={node.depth}
                >
                  <Counter>{count}</Counter>{' '}
                  {node.contentType === 'text'
                    ? node.value
                    : `[${node.contentType}]`}
                </BlockTreeNode>
              )
            })}
          </div>
        ) : null}
      </BlockTree>{' '}
    </Wrapper>
  )
}
