import React, { createContext, useMemo, useContext } from 'react';

var Cache = function Cache() {
  var data = {};

  return {
    get: function get(key, init) {
      if (!(key in data)) {
        data[key] = init();
      }

      return data[key];
    }
  };
};

var CacheContext = createContext();

/*
 * Usage:
 * const cache = useCachePerTick()
 * // initFn: returns value for key.
 * cache.get(key, initFn)
 *
 */
export var useCache = function useCache() {
  return useContext(CacheContext);
};

export var CacheProvider = function CacheProvider(_ref) {
  var children = _ref.children,
      deps = _ref.deps;

  // One cache for all nodes, gigNodes, contentNodes etc.
  // Consequently, we key entries by gigNodes so e.g. contentNodes and gigNodes point to same cache value.
  var cache = useMemo(function () {
    return Cache();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return React.createElement(
    CacheContext.Provider,
    { value: cache },
    children
  );
};