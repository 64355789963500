import { unwrapRemoveGigNodeByKey, forEachGigNode } from '../utils';
import { isDiffing, diffToggles } from '.';

export default (function (editor) {
  if (!isDiffing(editor)) {
    return;
  }

  editor.withoutNormalizing(function () {
    forEachGigNode(editor, function (gigNode) {
      if (diffToggles.isInsert(editor, gigNode.key) || diffToggles.isRemovableMergeRoot(editor, gigNode.key)) {
        unwrapRemoveGigNodeByKey(editor, gigNode.key);
      }
    });
  });
});