var _templateObject = _taggedTemplateLiteralLoose(['\n  display: inline-block;\n  vertical-align: middle;\n  margin: 0 0.1rem;\n  height: 50px;\n'], ['\n  display: inline-block;\n  vertical-align: middle;\n  margin: 0 0.1rem;\n  height: 50px;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  border-radius: 4px;\n  display: block;\n'], ['\n  border-radius: 4px;\n  display: block;\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

/* eslint-disable jsx-a11y/alt-text*/
import React from 'react';
import MediaWrapper from './MediaWrapper';

import styled from 'styled-components';

var SidebarImg = styled.img(_templateObject);

var MainImg = styled.img(_templateObject2);

var Sidebar = function Sidebar(_ref) {
  var value = _ref.value;

  return React.createElement(SidebarImg, { src: value.src });
};

var createMain = function createMain(fileLoader) {
  return function (_ref2) {
    var value = _ref2.value,
        domProps = _ref2.domProps;

    return React.createElement(MediaWrapper, {
      fileLoader: fileLoader,
      domProps: domProps,
      value: value,
      renderer: MainImg
    });
  };
};

var defineNode = {
  content: {
    isVoid: true
  }
};

var mimeSelector = function mimeSelector(mime) {
  var type = mime.replace(/\/.*/, '').toLowerCase();
  return type === 'image';
};

export default (function (_ref3) {
  var fileLoader = _ref3.fileLoader;
  return {
    Main: createMain(fileLoader),
    Sidebar: Sidebar,
    defineNode: defineNode,
    similarity: ['json'],
    mimeSelector: mimeSelector
  };
});