var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React, { useContext, useRef, useCallback } from 'react';
import FocusableDiv from './FocusableDiv';
import TitleEmphasis from './TitleEmphasis';
import { isContentNode, isTextGigNode } from '../../utils/node';
import { GigNodeContext } from '../gigNodeContext';
import { useMarkDiffPosition } from '../diffIndicator';
// import DeepAccept from './DeepAccept'
import useDetectTextChange from '../../useDetectTextChange';
import { INSERT, DELETE } from '@gigmade/msm-merge-util';
import { isDefaultContentType, convertChangesToInline } from '../../coreTextPlugin';

import { LayoutContent } from '../layout';

var ContentWrapper = function ContentWrapper(_ref) {
  var ContentRenderer = _ref.ContentRenderer,
      contentType = _ref.contentType,
      nodeIsFirst = _ref.nodeIsFirst,
      editor = _ref.editor,
      node = _ref.node,
      children = _ref.children,
      isFocused = _ref.isFocused,
      nodeValue = _ref.nodeValue;

  if (isDefaultContentType(contentType)) {
    var isMap = Boolean(editor.gigGetLayout());

    return (
      // On title only, add a h1, else pass through children.
      React.createElement(
        TitleEmphasis,
        { isMap: isMap, isTitle: nodeIsFirst },
        React.createElement(ContentRenderer, { editor: editor, node: node, value: children })
      )
    );
  }

  return (
    // <DeepAccept editor={editor} diffData={diffData}>
    React.createElement(
      FocusableDiv,
      { isFocused: isFocused },
      React.createElement(ContentRenderer, { value: nodeValue, isFocused: isFocused })
    )
    // </DeepAccept>

  );
};

var ContentPlugin = function ContentPlugin(_ref2) {
  var children = _ref2.children,
      editor = _ref2.editor,
      node = _ref2.node,
      attributes = _ref2.attributes,
      isFocused = _ref2.isFocused;

  var contentRef = useRef(null);

  var gigContext = useContext(GigNodeContext);
  var currentGigNode = gigContext.currentGigNode,
      isDiffing = gigContext.isDiffing,
      diff = gigContext.diff;

  // This is just the left vertical line for block changes.
  // We've decided to register that here because if we did it on the gigNode level,
  // it would be very long: a sequence change might cover lots of other change.

  useMarkDiffPosition(node.key, contentRef, undefined, !isDiffing || !diff.hasChange);

  // This helps us convert block level inserts and deletes of text to inline
  // changes once a user edits any part of the text.
  // This, together with the block
  var changeHandler = useCallback(function () {
    var type = diff.isInsert ? INSERT : DELETE;
    var accepted = type === INSERT ? diff.isAcceptedInsert : diff.isAcceptedDelete;
    editor.withoutSaving(function () {
      convertChangesToInline(editor, node, { type: type, accepted: accepted });
      // Resolve the block insert or delete in a way that it persists: reject if delete,
      // accept if insert.
      diff.getToggle(type).onFinalize(type === INSERT);
    });
  }, [editor, node, diff]);

  useDetectTextChange({
    skip: !isTextGigNode(currentGigNode) || !isDiffing || !diff.isInsert && !diff.isDelete,
    node: node,
    handler: changeHandler
  });

  return React.createElement(
    LayoutContent,
    {
      node: gigContext.currentGigNode,
      editor: editor,
      isFocused: isFocused,
      gigContext: gigContext
    },
    React.createElement(
      'div',
      _extends({ ref: contentRef }, attributes),
      React.createElement(
        ContentWrapper,
        _extends({}, gigContext, {
          editor: editor,
          node: node,
          isFocused: isFocused
        }),
        children
      )
    )
  );
};

export default (function () {
  var renderNode = function renderNode(_ref3, editor, next) {
    var node = _ref3.node,
        attributes = _ref3.attributes,
        children = _ref3.children,
        isFocused = _ref3.isFocused;

    if (!isContentNode(node)) {
      return next();
    }

    return React.createElement(
      ContentPlugin,
      {
        node: node,
        attributes: attributes,
        editor: editor,
        isFocused: isFocused
      },
      children
    );
  };

  return {
    renderNode: renderNode
  };
});