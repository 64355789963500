var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import { cloneElement, Children, useEffect, useState } from 'react';
import { DATA_LAYOUT2D_ID } from './constants';
import { useLayout2d } from '../react-layout2d';

export default (function (_ref) {
  var _dataAttributes;

  var children = _ref.children,
      nodeId = _ref.nodeId,
      first = _ref.first,
      layout2d = _ref.layout2d,
      domProps = _objectWithoutProperties(_ref, ['children', 'nodeId', 'first', 'layout2d']);

  var child = void 0;

  var _useLayout2d = useLayout2d(),
      _useLayout2d$ = _useLayout2d[0],
      data = _useLayout2d$.data,
      focusNodeKey = _useLayout2d$.focusNodeKey,
      focusWidth = _useLayout2d$.focusWidth,
      dispatch = _useLayout2d[1];

  var params = data && data.absoluteParams ? data.absoluteParams[nodeId] : null;

  var _useState = useState(null),
      nodeWidth = _useState[0],
      setNodeWidth = _useState[1];

  var minWidthForFixedNode = 300;

  useEffect(function () {
    if (!layout2d || focusNodeKey !== nodeId || !params) return;
    if (params.width < minWidthForFixedNode && params.height < 100 && !focusWidth) {
      setNodeWidth(minWidthForFixedNode);
      dispatch({ type: 'setFocusedWidth', value: minWidthForFixedNode });
    } else {
      setNodeWidth(params.width);
    }
  }, [params, dispatch, nodeWidth, focusNodeKey, nodeId, layout2d, focusWidth]);

  try {
    child = Children.only(children);
  } catch (e) {
    throw new Error('Each Layout2Node must wrap a single child');
  }

  var dataAttributes = (_dataAttributes = {}, _dataAttributes[DATA_LAYOUT2D_ID] = nodeId, _dataAttributes);

  var passedStyle = domProps.style,
      rest = _objectWithoutProperties(domProps, ['style']);

  var additionalStyle = void 0;

  if (layout2d) {
    if (params) {
      additionalStyle = {
        position: 'absolute',
        boxSizing: 'border-box',
        zIndex: focusNodeKey === nodeId ? 9999 : 1,
        paddingBottom: 0,
        margin: 0,
        display: 'block',
        opacity: 1,
        top: params.top,
        left: params.left,
        transition: '.3s'
      };

      if (focusNodeKey === nodeId) {
        additionalStyle.width = nodeWidth;
        additionalStyle.minHeight = params.height;
      } else {
        additionalStyle.width = params.width;
        additionalStyle.minHeight = params.height;
      }
    } else {
      additionalStyle = {
        visibility: 'hidden'
      };
    }
  }

  // FIXME: The part with child.props.style should be the reference implementation.
  // Notes: lodash merge?
  // var style = [child.props.style, { opacity: 0 }];
  // React.cloneElement(child, {style})
  var newChild = cloneElement(child, _extends({}, dataAttributes, rest, {
    style: _extends({}, child.props.style, passedStyle, additionalStyle)
  }));
  return newChild;
});