export default () => {
  const subscriptions = []

  const notifySubscribers = data => {
    subscriptions.forEach(subscriber => subscriber(data))
  }

  const subscribe = listener => {
    if (typeof listener !== 'function') {
      throw new Error('Expected the listener to be a function.')
    }

    let isSubscribed = true

    subscriptions.push(listener)

    return function unsubscribe() {
      if (!isSubscribed) {
        return
      }

      isSubscribed = false

      const index = subscriptions.indexOf(listener)
      subscriptions.splice(index, 1)
    }
  }

  return {
    notifySubscribers,
    subscribe,
  }
}
