import createGigNodeJson from './helpers/createGigNodeJson';
import { Block } from '@gigmade/slate';

export default (function (editor) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$value = _ref.value,
      value = _ref$value === undefined ? '' : _ref$value,
      _ref$children = _ref.children,
      children = _ref$children === undefined ? [] : _ref$children,
      _ref$type = _ref.type,
      type = _ref$type === undefined ? 'text' : _ref$type,
      _ref$skipContent = _ref.skipContent,
      skipContent = _ref$skipContent === undefined ? false : _ref$skipContent;

  return Block.fromJSON(createGigNodeJson({
    value: value,
    skipContent: skipContent,
    children: children,
    contentType: type
  }, editor.gigGetContentPlugins()));
});