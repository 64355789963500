import produce from 'immer';

var cacheName = 'diffCache';

export var getCache = function getCache(editor) {
  return editor.value.data.get(cacheName);
};

export var getToggleCache = function getToggleCache(editor, key, name) {
  // This can happen when the component still access an old diffcache
  // while the editor is switching to a new diff.
  if (!getCache(editor)) {
    return null;
  }

  return (getCache(editor)[key] || {})[name];
};

export var setCache = function setCache(editor, cache) {
  var oldCache = getCache(editor);

  // We can provide a function cache => newCache too.
  if (typeof cache === 'function') {
    cache = cache(oldCache);
  }

  if (cache === oldCache) {
    return;
  }

  var newData = editor.value.data.set(cacheName, cache);
  editor.setData(newData);
};

export var merge = function merge(cache, key, name, cacheItem) {
  return produce(cache, function (draft) {
    if (!draft[key]) {
      draft[key] = {};
    }

    if (draft[key][name] === cacheItem) {
      return;
    }

    draft[key][name] = cacheItem;
  });
};

export var addToCache = function addToCache(editor, key, name, cacheItem) {
  var cache = getCache(editor);
  var updated = merge(cache, key, name, cacheItem);
  setCache(editor, updated);
};

export var cacheExists = function cacheExists(editor) {
  return editor.value.data.get(cacheName) != null;
};

export var resetCache = function resetCache(editor) {
  setCache(editor, null);
};