var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { Fragment, useMemo, forwardRef } from 'react';
import makeInsertNodeOnPaddingClick from './makeInsertNodeOnPaddingClick';
import GigPadding from './GigPadding';

var noOp = function noOp() {};

/*
 * This discovers a click on our vertical padding and adds a new line if that seems
 * reasonable.
 * Using elements currentTarget because we want to measure relative to GigNodeWrapper,
 * always, and never some inner div.
 */
var isPaddingClick = function isPaddingClick(e) {
  var element = e.currentTarget;

  var _e$currentTarget$getB = e.currentTarget.getBoundingClientRect(),
      top = _e$currentTarget$getB.top;

  var style = window.getComputedStyle(element);
  var paddingTop = parseInt(style.getPropertyValue('padding-top'));
  var paddingBottom = parseInt(style.getPropertyValue('padding-bottom'));
  var totalHeight = element.offsetHeight;
  var eventOffsetY = parseFloat(e.clientY - top);
  return eventOffsetY < paddingTop ? 'top' : eventOffsetY > totalHeight - paddingBottom ? 'bottom' : false;
};

export default forwardRef(function (_ref, ref) {
  var flags = _ref.outerDom.flags,
      nodeIsFirst = _ref.nodeIsFirst,
      node = _ref.node,
      _ref$depthChange = _ref.depthChange,
      depthChange = _ref$depthChange === undefined ? 1 : _ref$depthChange,
      _ref$depth = _ref.depth,
      depth = _ref$depth === undefined ? 1 : _ref$depth,
      editor = _ref.editor,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ['outerDom', 'nodeIsFirst', 'node', 'depthChange', 'depth', 'editor', 'children']);

  var onPaddingClick = useMemo(function () {
    return node ? makeInsertNodeOnPaddingClick(editor, node) : noOp;
  }, [editor, node]);

  if (flags.skipLinedPadding) {
    console.error('FIXME: cannot put a ref');
    return children;
  }

  // TODO: We could design a separate div for padding which would simplify the click logic.
  return React.createElement(
    Fragment,
    null,
    React.createElement(
      GigPadding,
      _extends({}, rest, {
        ref: ref,
        depth: depth,
        depthChange: depthChange,
        nodeIsFirst: nodeIsFirst,
        onClick: function onClick(e) {
          var clickPos = isPaddingClick(e, e.currentTarget);
          if (!clickPos) {
            return;
          }
          e.stopPropagation();
          onPaddingClick(clickPos === 'top');
        }
      }),
      children
    )
  );
});