import React from 'react';

export default (function (_ref) {
  var extension = _ref.extension;

  extension = '.' + extension;
  if (extension.length > 6) {
    extension = '';
  }

  return React.createElement(
    'svg',
    { x: '0px', y: '0px', viewBox: '0 0 512 512' },
    React.createElement('polygon', {
      points: '322.01 426.85 323.37 426.07 322.01 426.07 322.01 426.85',
      fill: '#e6d6a6',
      fillRule: 'evenodd'
    }),
    React.createElement('rect', {
      x: '18.23',
      y: '9.1',
      width: '475.53',
      height: '493.81',
      rx: '43.89',
      ry: '43.89',
      fill: '#fff'
    }),
    React.createElement('path', {
      d: 'M269.59,88.2l-.05-.05H145.7a9.59,9.59,0,0,0-9.59,9.59V414.26a9.6,9.6,0,0,0,9.59,9.59H366.31a9.59,9.59,0,0,0,9.59-9.59V194.5H269.59Z',
      fill: '#bbb'
    }),
    React.createElement('polygon', {
      points: '269.59 194.49 375.89 194.49 269.59 88.2 269.59 194.49',
      fill: '#ddd'
    }),
    React.createElement('polygon', {
      points: '375.9 300.79 375.9 194.5 375.89 194.49 269.59 194.49 269.59 194.49 375.9 300.79',
      fill: '#333',
      opacity: '0.1'
    }),
    React.createElement(
      'text',
      { x: '135', y: '400', fontFamily: 'Arial', fontSize: '72', fill: '#fff' },
      extension
    )
  );
});