import { getClosestGigNodeByKey } from './';
import { isGigNode } from './node';

export default (function (editor, key) {
  var document = editor.value.document;


  var thisGigNode = getClosestGigNodeByKey(editor, key);

  var rightSibling = document.getNextSibling(thisGigNode.key);

  var rightSiblings = [];
  while (rightSibling) {
    // Normally, isGigNode is true, but we might call this during validation
    // where a node is wrongly placed during validation (dedentByKey)
    if (isGigNode(rightSibling)) {
      rightSiblings.push(rightSibling);
    }
    rightSibling = document.getNextSibling(rightSibling.key);
  }

  return rightSiblings;
});