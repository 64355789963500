/*
 * A deleted or inserted block is displayed like an inline change if it's text.
 * However, then the user can start editing inside that text.
 * The app understands that then, our user wants to keep the block but
 * work with the inline.
 * So here we convert our 'same' inline into a change inline and can
 * then edit on.
 *
 * TODO: Consider converting these right away? OK but that may change node structure.
 *
 */

import { Inline } from '@gigmade/slate';
import replaceNode from './textPlugin/replaceNode';
import { toSlate } from './textPlugin/';

var forEachInline = function forEachInline(node, fn) {
  node.forEachDescendant(function (desc) {
    if (desc.object !== 'inline') {
      return;
    }

    fn(desc);
  });
};

export default (function (editor, node, _ref) {
  var changeType = _ref.type,
      accepted = _ref.accepted;

  forEachInline(node, function (inline) {
    var inlineJson = toSlate([{ type: changeType, accepted: accepted, value: inline.text
      // index 1 because toSlate creates surrounding text leaves.
    }])[1];
    var newInline = Inline.fromJSON(inlineJson);

    var saveOffset = void 0;
    if (editor.value.selection.focus.key === inline.nodes.first().key) {
      saveOffset = editor.value.selection.focus.offset;
    }
    replaceNode(editor, inline, newInline);
    if (saveOffset) {
      editor.moveTo(newInline.nodes.first().key, saveOffset);
    }
  });
});