var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/*
 * Connectors is responsible for defining and drawing lines between
 * nodes in the map view.
 * It accepts the tree, map type as parameters, as well as getParent()
 * function helper.
 * One Connectors instance draws all connectors.
 */

import React, { Fragment, useEffect, useState } from 'react';
import { Line } from './styles';
import { getKeys } from './helper';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useLayout2d } from './Layout2dContext';

/*
 * Returns data needed to draw a line using css.
 */
var drawLine = function drawLine(x1, y1, x2, y2) {
  if (x2 < x1) {
    var tmp = x2;
    x2 = x1;
    x1 = tmp;
    var tmp2 = y2;
    y2 = y1;
    y1 = tmp2;
  }
  var lineLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  var m = (y2 - y1) / (x2 - x1);
  var degree = Math.atan(m) * 180 / Math.PI;
  return { degree: degree, lineLength: lineLength, x1: x1, y1: y1 };
};

/*
 * Layout2d will import this connector and draw the map.
 * This component depends on Layout2dContext.
 * It gets parameters for the nodes that we calculate in Layout2dContext
 * for position and size of content nodes.
 */
export default (function (_ref) {
  var tree = _ref.tree,
      type = _ref.type,
      getParent = _ref.getParent;

  var _useLayout2d = useLayout2d(),
      state = _useLayout2d[0];

  var nodeParams = state.data.connectParams;

  // One item per pair of nodes that have a line.
  // Array<{ degree, lineLength, x1, y1 }>

  var _useState = useState([]),
      lineProps = _useState[0],
      setLineProps = _useState[1];

  useDeepCompareEffect(function () {
    setLineProps([]);
  }, [setLineProps, nodeParams]);

  useEffect(function () {
    var coords = {};

    for (var key in nodeParams) {
      var params = nodeParams[key];
      coords[key] = {};
      coords[key]['top'] = [params.left + params.width / 2, params.top];
      coords[key]['bottom'] = [params.left + params.width / 2, params.bottom];
      coords[key]['middle'] = [params.right, params.top + params.height / 2];
      if (type === 'down') {
        coords[key]['to'] = coords[key]['top'];
      } else {
        coords[key]['to'] = [params.left, params.top + params.height / 2];
      }
    }

    var linePropsTmp = [];

    var keys = getKeys(tree);

    for (var _iterator = keys, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
      var _ref2;

      if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref2 = _iterator[_i++];
      } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref2 = _i.value;
      }

      var nodeKey = _ref2;

      var parent = getParent(nodeKey);
      var parentKey = parent ? parent.key : null;

      if (!coords[parentKey] || !coords[nodeKey]) continue;

      var fromCoord = null;
      if (Math.abs(coords[parentKey]['middle'][1] - coords[nodeKey]['to'][1]) < 10) {
        fromCoord = coords[parentKey]['middle'];
      } else if (Math.abs(coords[nodeKey]['to'][1] - coords[parentKey]['top'][1]) < Math.abs(coords[nodeKey]['to'][1] - coords[parentKey]['bottom'][1])) {
        fromCoord = coords[parentKey]['top'];
        if (fromCoord[1] < coords[nodeKey]['to'][1]) {
          fromCoord = coords[parentKey]['middle'];
          fromCoord[1] = coords[nodeKey]['to'][1];
        }
      } else {
        fromCoord = coords[parentKey]['bottom'];
        if (fromCoord[1] > coords[nodeKey]['to'][1]) {
          fromCoord = coords[parentKey]['middle'];
          fromCoord[1] = coords[nodeKey]['to'][1];
        }
      }

      linePropsTmp.push(drawLine(fromCoord[0], fromCoord[1], coords[nodeKey]['to'][0], coords[nodeKey]['to'][1]));
    }

    setLineProps(linePropsTmp);
  }, [setLineProps, tree, nodeParams, type, getParent]);

  return React.createElement(
    Fragment,
    null,
    lineProps.map(function (props, i) {
      return React.createElement(Line, _extends({ key: i }, props));
    })
  );
});