var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import { cloneElement, Children } from 'react';
import { DATA_FLIP_CONTENT_ID } from './constants';

export default (function (_ref) {
  var _dataAttributes;

  var children = _ref.children,
      flipId = _ref.flipId,
      domProps = _objectWithoutProperties(_ref, ['children', 'flipId']);

  var child = void 0;

  try {
    child = Children.only(children);
  } catch (e) {
    throw new Error('Each NestedFlippedContent must wrap a single child');
  }

  var dataAttributes = (_dataAttributes = {}, _dataAttributes[DATA_FLIP_CONTENT_ID] = flipId, _dataAttributes);

  var newChild = cloneElement(child, _extends({}, dataAttributes, domProps));

  return newChild;
});