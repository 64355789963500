import { getChildrenWrapper } from '../helpers/createGigNodeJson';
import { List } from 'immutable';
import isGigNode from './isGigNode';

export default (function (gigNode) {
  if (gigNode.object === 'document') {
    return gigNode.nodes;
  }
  var childrenWrapper = getChildrenWrapper(gigNode);
  // Note: slate 0.46.1, no leaves anymore, but code should work.
  // If childrenWrapper is empty, slate will add a leaf, therefore
  // we filter by isGigNode.
  // Note that we should be removing the childrenWrapper as part
  // of this transaction if that case happens.
  return childrenWrapper ? childrenWrapper.nodes.filter(isGigNode) : List();
});