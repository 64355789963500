export default (function () {
  var subscriptions = [];

  var notifySubscribers = function notifySubscribers(data) {
    subscriptions.forEach(function (subscriber) {
      return subscriber(data);
    });
  };

  var subscribe = function subscribe(listener) {
    if (typeof listener !== 'function') {
      throw new Error('Expected the listener to be a function.');
    }

    var isSubscribed = true;

    subscriptions.push(listener);

    return function unsubscribe() {
      if (!isSubscribed) {
        return;
      }

      isSubscribed = false;

      var index = subscriptions.indexOf(listener);
      subscriptions.splice(index, 1);
    };
  };

  return {
    notifySubscribers: notifySubscribers,
    subscribe: subscribe
  };
});