import getUserPath from './getUserPath';
import getPreviousGigNodeByKey from './getPreviousGigNodeByKey';
import isGigNode from './node/isGigNode';

export default (function (editor, key) {
  var document = editor.value.document;

  var thisGigNode = document.getNode(key);
  if (!isGigNode(thisGigNode)) {
    throw new Error('node ' + thisGigNode + ' is not of type gigNode');
  }
  var prevGigNode = getPreviousGigNodeByKey(editor, key);

  return prevGigNode == null ? 0 : getUserPath(editor, key).size - getUserPath(editor, prevGigNode.key).size;
});