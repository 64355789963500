/*
 * A node type is a container if it wraps its descendants with custom dom.
 * Note: We name this is"Directly" because while this function checks
 * whether a node is actually wrapped by a container, other functions
 * may check whether there's any container up the descendants tree.
 */
import someAncestorTypeDef from './someAncestorTypeDef';

export default (function (editor, key) {
  return someAncestorTypeDef(editor, key, function (plugin, i) {
    return Boolean(plugin.OuterContainer && plugin.descendants && plugin.descendants[i]);
  });
});