/*
 * This function removes surplus newlines all around,
 * except on users selections.
 * Benefit: clean up of anything enforceSingleLineOnEnter may have missed.
 * Con: Not real-time but slightly delayed.
 */
import { isEmptyTextGigNodeByKey, isRenderedByContainerAncestor, isRootGigNodeByKey, getFirstChildGigNode, getNextGigNodeByKey, unwrapRemoveGigNodeByKey, getGigNodesNearSelection } from '.';

import { isTextGigNode } from './node';
import { List } from 'immutable';
// import addTrailingText from './addTrailingText'

var add = function add(list, item) {
  if (list.indexOf(item) === -1) {
    list.push(item);
  }
};

export default (function (editor) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref$editMode = _ref.editMode,
      editMode = _ref$editMode === undefined ? false : _ref$editMode;

  var document = editor.value.document;

  var _getGigNodesNearSelec = getGigNodesNearSelection(editor),
      prevNode = _getGigNodesNearSelec.prevNode,
      startNode = _getGigNodesNearSelec.startNode,
      endNode = _getGigNodesNearSelec.endNode,
      nextNode = _getGigNodesNearSelec.nextNode;

  // Actually, we never want to delete an empty title node because that
  // would change the node structure in an unwanted way.
  // Note: See above comment on why this is not needed here.


  var exemptedNodes = [
    /*firstContent*/
  ];[prevNode, startNode, endNode, nextNode
  // firstContent,
  // lastContent
  ].forEach(function (node) {
    if (node && editMode) {
      add(exemptedNodes, node);
    }
  });

  // If the first child is the last node, we'll exempt it too because we minimally need
  // a title and a first paragraph node.
  var firstPara = getFirstChildGigNode(editor);
  if (!firstPara) {
    // Normally this shouldn't happen but this is a safety measure in case the
    // default-2-nodes schema is not active.
    return;
  }
  var nextPara = getNextGigNodeByKey(editor, firstPara.key);
  if (firstPara && !nextPara) {
    add(exemptedNodes, firstPara);
  }
  /*
   * Lets delete empty text nodes IF their (immediate) parents, if any, are atomic.
   */
  var emptyNodesToDelete = document.filterDescendants(function (node) {
    return isTextGigNode(node) && isEmptyTextGigNodeByKey(editor, node.key) &&
    // Just never remove root.
    !isRootGigNodeByKey(editor, node.key) &&
    // This avoids removing empty table cells.
    !isRenderedByContainerAncestor(editor, node.key);
  })
  // Exclude nodes exempted from deletion.
  .filter(function (node) {
    return exemptedNodes.indexOf(node) === -1;
  });

  if (emptyNodesToDelete.size) {
    // HACK
    // Because Slate does not easily allow merge operations, we mimic that behavior here by taking a snapshot of operations before and after
    // This helps avoid a bug in slate where withoutSave -> undo causes some paths to be undefined
    // Ideally, we would be running this function either inside of a normalization (but that has issues, see below comments), or we would be running it after a specific onChange and analyzing only where the selection just was, because we do not expect many empty lines to be created randomly
    var prevUndos = editor.value.getIn(['data', 'undos'], List());
    var prevLastBatch = prevUndos.last();

    var currentUndos = editor.value.getIn(['data', 'undos'], List());
    var currentLastBatch = currentUndos.last();

    emptyNodesToDelete.forEach(function (gigNode) {
      return unwrapRemoveGigNodeByKey(editor, gigNode.key);
    });

    if (prevLastBatch && currentLastBatch && prevUndos !== currentUndos) {
      var undos = currentUndos.skipLast(2).push(prevLastBatch.concat(currentLastBatch));

      var value = editor.value.setIn(['data', 'undos'], undos);

      editor.controller.setValue(value);
    }
  }
});