/*
 * Every plugin can self-register in uploadTypes to be part of DnD.
 * If multiple plugins match, the first one wins.
 * contentType: type name like 'image'
 * mimeSelector: function(mime) => bool
 */

/*
 * Return contentType of first of uploadTypes matching the mime, null if none found.
 */
export default (function (mime, uploadTypes) {
  var firstMatch = uploadTypes.find(function (_ref) {
    var contentType = _ref.contentType,
        mimeSelector = _ref.mimeSelector;

    return mimeSelector(mime);
  });
  return firstMatch ? firstMatch.contentType : null;
});