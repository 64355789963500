/*
 * The condition is called with the contentType definition,
 * and the distance to the parent of the gigNode with key `key`.
 */
import getGigNodeContentPluginByKey from './getGigNodeContentPluginByKey';
import getAncestorGigNodesByKey from './getAncestorGigNodesByKey';

export default (function (editor, key, condition) {
  // Note that this includes document.
  return getAncestorGigNodesByKey(editor, key, { includeSelf: false }).some(function (ancestor, i) {
    var plugin = getGigNodeContentPluginByKey(editor, ancestor.key);
    return condition(plugin, i);
  });
});