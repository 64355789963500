import { findGigNode } from '../utils';
import getDiffKey from './getDiffKey';

function gigNodeFromDiffedKey(editor, diffKey, ancestor) {
  return findGigNode(editor, function (node) {
    return getDiffKey(node) === diffKey;
  }, ancestor);
}

export function gigNodesFromDiffedKeys(editor, diffKeys, ancestor) {
  return diffKeys.map(function (diffKey) {
    return gigNodeFromDiffedKey(editor, diffKey, ancestor);
  });
}

export default gigNodeFromDiffedKey;