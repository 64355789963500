var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  display: flex;\n  flex-direction: row;\n'], ['\n  position: relative;\n  display: flex;\n  flex-direction: row;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  z-index: ', ';\n  flex-grow: ', ';\n  flex-shrink: 0;\n  flex-basis: ', ';\n'], ['\n  z-index: ', ';\n  flex-grow: ', ';\n  flex-shrink: 0;\n  flex-basis: ', ';\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { useRef } from 'react';
import styled from 'styled-components';
import DiffIndicator from './DiffIndicator';
import { DiffIndicatorProvider } from './diffIndicatorContext';

var Container = styled.div(_templateObject);

var Item = styled.div(_templateObject2, function (props) {
  return props.addZIndex ? '993' : 'initial';
}, function (props) {
  return props.fixedWidth ? 0 : 1;
}, function (props) {
  return props.fixedWidth ? 'auto' : '100%';
});

var HookedRender = function HookedRender(_ref) {
  var children = _ref.children;

  var containerRef = useRef(null);

  return React.createElement(
    Container,
    { ref: containerRef },
    React.createElement(
      DiffIndicatorProvider,
      { baseRef: containerRef },
      React.createElement(
        Item,
        { fixedWidth: true, addZIndex: true },
        React.createElement(DiffIndicator, null)
      ),
      React.createElement(
        Item,
        null,
        children
      )
    )
  );
};

export default (function () {
  var renderEditor = function renderEditor(props, editor, next) {
    // The Provider needs to wrap the DiffIndicator for consuming measurements
    // and the editor for consuming api's to update the measurements.
    return React.createElement(
      HookedRender,
      null,
      next()
    );
  };

  return { renderEditor: renderEditor };
});