export default (function () {
  return {
    schema: {
      blocks: {
        voidContent: {
          isVoid: true
        }
      }
    }
  };
});