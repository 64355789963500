import { PathUtils } from '@gigmade/slate';
var isAbove = PathUtils.isAbove;


export default (function (editor, ancKey) {
  for (var _len = arguments.length, desKeys = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    desKeys[_key - 2] = arguments[_key];
  }

  var document = editor.value.document;

  var ancPath = document.getPath(ancKey);
  var desPaths = desKeys.map(function (key) {
    return document.getPath(key);
  });

  return desPaths.every(function (path) {
    return isAbove(ancPath, path);
  });
});