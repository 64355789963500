var _templateObject = _taggedTemplateLiteralLoose(['\n  position: ', ';\n  left: ', ';\n  top: ', ';\n  height: ', ';\n  width: 8px;\n  z-index: 9999;\n  background-repeat: no-repeat;\n  background-position-y: 50%;\n  background-color: ', ';\n  &:hover {\n    background-color: transparent;\n  }\n  cursor: pointer;\n  display: ', ';\n  &::after {\n    content: \'\';\n    background-image: ', ';\n    background-repeat: repeat-y;\n    opacity: 0.5;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    position: absolute;\n  }\n'], ['\n  position: ', ';\n  left: ', ';\n  top: ', ';\n  height: ', ';\n  width: 8px;\n  z-index: 9999;\n  background-repeat: no-repeat;\n  background-position-y: 50%;\n  background-color: ', ';\n  &:hover {\n    background-color: transparent;\n  }\n  cursor: pointer;\n  display: ', ';\n  &::after {\n    content: \'\';\n    background-image: ', ';\n    background-repeat: repeat-y;\n    opacity: 0.5;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    position: absolute;\n  }\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  background-image: linear-gradient(to right, ', ' 50%, transparent);\n  position: relative;\n  display: block;\n  height: 0;\n  opacity: 0;\n  z-index: 1;\n  &:after {\n    position: absolute;\n    top: -6px;\n    left: -12px;\n    content: \'\';\n    width: 0;\n    height: 0;\n    border-top: 7px solid transparent;\n    border-left: 14px solid ', ';\n    border-bottom: 7px solid transparent;\n  }\n'], ['\n  background-image: linear-gradient(to right, ', ' 50%, transparent);\n  position: relative;\n  display: block;\n  height: 0;\n  opacity: 0;\n  z-index: 1;\n  &:after {\n    position: absolute;\n    top: -6px;\n    left: -12px;\n    content: \'\';\n    width: 0;\n    height: 0;\n    border-top: 7px solid transparent;\n    border-left: 14px solid ', ';\n    border-bottom: 7px solid transparent;\n  }\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  position: ', ';\n  width: 100%;\n  background: ', ';\n  top: 0;\n  left: 0;\n  margin-top: 0;\n  z-index: 1;\n'], ['\n  position: ', ';\n  width: 100%;\n  background: ', ';\n  top: 0;\n  left: 0;\n  margin-top: 0;\n  z-index: 1;\n']),
    _templateObject4 = _taggedTemplateLiteralLoose(['\n  border: ', ';\n  border-radius: ', ';\n'], ['\n  border: ', ';\n  border-radius: ', ';\n']),
    _templateObject5 = _taggedTemplateLiteralLoose(['\n  height: 1px;\n  border-top: 2px solid ', ';\n  transform-origin: top left;\n  position: fixed;\n  z-index: 2;\n  &:after {\n    position: absolute;\n    top: -6px;\n    right: -8px;\n    content: \'\';\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: ', ';\n    //border-top: 7px solid transparent;\n    //border-left: 14px solid ', ';\n    //border-bottom: 7px solid transparent;\n  }\n'], ['\n  height: 1px;\n  border-top: 2px solid ', ';\n  transform-origin: top left;\n  position: fixed;\n  z-index: 2;\n  &:after {\n    position: absolute;\n    top: -6px;\n    right: -8px;\n    content: \'\';\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: ', ';\n    //border-top: 7px solid transparent;\n    //border-left: 14px solid ', ';\n    //border-bottom: 7px solid transparent;\n  }\n']),
    _templateObject6 = _taggedTemplateLiteralLoose([''], ['']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import styled from 'styled-components';
import { animated } from 'react-spring';
import dots from './dots2.png';
//import dots from './dots.png'
// import dots from './dots3.png'

var dndColor = '#e66767'; // rgb(230, 103, 103)
var placeColor = '#f1f2f6'; // rgb(209,216,224)
var borderColor = '#a4b0be';

export var Handler = styled.div(_templateObject, function (props) {
  return props.layout2d === 'true' ? 'fixed' : 'absolute';
}, function (props) {
  return props.layout2d === 'true' ? props.left + 'px' : '2px';
}, function (props) {
  return props.layout2d === 'true' ? props.top + 'px' : '5px';
}, function (props) {
  return props.layout2d === 'true' && props.height ? props.height + 'px' : 'calc(100% - 10px)';
}, function (p) {
  if (p.selected) {
    return 'transparent';
  } else {
    return 'transparent';
  }
}, function (p) {
  var focus = p.focus;
  if (!p.top && p.layout2d !== '') focus = false;
  if (focus) {
    return 'block';
  } else {
    return 'none';
  }
}, function (p) {
  if (p.selected) return 'none';else return 'url(' + dots + ')';
});

export var Place = styled(animated.div)(_templateObject2, dndColor, dndColor);
export var PlaceOriginal = styled(animated.div)(_templateObject3, function (props) {
  return props.layout2d === 'true' ? 'absolute' : 'relative';
}, placeColor);
export var DndNode = styled(animated.div)(_templateObject4, function (p) {
  return p.selected ? '1px dashed ' + borderColor : 'none';
}, function (p) {
  return p.selected ? '0.2em' : 'none';
});
export var Line = styled(animated.div)(_templateObject5, dndColor, dndColor, dndColor);

export var Ghost = styled(animated.div)(_templateObject6);