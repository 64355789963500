import { createGigNode, insertSiblingGigNode, getClosestGigNodeByKey, appendGigNodeChild, isRootGigNodeByKey } from '../../utils';
import { isTextGigNode } from '../../utils/node';
import { toNormalizedLines, isIndented, collectIndentedLines } from './lines';

var createTextGigNode = function createTextGigNode(editor, line) {
  return createGigNode(editor, { contentType: 'text', value: line });
};

var insertAsChildOf = function insertAsChildOf(editor, insertKey, line) {
  var newNode = createTextGigNode(editor, line);
  appendGigNodeChild(editor, insertKey, newNode);
  return newNode.key;
};

var insertAsSiblingOf = function insertAsSiblingOf(editor, insertKey, line, isFirstLine) {
  var newNode = createTextGigNode(editor, line);
  insertSiblingGigNode(editor, insertKey, newNode);
  return newNode.key;
};

var insertAsInlineOf = function insertAsInlineOf(editor, insertKey, line) {
  var insertGigNode = getClosestGigNodeByKey(editor, insertKey);
  var currentText = insertGigNode.getFirstText();
  editor.insertTextByKey(currentText.key, currentText.text.length, line);
};

var insertLinesAtKey = function insertLinesAtKey(editor, insertKey, lines) {
  var isFirstLine = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var document = editor.value.document;

  var index = 0;
  while (index < lines.length) {
    // Skip empty lines
    // Doing this in context allows us to skip bulleted empty lines too.
    var line = lines[index];
    if (line.trim() === '') {
      index++;
      continue;
    }

    if (!isIndented(lines, index)) {
      // Now, insert the flat list. Except for the root node that is attached
      // to the document: That needs to be inserted as a child.
      if (insertKey === document.key) {
        // This would happen if we insert at document root, but in practice it
        // won't because validation ensures 2 top nodes always.
        insertKey = insertAsChildOf(editor, insertKey, line);
      } else if (isFirstLine && isTextGigNode(getClosestGigNodeByKey(editor, insertKey))) {
        // Insert text inline and no need for a new insertKey.
        // For now we treat non-'text'-type gigNodes as not being able to accept an
        // inline plaintext insert.
        insertAsInlineOf(editor, insertKey, line);
      } else if (isRootGigNodeByKey(editor, insertKey)) {
        // We can only have one root.
        insertKey = insertAsChildOf(editor, insertKey, line);
      } else {
        insertKey = insertAsSiblingOf(editor, insertKey, line);
      }
      index++;
      continue;
    }

    // Regular, non-inline, insert for future lines.
    isFirstLine = false;

    // We found an indented line.
    var indentedLines = collectIndentedLines(lines, index);
    var first = indentedLines[0],
        rest = indentedLines.slice(1);

    var nestedKey = insertAsChildOf(editor, insertKey, first);
    insertLinesAtKey(editor, nestedKey, rest);
    // Skip to the next non-indented line
    index += indentedLines.length;
  }
};

export default (function (editor, insertKey, text) {
  var lines = toNormalizedLines(text);
  editor.withoutNormalizing(function () {
    insertLinesAtKey(editor, insertKey, lines, true);
  });
});