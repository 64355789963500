var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n  max-width: 90vw;\n'], ['\n  position: relative;\n  max-width: 90vw;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  visibility: hidden;\n  position: absolute;\n  user-select: none;\n  top: 0;\n  left: 0;\n'], ['\n  visibility: hidden;\n  position: absolute;\n  user-select: none;\n  top: 0;\n  left: 0;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  background: #95c1b1;\n  border: 1px solid #cecece;\n'], ['\n  background: #95c1b1;\n  border: 1px solid #cecece;\n']);

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

/*
 * Plugin positioning: first renderEditor, but last of the renderNode
 */

import React, { Fragment, useEffect, useCallback, useContext, useState, useMemo, useRef, forwardRef } from 'react';
// import { Flipper, Flipped } from 'react-flip-toolkit'
import { getRootGigNode,
// getKeyById,
insertChildGigNode, insertSiblingGigNode, getParentGigNodeByKey, isRootGigNodeByKey, getSelectedGigNode } from '../utils';
import { getChildrenGigNodes /*getId*/ } from '../utils/node';

import { SlateContext } from '../SlateEditor';
import useLatestDifferent from '../useLatestDifferent';

// Note that *Flipped* need to wrap DOM nodes!
import { NestedFlipper, NestedFlipped, NestedFlippedContent as NFC } from '../react-nested-flip';
import { STATUS_DISABLED, STATUS_ENABLED } from '../react-nested-flip/constants';

import { DragDropTree, DragNode, DragDropContent as DDC } from '../react-nested-dnd';

import { Layout2dNode, Layout2d, Layout2dFlexbox, Layout2dContent, useLayout2d, Layout2dProvider } from '../react-layout2d';

import CardWrapper from '../CardWrapper';

import styled from 'styled-components';

// FIXME: Should export from Layout
var isMap = function isMap(option) {
  return option === 'right' || option === 'down';
};

// import { useSerializedNodes } from '../SerializedNodesContext'

var RelativeContainer = styled.div(_templateObject);

var HiddenLayoutPortal = styled.div(_templateObject2);

var layoutSelectorName = 'layout';

// We're actually simply using node key instead of node id.
// We've seen some duplicate ids and this can make dnd fail.
var getKeyById = function getKeyById(editor, nodeKey) {
  return nodeKey;
};
var getId = function getId(node) {
  return node.key;
};

var getTreeStructure = function getTreeStructure(editor) {
  var node = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (node == null) {
    node = getRootGigNode(editor);
  }

  return {
    key: getId(node),
    children: getChildrenGigNodes(node).map(function (child) {
      return getTreeStructure(editor, child);
    }).toArray()
  };
};

var NestedFlippedGigNode = function NestedFlippedGigNode(_ref) {
  var children = _ref.children,
      node = _ref.node,
      domProps = _objectWithoutProperties(_ref, ['children', 'node']);

  return React.createElement(
    NestedFlipped,
    _extends({ flipId: getId(node) }, domProps),
    children
  );
};

var NestedFlippedContent = function NestedFlippedContent(_ref2) {
  var children = _ref2.children,
      node = _ref2.node,
      domProps = _objectWithoutProperties(_ref2, ['children', 'node']);

  return React.createElement(
    NFC,
    _extends({ flipId: getId(node) }, domProps),
    children
  );
};

var DragDropGigNode = function DragDropGigNode(_ref3) {
  var children = _ref3.children,
      node = _ref3.node,
      editor = _ref3.editor,
      layout2d = _ref3.layout2d,
      domProps = _objectWithoutProperties(_ref3, ['children', 'node', 'editor', 'layout2d']);

  var _useLayout2d = useLayout2d(),
      state = _useLayout2d[0];

  var data = state.data;

  var fixedParams = data ? data.fixedParams : null;

  var insertAsChild = useCallback(function (nodeId, parentId) {
    var nodeKey = getKeyById(editor, nodeId);
    var parentKey = getKeyById(editor, parentId);

    insertChildGigNode(editor, parentKey, nodeKey, 0);
  }, [editor]);

  var insertAfterSibling = useCallback(function (nodeId, siblingId) {
    var nodeKey = getKeyById(editor, nodeId);
    var siblingKey = getKeyById(editor, siblingId);

    insertSiblingGigNode(editor, siblingKey, nodeKey);
  }, [editor]);

  var insertBeforeSibling = useCallback(function (nodeId, siblingId) {
    var nodeKey = getKeyById(editor, nodeId);
    var siblingKey = getKeyById(editor, siblingId);

    insertSiblingGigNode(editor, siblingKey, nodeKey, true);
  }, [editor]);

  return React.createElement(
    DragNode,
    _extends({
      insertAsChild: insertAsChild,
      insertAfterSibling: insertAfterSibling,
      insertBeforeSibling: insertBeforeSibling,
      dragId: getId(node),
      layout2d: layout2d,
      fixedParams: fixedParams
    }, domProps),
    children
  );
};

var DragDropContent = function DragDropContent(_ref4) {
  var children = _ref4.children,
      node = _ref4.node,
      domProps = _objectWithoutProperties(_ref4, ['children', 'node']);

  return React.createElement(
    DDC,
    _extends({ dragId: getId(node) }, domProps),
    children
  );
};

export var LayoutGigNode = forwardRef(function (_ref5, cardRef) {
  var children = _ref5.children,
      node = _ref5.node,
      editor = _ref5.editor,
      attributes = _ref5.attributes,
      gigContext = _ref5.gigContext;

  var layoutOption = editor.value.data.get(layoutSelectorName);

  var isFirst = useMemo(function () {
    return isRootGigNodeByKey(editor, node.key);
  }, [editor, node]);

  // In standard layout, the card wrapper wraps the content and its children.
  // In map layout, it doesn't (but will wrap the content).
  var wrappedCard = isMap(layoutOption) ? React.createElement(
    'div',
    _extends({}, attributes, { ref: cardRef }),
    children
  ) : React.createElement(
    CardWrapper,
    _extends({}, attributes, {
      ref: cardRef
    }, gigContext),
    children
  );

  return React.createElement(
    DragDropGigNode,
    { node: node, editor: editor, layout2d: layoutOption },
    React.createElement(
      NestedFlippedGigNode,
      { node: node },
      React.createElement(
        Layout2dNode,
        {
          nodeId: getId(node),
          layout2d: layoutOption,
          first: isFirst
        },
        wrappedCard
      )
    )
  );
});

/*
 * Goals:
 * - only one node at a time should be "focused"
 * - if multiple nodes are focused, keep last used configuration
 *   to avoid fast-changing node sizes.
 */
var useIsFocused = function useIsFocused(editor, node, isFocused) {
  var _useState = useState(isFocused),
      focused = _useState[0],
      setFocused = _useState[1];

  // Turn mutable editor into immutable selection.


  var selection = editor.value.selection;


  useEffect(function () {
    // We sometimes return false even if isFocused, but never true if not.
    // This also avoids calculating selection too often.
    if (!isFocused || !node || !editor) {
      setFocused(false);
      return;
    }

    var focusGigNode = getSelectedGigNode(editor);
    var focusGigNodeKey = focusGigNode ? focusGigNode.key : null;
    var anchorGigNode = getSelectedGigNode(editor, true);
    var anchorGigNodeKey = anchorGigNode ? anchorGigNode.key : null;

    // If the selection is multi-gigNode-select, we don't change anything.
    // This avoids getting in to an awkward UI that's constantly changing sizes.
    if (focusGigNodeKey !== anchorGigNodeKey) {
      return;
    }

    setFocused(focusGigNodeKey === node.key);
  }, [editor, node, isFocused, selection]);

  if (!isFocused) {
    return false;
  }

  return focused;
};

var FirstMapNode = styled.div(_templateObject3);

export var LayoutContent = function LayoutContent(_ref6) {
  var children = _ref6.children,
      node = _ref6.node,
      editor = _ref6.editor,
      isFocused = _ref6.isFocused,
      gigContext = _ref6.gigContext;

  var layoutOption = editor.value.data.get(layoutSelectorName);

  var focused = useIsFocused(editor, node, isFocused);

  // Only in map layout, we directly wrap the content with the card
  // wrapper.
  // This gives us freedom to make map specific styling changes here.
  var wrappedContent = isMap(layoutOption) ? React.createElement(
    CardWrapper,
    gigContext,
    children
  ) : children;

  if (isMap(layoutOption) && gigContext.nodeIsFirst) {
    wrappedContent = React.createElement(
      FirstMapNode,
      null,
      wrappedContent
    );
  }

  return React.createElement(
    DragDropContent,
    { node: node },
    React.createElement(
      NestedFlippedContent,
      { node: node },
      React.createElement(
        Layout2dContent,
        {
          layout2dId: node.key,
          layout2d: layoutOption,
          isFocused: focused
        },
        wrappedContent
      )
    )
  );
};

/*
 * Like LayoutContent, this receives the card node.
 * We can use its key to assign an inverse scale factor
 */
export var LayoutChildren = function LayoutChildren(_ref7) {
  var children = _ref7.children,
      node = _ref7.node,
      editor = _ref7.editor;

  // First, try wrapping it in a scaled div.
  // Then, lets try cloning it and applying scale styles.
  return children;
};

var InnerRenderEditor = function InnerRenderEditor(_ref8) {
  var tree = _ref8.tree,
      layout2d = _ref8.layout2d,
      editor = _ref8.editor,
      children = _ref8.children;

  var relativeContainer = useRef();

  var _useState2 = useState(STATUS_ENABLED),
      animate = _useState2[0],
      setAnimate = _useState2[1];

  var _useContext = useContext(SlateContext),
      pauseCleanNode = _useContext.pauseCleanNode;

  var resumeCleaning = useRef();

  var onDnDStart = useCallback(function () {
    resumeCleaning.current = pauseCleanNode();
    setAnimate(STATUS_DISABLED);
  }, [pauseCleanNode]);

  var onDnDEnd = useCallback(function () {
    if (resumeCleaning.current) {
      resumeCleaning.current();
      resumeCleaning.current = null;
    }
  }, []);

  useEffect(function () {
    setAnimate(STATUS_ENABLED);
  }, [tree]);

  var _useLayout2d2 = useLayout2d(),
      state = _useLayout2d2[0];

  var data = state.data,
      valuesReady = state.valuesReady;


  var getParent = useCallback(function (nodeId) {
    return getParentGigNodeByKey(editor, getKeyById(editor, nodeId));
  }, [editor]);

  var inner = React.createElement(
    Fragment,
    null,
    layout2d && valuesReady && React.createElement(Layout2dFlexbox, {
      tree: tree,
      containerRef: relativeContainer,
      getParent: getParent,
      type: layout2d
    }),
    React.createElement(
      DragDropTree,
      {
        tree: tree,
        onBeforeCallback: onDnDStart,
        onAfterCallback: onDnDEnd
      },
      React.createElement(
        NestedFlipper,
        {
          tree: tree,
          active: animate,
          layout2d: layout2d,
          fixedParams: data.fixedParams,
          needCalculateInitial: !layout2d || valuesReady && data.fixedParams
        },
        React.createElement(
          Layout2d,
          { layout2d: layout2d, tree: tree, getParent: getParent },
          children
        )
      )
    )
  );

  return React.createElement(
    RelativeContainer,
    { ref: relativeContainer },
    inner
  );
};

var OuterRenderEditor = function OuterRenderEditor(_ref9) {
  var layoutOption = _ref9.layoutOption,
      children = _ref9.children,
      editor = _ref9.editor,
      newTree = _ref9.tree;

  // TODO(Nataliia): treeStructure is same as tree but:
  // - without "value"
  // - less updates.
  // Check if this is sufficient and replace tree.
  // If later we need to judge the difference between values (has a user typed?),
  // then I will add const { treeStructure, contentsByKey } = useSerializedNodes()
  // and you can check contentsByKey.
  // const { treeStructure } = useSerializedNodes()
  // const {
  //   treeData: { treeStructure }
  // } = useContext(SlateContext)
  var tree = useLatestDifferent(newTree);

  var portal = useRef();

  if (!tree) {
    return null;
  }

  return (
    // Relative is here, used originally only by NestedFlipper
    // but later by Layout2dProvider too.
    React.createElement(
      Fragment,
      null,
      React.createElement(
        'div',
        null,
        React.createElement(
          Layout2dProvider,
          { tree: tree, type: layoutOption, portalRef: portal },
          React.createElement(
            InnerRenderEditor,
            {
              editor: editor,
              tree: tree,
              layout2d: layoutOption
            },
            children
          )
        )
      ),
      React.createElement(HiddenLayoutPortal, { ref: portal })
    )
  );
};

var renderEditor = function renderEditor(props, editor, next) {
  // For now, flip on diff only.
  // if (!props.diff) {
  //   return next()
  // }

  var tree = getTreeStructure(editor);

  var layoutOption = editor.value.data.get(layoutSelectorName);

  return React.createElement(
    OuterRenderEditor,
    { layoutOption: layoutOption, tree: tree, editor: editor },
    next()
  );
};

var gigSetLayout = function gigSetLayout(editor, layoutOption) {
  var newData = editor.value.data.set(layoutSelectorName, layoutOption);
  editor.setData(newData);
};

var gigGetLayout = function gigGetLayout(editor) {
  return editor.value.data.get(layoutSelectorName);
};

export default (function () {
  return {
    renderEditor: renderEditor,
    commands: {
      gigSetLayout: gigSetLayout
    },
    queries: {
      gigGetLayout: gigGetLayout
    }
  };
});