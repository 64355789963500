export default (function (ref, baseRef) {
  var refBox = ref.getBoundingClientRect();
  var baseBox = baseRef.current.getBoundingClientRect();

  // const top = ref.offsetTop
  // const left = ref.offsetLeft

  var top = refBox.top - baseBox.top;
  var left = refBox.left - baseBox.left;

  return {
    top: top,
    height: ref.offsetHeight,
    left: left,
    width: ref.offsetWidth
  };
});