var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  line-height: 1.5;\n  margin: 0;\n  padding: 0;\n'], ['\n  line-height: 1.5;\n  margin: 0;\n  padding: 0;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  ', '\n  color: ', ';\n'], ['\n  ', '\n  color: ', ';\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { useRef, useCallback } from 'react';
import styled from 'styled-components';
import finalizeInline from './finalizeInline';
import useDetectTextChange from '../../useDetectTextChange';
import flatten from 'lodash/flatten';

var P = styled.p(_templateObject);

var Span = styled.span(_templateObject2, function (props) {
  return props.type === 'delete' ? 'text-decoration: line-through;' : '';
}, function (props) {
  return props.color;
});

var Change = function Change(_ref) {
  var editor = _ref.editor,
      isActive = _ref.isActive,
      attributes = _ref.attributes,
      children = _ref.children,
      onFinalize = _ref.onFinalize,
      color = _ref.color,
      type = _ref.type,
      useMarkDiffPosition = _ref.useMarkDiffPosition,
      AcceptIgnore = _ref.AcceptIgnore,
      node = _ref.node;

  var ref = useRef(null);

  var changeHandler = useCallback(function (text) {
    if (!text.length) {
      editor.removeNodeByKey(node.key);
    }
  }, [editor, node]);

  useDetectTextChange({
    node: node,
    handler: changeHandler
  });

  useMarkDiffPosition(node.key, ref);

  return React.createElement(
    AcceptIgnore,
    { type: type, text: node.text, onFinalize: onFinalize },
    React.createElement(
      'span',
      { onMouseDown: function onMouseDown(e) {
          return e.stopPropagation();
        } },
      React.createElement(
        Span,
        _extends({}, attributes, {
          ref: ref,
          color: color,
          type: type,
          isActive: true
        }),
        children
      )
    )
  );
};

var Same = function Same(_ref2) {
  var children = _ref2.children,
      attributes = _ref2.attributes;

  return React.createElement(
    'span',
    attributes,
    children
  );
};

/*
 * Tentative API:
 * - Sidebar: Render value into diff sidebar for labeling selection toggles.
 * - Main: Render type into main diff window.
 *   If no InBlockChange in export, render diffs using Main as well.
 * - Container, InBlockChange, InBlockSame: For rendering diffs
 *   with diff-controlled logic, passing every insert/same/delete
 *   separately. (Currently used in text only.)
 */

// const Sidebar = ({ value }) => {
//   return `"${value}"`
// }

var Container = function Container(_ref3) {
  var domProps = _ref3.domProps,
      children = _ref3.children;

  return React.createElement(
    P,
    domProps,
    children
  );
};

var InlineRenderer = function InlineRenderer(_ref4) {
  var editor = _ref4.editor,
      node = _ref4.node,
      blockChange = _ref4.blockChange,
      useMarkDiffPosition = _ref4.useMarkDiffPosition,
      AcceptIgnore = _ref4.AcceptIgnore,
      attributes = _ref4.attributes,
      colorScheme = _ref4.colorScheme,
      children = _ref4.children;
  var type = node.type,
      data = node.data;

  // We're not using value because value is passed through the slate node.

  var _data$toJSON = data.toJSON(),
      orphanType = _data$toJSON.type,
      accepted = _data$toJSON.accepted;

  var calculatedType = type;

  if (accepted && type === 'change') {
    calculatedType = orphanType === 'insert' ? 'same' : 'hide';
  }

  var onFinalize = function onFinalize(accept) {
    return finalizeInline(editor, node.key, accept);
  };

  if (blockChange != null) {
    calculatedType = 'change';
    orphanType = blockChange.type;
    onFinalize = blockChange.onFinalize;
    // This is currently our only defined state for inline track change anyway.
    // But TODO: need to watch that, clean up in future.
    accepted = false;
  }

  switch (calculatedType) {
    case 'hide':
      {
        return React.createElement('span', attributes);
      }
    case 'same':
      {
        return React.createElement(
          Same,
          { attributes: attributes },
          children
        );
      }
    case 'change':
      {
        return React.createElement(
          Change,
          {
            editor: editor,
            useMarkDiffPosition: useMarkDiffPosition,
            AcceptIgnore: AcceptIgnore,
            node: node,
            attributes: attributes,
            color: colorScheme.compare.change
            // altValue={neighbor && neighbor.value}
            , onFinalize: onFinalize
            // key={index}
            , accepted: accepted,
            type: orphanType
          },
          children
        );
      }
    default:
      {
        throw new Error('Missing renderer for type "' + type + '"');
      }
  }
};

var Main = function Main(_ref5) {
  var value = _ref5.value,
      domProps = _ref5.domProps,
      node = _ref5.node,
      contents = _ref5.contents,
      colorScheme = _ref5.colorScheme;

  return React.createElement(
    Container,
    { domProps: domProps },
    value
  );
};

export var toSlate = function toSlate(value) {
  if (!Array.isArray(value)) {
    // Was: no custom element required.
    // return null
    // The reason we are wrapping this in a custom inline is that now
    // we can use that wrapper to convert a normal paragraph to
    // a insert or delete, IF the gigNode that owns it is a insert or
    // delete.
    return [{ object: 'text', text: '' }, {
      type: 'same',
      object: 'inline',
      nodes: [{ object: 'text', text: value }]
    }, { object: 'text', text: '' }];
  }

  return flatten(value.map(function (item) {
    // A matching piece of (inline) text is represented by a string value.
    if (typeof item === 'string') {
      return {
        object: 'text',
        text: item
        // return {
        //   type: 'same',
        //   object: 'inline',
        //   nodes: [{ object: 'text', text: item }]
        // }
      };
    }

    // Item is an object
    // Note: We used 'accepted' to toggle the item back and forth in the
    // previous api.
    // But now, any finalized toggles are immediately converted to regular text.
    // Remaining 'change' types are converted per the 'accepted' value when
    // the whole tree gets converted back to a regular tree.
    var type = item.type,
        accepted = item.accepted,
        value = item.value;

    // Array means diff, and we have custom dom wrappers.
    // The flatMap flattens neighboring change definitions that are toggled
    // together.

    return [{ object: 'text', text: '' }, {
      type: 'change',
      object: 'inline',
      data: { type: type, accepted: accepted },
      nodes: [{ object: 'text', text: value }]
    }, { object: 'text', text: '' }];
  }));
};

var defineNode = {
  content: {
    isVoid: false,
    toSlate: toSlate
  },
  contentIsEmpty: function contentIsEmpty(gigNode) {
    return gigNode.nodes.first().text.trim().length === 0;
  },
  fromSlate: function fromSlate(gigNode) {
    var toJson = function toJson(node) {
      switch (node.object) {
        case 'text':
          {
            return node.text;
          }
        case 'inline':
          {
            switch (node.type) {
              case 'same':
                {
                  // will be a node.object == text
                  return toJson(node.nodes[0]);
                }
              case 'change':
                {
                  return {
                    type: node.data.type,
                    value: toJson(node.nodes[0]),
                    accepted: node.data.accepted
                  };
                }
              default:
                {
                  throw new Error('Unknown node type "' + node.type + '".');
                }
            }
          }
        default:
          {
            throw new Error('Unknown node object "' + node.object + '".');
          }
      }
    };

    // children nodes of contentNode.
    var nodes = gigNode.nodes[0].nodes;

    var result = nodes.map(toJson);

    var out = [];
    result.forEach(function (item) {
      if (item === '') {
        return;
      }

      if (typeof item === 'string' && typeof out[out.length - 1] === 'string') {
        out[out.length - 1] += item;
        return;
      }

      out.push(item);
    });

    if (!out.length) {
      return '';
    }
    if (out.length === 1 && typeof out[0] == 'string') {
      return out[0];
    }

    return out;
  }
};

export default (function () {
  return {
    Main: Main,
    InlineRenderer: InlineRenderer,
    defineNode: defineNode,
    similarity: ['plainText']
  };
});