import { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import {
  subscribeApiDiffChange,
  subscribeEditorChange,
  getAll,
  onEditorChange,
  onExternalChange,
  onExternalNew,
  getTitle,
  hasTitle,
  hasText,
} from '../localState/wipNode'

const getData = () => {
  const data = {
    ...getAll(),
    title: getTitle(),
    hasTitle: hasTitle(),
    hasText: hasText(),
  }

  return data
}

export default () => {
  const [localContent, setLocalContent] = useState(getData())

  useEffect(() => {
    const load = () => {
      setLocalContent(data => {
        const newData = getData()
        return isEqual(newData, data) ? data : newData
      })
    }

    // Between initial useState and the useEffect, getData() can (and does) change.
    load()
    const unsubscribe1 = subscribeEditorChange(load)
    const unsubscribe2 = subscribeApiDiffChange(load)

    return () => {
      unsubscribe1()
      unsubscribe2()
    }
  }, [])

  return { ...localContent, onEditorChange, onExternalChange, onExternalNew }
}
