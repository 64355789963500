import React from 'react'

// import Title from './Title'
import EditorControls from './EditorControls'
import { /*TopBar,*/ Toolbar } from './Nav.style'

export default ({ loading, history }) => {
  return (
    <Toolbar>
      <EditorControls />
    </Toolbar>
  )
}
