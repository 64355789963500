var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  position: absolute;\n  top: ', 'px;\n  left: ', 'px;\n  width: ', 'px;\n  height: ', 'px;\n'], ['\n  position: absolute;\n  top: ', 'px;\n  left: ', 'px;\n  width: ', 'px;\n  height: ', 'px;\n']);

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colorScheme } from '@gigmade/msm-ui';

var Svg = styled.svg(_templateObject, function (props) {
  return props.top;
}, function (props) {
  return props.left;
}, function (props) {
  return props.width;
}, function (props) {
  return props.height;
});

export default forwardRef(function (_ref, ref) {
  var dimensions = _ref.dimensions,
      _ref$rotate = _ref.rotate,
      rotate = _ref$rotate === undefined ? 0 : _ref$rotate,
      domProps = _objectWithoutProperties(_ref, ['dimensions', 'rotate']);

  return React.createElement(
    Svg,
    _extends({
      ref: ref
    }, domProps, dimensions, {
      x: '0px',
      y: '0px',
      viewBox: '0 0 100 100',
      xmlns: 'http://www.w3.org/2000/svg',
      'fill-rule': 'evenodd',
      'clip-rule': 'evenodd',
      stroke: colorScheme.compare.changeLine,
      fill: colorScheme.compare.changeLine,
      strokeWidth: '5%'
    }),
    React.createElement(
      'g',
      { transform: 'rotate(-' + rotate + ' 50 50)' },
      React.createElement('path', { d: 'm90.339752,53.120506l-30.928253,40.591438l2.646179,4.980339l36.980766,-48.963627l-36.980766,-48.690197l-2.65028,4.973833l30.936451,40.597956l-89.920766,0l0,6.510258l89.916669,0z' })
    )
  );
});