import createGigNode from './createGigNode';
import addContent from './addContent';
import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import insertChildGigNode from './insertChildGigNode';

export default (function (editor, contentKey) {
  var document = editor.value.document;

  var contentToWrap = document.getNode(contentKey);
  var gigContainer = getClosestGigNodeByKey(editor, contentKey);

  // Create a new gigNode, add it in the gigNode where the content node was
  // found, and then add the content node to it.
  // Note that addContent depends on the gigNode being in the document.
  var newGigNode = createGigNode(editor, { skipContent: true });
  insertChildGigNode(editor, gigContainer.key, newGigNode, 0);
  addContent(editor, newGigNode.key, contentToWrap);
  return newGigNode;
});