var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  /* override tippy */\n  text-align: initial;\n'], ['\n  /* override tippy */\n  text-align: initial;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  display: inline-block;\n  vertical-align: top;\n  max-width: 14em;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n'], ['\n  display: inline-block;\n  vertical-align: top;\n  max-width: 14em;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  display: inline-block;\n  outline: none;\n  border: none;\n  cursor: pointer;\n  background: none;\n  padding: 0;\n  &:last-child {\n    margin-left: 1em;\n  }\n  color: #fff;\n  text-decoration: underline;\n  &:hover {\n    text-decoration: none;\n  }\n'], ['\n  display: inline-block;\n  outline: none;\n  border: none;\n  cursor: pointer;\n  background: none;\n  padding: 0;\n  &:last-child {\n    margin-left: 1em;\n  }\n  color: #fff;\n  text-decoration: underline;\n  &:hover {\n    text-decoration: none;\n  }\n']);

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

// Future:
// Single tippy instance?
// https://github.com/atomiks/tippyjs/blob/d6021864e19abf4e4e2f92421d8d36db1a772889/website/src/components/examples/Singleton.js

import React, { forwardRef, useEffect, useRef, useState, Fragment } from 'react';
import styled from 'styled-components';
import useClickAway from 'react-use/lib/useClickAway';
import Tippy from '@tippy.js/react';
import subscriptions from './makeSubscription';

var _subscriptions = subscriptions(),
    notifySubscribers = _subscriptions.notifySubscribers,
    subscribe = _subscriptions.subscribe;

var TooltipContainer = styled.div(_templateObject);

var TextSpan = styled.span(_templateObject2);

var TooltipButton = styled.button(_templateObject3);

var capitalize = function capitalize(str) {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

var AcceptIgnoreTooltip = function AcceptIgnoreTooltip(_ref) {
  var type = _ref.type,
      text = _ref.text,
      onAccept = _ref.onAccept,
      onIgnore = _ref.onIgnore,
      children = _ref.children;

  return React.createElement(
    Fragment,
    null,
    React.createElement(
      TooltipContainer,
      null,
      React.createElement(
        'div',
        null,
        capitalize(type),
        text && ': "',
        text && React.createElement(
          TextSpan,
          null,
          text
        ),
        text && '"'
      ),
      React.createElement(
        TooltipButton,
        { onMouseDown: onAccept },
        'Accept'
      ),
      React.createElement(
        TooltipButton,
        { onMouseDown: onIgnore },
        'Ignore'
      )
    ),
    children
  );
};

export default (function (_ref2) {
  var type = _ref2.type,
      text = _ref2.text,
      onFinalize = _ref2.onFinalize,
      OverlayComponent = _ref2.OverlayComponent,
      children = _ref2.children,
      tippyProps = _objectWithoutProperties(_ref2, ['type', 'text', 'onFinalize', 'OverlayComponent', 'children']);

  var overlay = OverlayComponent ? React.createElement(OverlayComponent, null) : null;

  return React.createElement(
    Tippy,
    _extends({
      interactive: true,
      animation: 'scale',
      delay: [70, 0],
      placement: 'right',
      flipBehavior: ['left', 'top'],
      arrow: true
    }, tippyProps, {
      content: React.createElement(
        AcceptIgnoreTooltip,
        {
          type: type,
          text: text,
          onAccept: function onAccept() {
            return onFinalize(true);
          },
          onIgnore: function onIgnore() {
            return onFinalize(false);
          }
        },
        overlay
      )
    }),
    children
  );
});

var ClickAwayComponent = function ClickAwayComponent(_ref3) {
  var children = _ref3.children,
      close = _ref3.close;

  var tooltipRef = useRef(null);
  useClickAway(tooltipRef, function () {
    close();
  });

  return React.createElement(
    'div',
    { ref: tooltipRef },
    children
  );
};

export var ShowOnHover = forwardRef(function (_ref4, ref) {
  var children = _ref4.children,
      content = _ref4.content;

  var _useState = useState(false),
      showOverlay = _useState[0],
      setShow = _useState[1];

  useEffect(function () {
    var unsubscribe = subscribe(function () {
      return setShow(false);
    });

    return unsubscribe;
  });

  return React.createElement(
    'div',
    {
      ref: ref,
      onMouseOver: function onMouseOver() {
        notifySubscribers();
        setShow(true);
      }
    },
    children,
    showOverlay && React.createElement(
      ClickAwayComponent,
      { close: function close() {
          return setShow(false);
        } },
      content
    )
  );
});