export var getKeys = function getKeys(tree) {
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  if (!tree) return list;
  list.push(tree.key);(tree.children || []).forEach(function (child) {
    getKeys(child, list);
  });

  return list;
};

export var getKeysAndDepth = function getKeysAndDepth(tree) {
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  list.push(tree.key + '/' + depth);(tree.children || []).forEach(function (child) {
    getKeysAndDepth(child, list, depth + 1);
  });

  return list;
};