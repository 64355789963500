var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React, { createContext, useContext, useEffect, useReducer, useMemo } from 'react';
import { getKeys } from './helper';

// All these parameters are calculated in Layout2dFlexbox.js
// They are the parameters that will be consumed by Connectors,
// Layout2dNode.
// Note: NestedFlipper.js also consumes fixedParams from here.
var initial = {
  // absoluteParams are used in Layout2dNode for styling nodes.
  // It contains absolute ContentNode positions for the map.
  absoluteParams: null,
  // fixedParams: this stores the current left/top positions of cards aka gigNodes.
  // Here, left/top are just measuring the card left/top
  fixedParams: null,
  // connectParams are parameters for drawing map lines (dimensions, position)
  // Here, left/top need to account for containers (padding/margin)
  connectParams: null
};

var initialState = {
  // positions
  data: initial,
  // Related to Layout2dContent. It contains all child react elements by key
  // (the same that we also render).
  // We use the values to build the flexbox in Layout2dFlexbox.
  values: {},
  // When the last value is read (values size is the same as tree)
  // this is true. => Then we build flexbox.
  valuesReady: false,
  // tree change => update treeLength => Used to calculate valuesReady
  treeLength: 0,
  focusNodeKey: null
};

var Layout2dContext = createContext();

function layout2dReducer(state, action) {
  switch (action.type) {
    case 'setNodeMapParams':
      {
        return _extends({}, state, { data: action.value });
      }
    case 'setTreeLength':
      {
        return _extends({}, state, { treeLength: action.value });
      }
    case 'setFocusedWidth':
      {
        return _extends({}, state, { focusWidth: action.value });
      }
    case 'setFocusNodeKey':
      {
        return _extends({}, state, { focusNodeKey: action.value });
      }
    case 'setValue':
      {
        var newStateValues = _extends({}, state.values, action.value);
        var lengthValues = Object.keys(newStateValues).length;
        var valuesReady = lengthValues > 1 && state.treeLength <= lengthValues;
        return _extends({}, state, { values: newStateValues, valuesReady: valuesReady });
      }
    default:
      {
        throw new Error('Unhandled action type: ' + action.type);
      }
  }
}

export var Layout2dProvider = function Layout2dProvider(_ref) {
  var tree = _ref.tree,
      type = _ref.type,
      portalRef = _ref.portalRef,
      children = _ref.children;

  var _useReducer = useReducer(layout2dReducer, _extends({}, initialState, {
    portalRef: portalRef
  })),
      state = _useReducer[0],
      dispatch = _useReducer[1];

  useEffect(function () {
    if (!type) return;
    var keys = getKeys(tree);
    dispatch({ type: 'setTreeLength', value: keys.length });
  }, [tree, type]);

  var value = useMemo(function () {
    return [state, dispatch];
  }, [state, dispatch]);

  return React.createElement(
    Layout2dContext.Provider,
    { value: value },
    children
  );
};

export function useLayout2d() {
  var context = useContext(Layout2dContext);
  if (context === undefined) {
    throw new Error('useLayout2d must be used within a Layout2dProvider');
  }
  return context;
}