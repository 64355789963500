/*
 * readOne should become readTitle, readDocument.
 */

import makeSubscription from '../makeSubscription'
import isEqual from 'lodash/isEqual'

const {
  notifySubscribers: notifyExternalChange,
  subscribe: subscribeApiDiffChange,
} = makeSubscription()
const {
  notifySubscribers: notifyEditorChange,
  subscribe: subscribeEditorChange,
} = makeSubscription()

// export { subscribe }
export { subscribeApiDiffChange, subscribeEditorChange }

const localContent = {
  node: null,
  selectedId: null,
  sessionNumber: null,
  undoable: false,
  // This marks our initial node definition as invalid and the node as being
  // loaded from the api (even if it turns out empty).
  // In particular, we want to avoid the editor loading the nodes [] and
  // subsequently call onEditorChange with a fresh node changed by validation.
  loading: true,
}

export const onExternalRefresh = undoable => {
  const session = localContent.sessionNumber
  localContent.sessionNumber = session == null ? 0 : session + 1
  localContent.undoable = undoable
}

const onApiDiffChange = (node, undoable) => {
  onExternalRefresh(undoable)

  const previousNode = localContent.node
  // Just change the node if the new one is different
  if (!isEqual(previousNode, node)) {
    localContent.node = node
  }

  // But in any case, set loading to false (even if same node.)
  localContent.loading = false
  // For now, just reset selected id (TOC, and possibly later editor.)
  localContent.selectedId = null

  notifyExternalChange()
}

// New edit session, no undo history.
export const onExternalNew = ({ node }) => {
  onApiDiffChange(node, false)
}
// Same edit session, extend undo history.
export const onExternalChange = ({ node }) => {
  onApiDiffChange(node, true)
}

export const onEditorChange = ({ node, selectedId }) => {
  let changed = false
  const previousNode = localContent.node
  if (selectedId !== localContent.selectedId) {
    localContent.selectedId = selectedId
    changed = true
  }

  if (!isEqual(previousNode, node)) {
    localContent.node = node
    changed = true
  }

  if (changed) {
    notifyEditorChange()
  }
}

// export const readOne = key => localContent[key]
export const getTitle = () => {
  if (!localContent.node || !localContent.node.length) {
    return ''
  }
  const { contentType, value } = localContent.node[0]

  if (contentType !== 'text') {
    return ''
  }

  // TODO!! Better integration, perhaps use our own editor clone here?
  return Array.isArray(value)
    ? value
        .map(item => {
          return typeof item === 'string'
            ? item
            : item.type === 'delete'
            ? item.value
            : ''
        })
        .join('')
    : value
}

export const hasText = () => {
  return localContent.node && localContent.node.length > 1
}

export const hasTitle = () => {
  const title = getTitle()
  return title.length > 0
}

export const getAll = () => localContent

export const getNode = () => {
  return localContent.node
}
