var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import fixContentAndChildrenSchema from './schemas/fixContentAndChildren';
import idSchema from './schemas/id';
import defaultValueSchema from './schemas/defaultValue';
import voidToTextSchema from './schemas/voidToText';
import voidElementSchema from './schemas/voidElement';
import placeholder from './placeholder';
import indenting from './indenting';
import renderPluginTopBars from './renderPluginTopBars';
import renderRootWrapper from './renderRootWrapper';
import { diffIndicatorPlugin } from './diffIndicator';
import { diffCachePlugin } from '../diff';
import dndMedia from './dndMedia';
import enforceSingleLineOnEnter from './enforceSingleLineOnEnter';
import cleanState from './cleanState';
import selectedIdQuery from './selectedIdQuery';
import injectContentPlugins from './injectContentPlugins';
import onPaste from './onPaste';
import gigNodePlugin from './gigNodePlugin';
import { addGigNodeContext } from './gigNodeContext';
import childrenWrapperPlugin from './childrenWrapperPlugin';
import contentNodePlugin from './contentNodePlugin';
import inlinePlugin from './inlinePlugin';
import extractNodesPlugin from './extractNodesPlugin';
import layout from './layout';

export default (function (_ref) {
  var pauseSave = _ref.pauseSave,
      getCleanNodePaused = _ref.getCleanNodePaused,
      addMessage = _ref.addMessage,
      titlePlaceholder = _ref.titlePlaceholder,
      textPlaceholder = _ref.textPlaceholder,
      fileLoader = _ref.fileLoader,
      debug = _ref.debug,
      contentPlugins = _ref.contentPlugins,
      RootWrapper = _ref.RootWrapper;

  var uploadTypes = [];

  // Create a flat plugin list for easier consumption, and fill uploadTypes.
  var contentPluginsList = Reflect.ownKeys(contentPlugins).map(function (key) {
    var plugin = _extends({}, contentPlugins[key], { key: key
      // Add to the list of media that can be uploaded.
    });if (plugin.mimeSelector) {
      uploadTypes.push({
        contentType: key,
        mimeSelector: plugin.mimeSelector
      });
    }

    return plugin;
  });

  var plugins = [injectContentPlugins({ contentPlugins: contentPlugins }), diffCachePlugin({ debug: debug }), indenting(), selectedIdQuery(), dndMedia({
    fileLoader: fileLoader,
    pauseSave: pauseSave,
    uploadTypes: uploadTypes,
    addMessage: addMessage
  }), enforceSingleLineOnEnter(), placeholder({ type: 'title', placeholder: titlePlaceholder }), placeholder({ type: 'text', placeholder: textPlaceholder }),
  // Validation
  idSchema({ debug: false }), voidElementSchema(),
  // TODO: this causes infinite loop
  fixContentAndChildrenSchema({ debug: debug }), defaultValueSchema({ debug: debug }), voidToTextSchema({ debug: debug }), onPaste(),
  // Add a context for gigNodes, contentNodes and childrenWrappers.
  // This context adds everything from `getCurrentGigNodeInfo`.
  // This adds gigNode related data such as userPath, but also calculates
  // Components that are needed to wrap our plugins.
  addGigNodeContext({ debug: debug }),

  // This only responds to gigNodes and:
  // - renders gigNode wrappers that are the same for every gigNode
  //   irrespective of its type such as the line counter,
  //   except if it has a directive to skip certain wrappers.
  // - Wraps the gigNode Components calculated by the `addGigNodeContext`.
  // - Adds a 'context menu' to each node level (currently used to indicate
  //   where selected traces can point to).
  gigNodePlugin({ contentPlugins: contentPluginsList }),
  // This only responds to childrenWrapper nodes and:
  // - wraps react components from the amove gignode context around the nodes
  //   E.g.: a table renders a `<tbody>` for the children wrapper.
  childrenWrapperPlugin({ contentPlugins: contentPluginsList }),
  // This only responds to `contentNodes` and:
  // - renders a text paragraph (optionally rendered as a title), or
  //   a react component per contentPlugins specification.
  contentNodePlugin(),
  // In diff, we sometimes (textPlugin) need control on the inline level.
  // This plugin controls that and calls the `InlineRenderer` as appropriate.
  inlinePlugin(),

  // contentPlugins can optionally have their own top bars, see trace-plugin for
  // an example. This is rendered in this plugin.
  renderRootWrapper({ RootWrapper: RootWrapper }), renderPluginTopBars({ contentPlugins: contentPluginsList }), diffIndicatorPlugin()].concat(contentPluginsList, [layout()]);
  // cleanState uses a second editor instance, so make sure it just generally
  // has same behaviour / plugins as the real one.
  // Also, extractToNode depends on cleanState, it shouldn't really matter
  // where it goes.
  plugins.push(cleanState({ plugins: plugins, copyProps: ['isDiffing'], getCleanNodePaused: getCleanNodePaused }), extractNodesPlugin({ contentPlugins: contentPlugins }));

  return plugins;
});