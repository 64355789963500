// Later, we could make utils contentType => editor => returnValue.
// But perhaps it's just useful like this.
export default (function (_ref) {
  var contentPlugins = _ref.contentPlugins;

  return {
    queries: {
      gigGetContentPlugins: function gigGetContentPlugins() {
        return contentPlugins;
      }
    }
  };
});