import styled from 'styled-components'

// import { widthCss } from './VariantsSidebar'
const widthCss = '1em'

// Child of EditorWrapper
export const ScrollableEditorView = styled.div`
  overflow: auto;
  max-height: 100%;
  width: 100%;
  /* Removing flex-shrink: 0 because that removes scrollbar */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  figure {
    margin: 0;
  }

  /*
   * This is done to not break editor scroll.
   * However, it prevents blocks from setting their own max-width.
   * Need to find another solution: either just setting overflow,
   * or somehow being able to reference nested react components of type "Block"
   * or similar.
  img {
    display: block;
    max-width: 20%;
  }
   */
`

// Outer-most
export const EditorWrapper = styled.div`
  visibility: ${props => (props.hide ? 'hidden' : 'inherit')};
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
`

// Inner-most wrapper
export const EditorRoot = styled.div`
  /* position: relative helps counter and tracing context menu */
  position: relative;
  /* 3.2 seems what's needed for the table main button */
  padding-left: 1em;
  padding-right: ${widthCss};
`
