/*
 * This gets the selected contentNode(s), and
 * the contentNode before the selection start, and
 * the contentNode after the selection end.
 * Note: doesn't get inner selection nodes.
 */
import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import getPreviousGigNodeByKey from './getPreviousGigNodeByKey';
import getNextGigNodeByKey from './getNextGigNodeByKey';

export default (function (editor) {
  var selection = editor.value.selection;


  var startPath = selection.start.path;
  var endPath = selection.end.path;

  var startNode = startPath ? getClosestGigNodeByKey(editor, startPath) : null;
  var endNode = endPath ? getClosestGigNodeByKey(editor, endPath) : null;

  var prevNode = startPath ? getPreviousGigNodeByKey(editor, startPath) : null;
  var nextNode = endPath ? getNextGigNodeByKey(editor, endPath) : null;

  return {
    prevNode: prevNode,
    startNode: startNode,
    endNode: endNode,
    nextNode: nextNode
  };
});