/*
 * Note: I haven't managed to harmonize this addContent with
 * the json based addContent inside createGigNodeJson.js
 * A first step may be to better understand what capability needs to reside
 * at the contentType interface.
 *
 */
import getGigNodeContentPluginByKey from './getGigNodeContentPluginByKey';
import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import insertChild from './plumbing/insertChild';
import hasChildren from './node/hasChildren';
import { contentNodeType } from './helpers/createGigNodeJson';

export default (function (editor, gigNodeKey, contentNode) {
  var thisGigNode = getClosestGigNodeByKey(editor, gigNodeKey);
  if (hasChildren(thisGigNode)) {
    throw new Error('Call addContent before node has children');
  }
  var plugin = getGigNodeContentPluginByKey(editor, gigNodeKey);
  var contentDef = plugin.defineNode.content;

  if (!contentDef) {
    throw new Error('Do not add content to gigNode without content definition.');
  }
  if (!contentNodeType(contentDef) === contentNode.type) {
    throw new Error('Content node type mismatch.');
  }

  insertChild(editor, gigNodeKey, contentNode, 0);
});