var bulletReg = /^\s*[-*•♣o.]\s+/;
var whiteSpaceReg = /^[\t\f]/;

// From draft-js convertFromHTMLToContentBlocks.js
var NBSP = '&nbsp;';
var SPACE = ' ';
var LF = '\n';

var REGEX_CR = /\r/g;
var REGEX_NBSP = new RegExp(NBSP, 'g');
var REGEX_CARRIAGE = new RegExp('&#13;?', 'g');
var REGEX_ZWS = new RegExp('&#8203;?', 'g');

var toLines = function toLines(text) {
  return (text = text.replace(REGEX_CR, LF).replace(REGEX_NBSP, SPACE).replace(REGEX_CARRIAGE, LF).replace(REGEX_ZWS, LF).split(LF)).filter(function (line) {
    return line.trim() !== '';
  });
};

/*
 * The reason for standardizing indents instead of ad-hoc parsing is
 * that word text paste just has different bullet styles for different
 * indent levels, so we need to assign different indents to symbols.
 */
var normalizedIndents = function normalizedIndents(lines) {
  var indents = [];

  return lines.map(function (line) {
    // debugger

    var indent = getIndent(line);
    if (!indent) {
      return line;
    }

    var index = indents.indexOf(indent);
    if (index === -1) {
      index = indents.length;
      indents.push(indent);
    }
    // Zero position in indents array means first indent => + 1.
    // One more for the join operation.
    var normalizedIndent = Array(index + 2).join('\t');
    return line.replace(indent, normalizedIndent);
  });
};

var getIndent = function getIndent(line) {
  // Search for bullets
  var bulletMatch = line.match(bulletReg);
  if (bulletMatch) {
    return bulletMatch[0];
  }

  // Detect 2-spaces indents.
  if (line.startsWith(' ')) {
    var candidate = '  ';
    if (line.startsWith(candidate)) {
      return candidate;
    }
    return;
  }

  var whiteSpaceMatch = line.match(whiteSpaceReg);

  if (whiteSpaceMatch) {
    return whiteSpaceMatch;
  }
};

export var isIndented = function isIndented(lines, index) {
  var line = lines[index];

  return !!getIndent(line);
};

/*
 * Collect indented lines including further nested lines, then return a version
 * without indent decorations.
 */
export var collectIndentedLines = function collectIndentedLines(lines, index) {
  var out = [];

  var indent = getIndent(lines[index]);

  for (var i = index; i < lines.length && lines[i].startsWith(indent); i++) {
    out.push(lines[i].replace(indent, ''));
  }
  return out;
};

export var toNormalizedLines = function toNormalizedLines(text) {
  var lines = toLines(text);
  return normalizedIndents(lines);
};