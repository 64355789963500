import createSharedHook from 'react-shared-hook'
import { useState, useEffect } from 'react'
import {
  subscribe,
  getEditorState,
  setEditorState,
  getEditorValue,
} from '../localState/opaqueEditorState'

const initialState = {
  editorState: null,
  // See editorState.js on why we need editorValue despite not using it.
  editorValue: null,
}

const [useSharedHook, setter] = createSharedHook(useState, initialState)

export default () => {
  useEffect(() => {
    const set = () => {
      setter({
        editorState: getEditorState(),
        editorValue: getEditorValue(),
      })
    }

    const unsubscribe = subscribe(set)

    // FIXME: Setting now makes the traces disappear.
    // Why? Check later, when we fix the nodes-update-immediately issue.
    // set()

    return unsubscribe
  }, [])

  return {
    ...useSharedHook(),
    setEditorState,
  }
}
