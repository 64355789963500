var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

// Later: dynamically load, restrict access, etc.
import list from './listPlugin';
import image from './imagePlugin';
import attachment from './attachmentPlugin';

// This is always there.
import coreTextPlugin from '../coreTextPlugin';

// Diffs
import diffRootPlugin from './diffRootPlugin';
import alternativeDiffPlugin from './alternativeDiffPlugin';

/*
 * Mapping of the API node name to the component.
 */
export default [{ key: 'image', plugin: image }, { key: 'attachment', plugin: attachment }, coreTextPlugin, { key: 'list', plugin: list }, { key: 'alternative', plugin: alternativeDiffPlugin }, { key: 'diff-root', plugin: diffRootPlugin }];

export var setup = function setup(pluginDefs, config) {
  return Object.assign.apply(Object, [{}].concat(pluginDefs.map(function (_ref) {
    var _ref2;

    var key = _ref.key,
        plugin = _ref.plugin;

    return _ref2 = {}, _ref2[key] = plugin(_extends({ dataKey: key }, config)), _ref2;
  })));
};