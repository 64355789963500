import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import getGigNodeStructureByKey from './getGigNodeStructureByKey';
import { PathUtils } from '@gigmade/slate';

export default (function (editor, key) {
  var document = editor.value.document;

  var thisGigNode = getClosestGigNodeByKey(editor, key);

  var _getGigNodeStructureB = getGigNodeStructureByKey(editor, thisGigNode.key),
      firstChildOffset = _getGigNodeStructureB.firstChildOffset;

  var path = document.getPath(thisGigNode.key);
  // path.last === 0 is a contentNode.
  if (path.last() <= firstChildOffset) {
    return null;
  }
  return document.getNode(PathUtils.decrement(path));
});