/*
 * Unwrap the node's children into the parent node, and then remove the node.
 * Cf. removeGigNodeByKey.
 */
import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import unwrapGigNodesByParentKey from './unwrapGigNodesByParentKey';

export default (function (editor, key) {
  var thisGigNode = getClosestGigNodeByKey(editor, key);
  editor.withoutNormalizing(function () {
    // If key is the root key: since we delete it, we can
    // temporarily tolerate 2 root keys; thus the option.
    unwrapGigNodesByParentKey(editor, thisGigNode.key, { allowTwoRoots: true });
    editor.removeNodeByKey(thisGigNode.key);
  });
});