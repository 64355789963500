import styled from 'styled-components'

export const Avatar = styled.img`
  border-radius: 100px;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  width: auto;
  display: inline-block;
  align-self: center;
`

export const Button = styled.button`
  font-size: 1em;
  padding: 0.3rem 1rem;
  display: block;
  cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  transition: 250ms;
  outline: none;
  border: none;
  border-left: 1px solid #eee;
  background: #fff;
  color: ${props => (props.disabled ? '#999' : '#666')};
  position: relative;

  input {
    display: none;
  }

  & > * {
    vertical-align: middle;
  }

  &:hover {
    background: ${props => (props.disabled ? 'inherit' : '#eee')};
  }
`

export const BlockButton = styled.div`
  border: none;
  position: relative;
  background: none;
  opacity: 0.7;
  cursor: pointer;
  font-size: 1em;
  padding: 0em;

  &:hover {
    opacity: 1;
  }

  * {
    cursor: pointer;
  }

  button {
    width: 100%;
    background: transparent;
    border: none;
    height: 100%;
    position: absolute;
    top: 0;
    outline: none;
    left: 0;
  }

  input {
    opacity: 0;
    width: 100%;
    height: 1px;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const BlockToolbar = styled.div`
  position: fixed;
  transition: 500ms;
  display: flex;
  cursor: pointer;
  margin-top: 6px;
  align-items: center;
  opacity: ${props => (props.isVisible === true ? '1' : '0')};
  flex-direction: column;
  top: ${props => props.top}px;
  left: ${props => props.left - 40}px;
`
