var _templateObject = _taggedTemplateLiteralLoose(['\n  display: inline-block;\n  position: relative;\n  align-self: flex-end;\n  font-size: 0.6em;\n  padding-right: 2em;\n  color: #888;\n  user-select: none;\n\n  &:hover {\n    color: #000;\n  }\n'], ['\n  display: inline-block;\n  position: relative;\n  align-self: flex-end;\n  font-size: 0.6em;\n  padding-right: 2em;\n  color: #888;\n  user-select: none;\n\n  &:hover {\n    color: #000;\n  }\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { useMemo } from 'react';
import styled from 'styled-components';

/*
 * Keep the position: relative combined with the
 * position: absolute in the caller and it will
 * end up at the right place.
 */
export var ContextMenuWrapper = styled.div(_templateObject);

export default (function (_ref) {
  var flags = _ref.outerDom.flags,
      contentPlugins = _ref.contentPlugins,
      editor = _ref.editor,
      ancestors = _ref.ancestors;

  // TODO: This avoids the validateDomNesting issue where a table's tr can
  // contain a div in addition to td's. However, it's not optimal because it
  // generally prevents table rows from having a context menu.
  if (flags.skipContextMenu) {
    return null;
  }

  var MenuComponents = useMemo(function () {
    return contentPlugins.map(function (plugin) {
      return plugin.ContextMenu;
    }).filter(Boolean);
  }, [contentPlugins]);
  return React.createElement(
    ContextMenuWrapper,
    {
      contentEditable: false,
      style: { position: 'absolute', right: '0' }
    },
    MenuComponents.map(function (Menu, i) {
      return React.createElement(Menu, { editor: editor, ancestors: ancestors, key: i });
    })
  );
});