var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import { getGigNodeByKey, getParentGigNodeByKey } from '../utils';
import parseChildSeq from './parseChildSeq';
import produce from 'immer';

import gigNodeFromDiffedKey, { gigNodesFromDiffedKeys } from './gigNodeFromDiffedKey';

import { ALT, INVERT, SWAP } from '@gigmade/msm-merge-util';
import { insertSiblingGigNode, insertChildGigNode } from '../utils';
import { getLeftMostKey, getRightMostKey, getKeysSortedBy } from './getOuterKeys';

var getToggleCount = function getToggleCount(_ref) {
  var toggleCache = _ref.toggleCache;
  return function () {
    return toggleCache == null ? 0 : toggleCache.length;
  };
};

function calculateCache(editor, parentGigNodeKey, childSeqKeyInfo) {
  function slateKeysFromDiffKeys(editor, diffedKeys) {
    var gigNodes = gigNodesFromDiffedKeys(editor, diffedKeys);
    return gigNodes.map(function (n) {
      return n.key;
    });
  }

  var parentGigNode = getGigNodeByKey(editor, parentGigNodeKey);

  var data = childSeqKeyInfo.data,
      accepted = childSeqKeyInfo.accepted;

  // Transform the nested raw info into a tabular format

  var unwrapped = parseChildSeq(data);
  // Add slate keys and accept states.
  var converted = unwrapped.map(function (_ref2) {
    var type = _ref2[0],
        firstKey = _ref2[1],
        params = _ref2.slice(2);

    var entry = {
      type: type,
      // The key that gets the toggle.
      labelKey: gigNodeFromDiffedKey(editor, firstKey, parentGigNode).key,
      accepted: accepted,
      // For now, don't provide parentGigNode as an ancestor to search in;
      // I'm not sure this is the correct one (springer!) always
      keys: params.map(function (keys) {
        return slateKeysFromDiffKeys(editor, keys);
      })
    };

    return entry;
  });

  var result = [];
  converted.forEach(function (_ref3) {
    var labelKey = _ref3.labelKey,
        partialCache = _objectWithoutProperties(_ref3, ['labelKey']);

    var found = result.find(function (item) {
      return item.key === labelKey;
    });
    if (!found) {
      found = { key: labelKey, cache: [] };
      result.push(found);
    }
    found.cache.push(partialCache);
  });

  return result;
}

function normalizeIndex(cache, index) {
  return cache.length > 1 && index < cache.length ? index : 0;
}

// Note: this is the actual index.
function switchToggle(cache, index) {
  var switchedToggles = cache.map(function (cacheItem, i) {
    if (i === index) {
      return _extends({}, cacheItem, {
        accepted: !cacheItem.accepted
      });
    }
    return cacheItem;
  });

  return switchedToggles;
}

function executeChange(editor, parentGigNode, _ref4) {
  var type = _ref4.type,
      accepted = _ref4.accepted,
      keys = _ref4.keys;

  function moveToSide(moveKeys, targetKeys) {
    var moveLeftOf = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    // src is right, target is left.
    if (moveLeftOf) {
      var leftMost = getLeftMostKey(parentGigNode, targetKeys);
      moveKeys.forEach(function (key) {
        insertSiblingGigNode(editor, leftMost, key, true);
      });
      return;
    }

    // opposite direction
    var rightMost = getRightMostKey(parentGigNode, targetKeys);
    var keysToMove = moveKeys.slice().reverse();

    keysToMove.forEach(function (key) {
      insertSiblingGigNode(editor, rightMost, key);
    });
    return;
  }

  // Apply the "opposite" operation of the current accept state.
  switch (type) {
    case INVERT:
      {
        var originalLeftNodes = keys[0];
        var originalRightNodes = keys[1];
        if (accepted) {
          moveToSide(originalLeftNodes, originalRightNodes, true);
          return;
        }

        moveToSide(originalLeftNodes, originalRightNodes, false);
        return;
      }
    case SWAP:
      {
        var _originalLeftNodes = keys[0];
        var originalMiddleNodes = keys[1];
        var _originalRightNodes = keys[2];

        if (accepted) {
          moveToSide(_originalLeftNodes, originalMiddleNodes, true);
          moveToSide(_originalRightNodes, originalMiddleNodes, false);
          return;
        }

        moveToSide(_originalLeftNodes, originalMiddleNodes, false);
        moveToSide(_originalRightNodes, originalMiddleNodes, true);
        return;
      }
    case ALT:
      {
        var extendedSeq = getKeysSortedBy(parentGigNode, accepted ? keys[0] : keys[1]);

        editor.withoutNormalizing(function () {
          extendedSeq.forEach(function (key, i) {
            insertChildGigNode(editor, parentGigNode.key, key, i);
          });
        });

        return;
      }
    default:
      {
        throw new Error('Unrecognized type');
      }
  }
}

var onToggle = function onToggle(editor, options) {
  return function (index) {
    var gigNodeKey = options.gigNodeKey,
        toggleCache = options.toggleCache,
        setToggleCache = options.setToggleCache;
    // Look for our current key..

    var actualIndex = normalizeIndex(toggleCache, index);

    var parentGigNode = getParentGigNodeByKey(editor, gigNodeKey);

    editor.withoutNormalizing(function () {
      executeChange(editor, parentGigNode, toggleCache[actualIndex]);
      setToggleCache(switchToggle(toggleCache, actualIndex));
    });
  };
};

var onFinalize = function onFinalize(editor, options) {
  return function (index) {
    return function (accepted) {
      editor.withoutNormalizing(function () {
        var setToggleCache = options.setToggleCache,
            toggleCache = options.toggleCache;


        var actualIndex = toggleCache.length > 1 && index < toggleCache.length ? index : 0;

        // TODO: full toggling with 'accepted !== isAccepted'
        if (accepted) {
          onToggle(editor, options)(actualIndex);
        }

        var newCache = [].concat(toggleCache.slice(0, actualIndex), toggleCache.slice(actualIndex + 1));

        // Only add the key back if we still have cache
        setToggleCache(newCache.length ? newCache : null);
      });
    };
  };
};

var getOperation = function getOperation(options) {
  return function (index) {
    var toggleCache = options.toggleCache;


    var actualIndex = normalizeIndex(toggleCache, index);

    var _toggleCache$actualIn = toggleCache[actualIndex],
        type = _toggleCache$actualIn.type,
        accepted = _toggleCache$actualIn.accepted,
        keys = _toggleCache$actualIn.keys;


    switch (type) {
      case INVERT:
      case ALT:
        {
          var fromIndex = accepted ? 1 : 0;
          return {
            from: keys[fromIndex],
            to: keys[1 - fromIndex],
            type: type
          };
        }
      case SWAP:
        {
          var _fromIndex = accepted ? 2 : 0;
          return {
            from: keys[_fromIndex],
            to: keys[2 - _fromIndex],
            type: type
          };
        }
      default:
        {
          throw new Error('Unknown child seq type.');
        }
    }
  };
};

// keys that may have been deleted by editing action.
var handleMissingKeys = function handleMissingKeys(options) {
  return function (i, missingKeys) {
    var toggleCache = options.toggleCache,
        setToggleCache = options.setToggleCache;
    // Our mechanism is robust against a few keys missing, but not all.

    // Ideas for more precise handling:
    // const keyLists = keyed[currentGigNode.key][i].keys
    // // keys are an array of an array.
    // const missingLists = keyLists.map(keys => {
    //   const missing = keys.filter(key => missingKeys.indexOf(key) !== -1)
    //   return missing
    // })
    // if (keyLists.some((keyList, i) => {
    //   const missingLists[i]
    // }))

    // We've lost all keys, need to remove this toggle
    // NOTE: No, this should be analyzed in more detail: remaining keys
    // could be springers that are not currently here.
    // So would need to operate with getOuterKeys.js:activeKeys as well here.
    // (And what if the status of these updates?)

    setToggleCache(produce(toggleCache, function (draft) {
      draft.splice(i, 1);
    }));
  };
};

// keys that may have been moved out of the relevant parent
var handleMovedOutKeys = function handleMovedOutKeys(options) {
  return function (i, movedKeys) {
    // for now, same as missing keys.
    return handleMissingKeys(options)(i, movedKeys);
  };
};

export default {
  calculateCache: calculateCache,
  isToggle: function isToggle(_ref5) {
    var toggleCache = _ref5.toggleCache;

    return getToggleCount({ toggleCache: toggleCache })() > 0;
  },
  getToggle: function getToggle(editor, options) {
    return {
      isAccepted: function isAccepted(index) {
        var toggleCache = options.toggleCache;
        // TODO: questionable normalize.

        var actualIndex = normalizeIndex(toggleCache, index);
        return toggleCache[actualIndex].accepted;
      },
      onToggle: onToggle(editor, options),
      getToggleCount: getToggleCount(options),
      onFinalize: onFinalize(editor, options),
      getOperation: getOperation(options),
      handleMissingKeys: handleMissingKeys(options),
      handleMovedOutKeys: handleMovedOutKeys(options)
    };
  }
};