var _templateObject = _taggedTemplateLiteralLoose(['\n  position: relative;\n'], ['\n  position: relative;\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { useRef, useState, useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { DATA_FLIP_ID, DATA_FLIP_CONTENT_ID, STATUS_ENABLED, STATUS_DISABLED } from './constants';
import FlatTree from './FlatTree';
import styled from 'styled-components';

var RelativeContainer = styled.div(_templateObject);

// hook for saving previous prop value
function usePrevious(value) {
  var ref = useRef();
  useEffect(function () {
    if (value) ref.current = value;
  }, [value]);
  return ref.current;
}

function createMapFromArray(arr) {
  return arr.reduce(function (map, val, i) {
    map.set(val, i);
    return map;
  }, new Map());
}

// return true if arrays have the same values but in different sequence
function isArrayMutated(arrFrom, arrTo) {
  if (!arrFrom || !arrTo) return false;
  if (arrTo.length !== arrFrom.length) return false;

  var map1 = createMapFromArray(arrFrom);
  var map2 = createMapFromArray(arrTo);

  var mapsHaveTheSameKeys = true;
  var differentValueForKeyExists = false;

  for (var _iterator = map1, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
    var _ref2;

    if (_isArray) {
      if (_i >= _iterator.length) break;
      _ref2 = _iterator[_i++];
    } else {
      _i = _iterator.next();
      if (_i.done) break;
      _ref2 = _i.value;
    }

    var _ref = _ref2;
    var key = _ref[0];
    var value = _ref[1];

    if (map2.get(key) === undefined) {
      mapsHaveTheSameKeys = false;
      break;
    }
    if (!differentValueForKeyExists && value !== map2.get(key)) {
      differentValueForKeyExists = true;
    }
  }
  return mapsHaveTheSameKeys && differentValueForKeyExists;
}

var numeric = function numeric(style, str) {
  return Number(style.getPropertyValue(str).replace('px', ''));
};

var getFlatTreeInfo = function getFlatTreeInfo(tree) {
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  if (!tree) return list;
  list.push({ key: tree.key, depth: depth });(tree.children || []).forEach(function (child) {
    getFlatTreeInfo(child, list, depth + 1);
  });

  return list;
};

/*
 * Note: treeInfo may be deep equal but still not same instance.
 */
export default (function (_ref3) {
  var tree = _ref3.tree,
      children = _ref3.children,
      active = _ref3.active,
      layout2d = _ref3.layout2d,
      fixedParams = _ref3.fixedParams,
      needCalculateInitial = _ref3.needCalculateInitial;

  var treeInfo = useMemo(function () {
    return getFlatTreeInfo(tree);
  }, [tree]);

  var _useState = useState(0),
      flat = _useState[0],
      setFlat = _useState[1];

  var ref = useRef(null);
  var relativeBounds = useRef(null);

  var _useState2 = useState({}),
      initialProps = _useState2[0],
      setInitialProps = _useState2[1];

  var _useState3 = useState({}),
      finalProps = _useState3[0],
      setFinalProps = _useState3[1];

  var _useState4 = useState({}),
      content = _useState4[0],
      setContent = _useState4[1];

  var initialParams = usePrevious(initialProps);

  var ids = useMemo(function () {
    return treeInfo.map(function (item) {
      return item.key;
    });
  }, [treeInfo]);

  var nodeDepthFor = useMemo(function () {
    return Object.assign.apply(Object, [{}].concat(treeInfo.map(function (item) {
      var _ref4;

      return _ref4 = {}, _ref4[item.key] = item.depth, _ref4;
    })));
  }, [treeInfo]);

  var prevIds = usePrevious(ids);

  var needAnimation = useMemo(function () {
    return isArrayMutated(prevIds, ids);
  }, [prevIds, ids]) && (active === STATUS_ENABLED || !active);

  var getProps = useCallback(function (id) {
    var nodeDepth = nodeDepthFor[id];
    var nodeWrapper = ref.current.querySelector('[' + DATA_FLIP_ID + '="' + id + '"]');
    var rect = nodeWrapper.getBoundingClientRect();
    var style = window.getComputedStyle(nodeWrapper);

    return {
      height: rect.height,
      background: style.getPropertyValue('background'),
      border: style.getPropertyValue('border'),
      paddingTop: numeric(style, 'padding-top'),
      paddingLeft: numeric(style, 'padding-left'),
      paddingBottom: numeric(style, 'padding-bottom'),
      paddingRight: numeric(style, 'padding-right'),
      top: layout2d ? rect.top : rect.top - relativeBounds.current.top + 1,
      left: layout2d ? rect.left : rect.left - relativeBounds.current.left,
      width: rect.width,
      zIndex: Number(nodeDepth)
    };
  }, [nodeDepthFor, layout2d]);
  var getContent = useCallback(function (id) {
    return ref.current.querySelector('[' + DATA_FLIP_CONTENT_ID + '="' + id + '"]').outerHTML;
  }, []);

  // here we define initial styles
  useEffect(function () {
    // if we need to animate then there is no need to update initial props
    if (!ref.current || needAnimation) {
      return;
    }
    relativeBounds.current = ref.current.parentNode.getBoundingClientRect();
    var content = {};
    var initialPropsTemp = {};

    for (var _iterator2 = ids, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
      var _ref5;

      if (_isArray2) {
        if (_i2 >= _iterator2.length) break;
        _ref5 = _iterator2[_i2++];
      } else {
        _i2 = _iterator2.next();
        if (_i2.done) break;
        _ref5 = _i2.value;
      }

      var id = _ref5;

      initialPropsTemp[id] = getProps(id);
      content[id] = getContent(id);
    }
    if (!layout2d || needCalculateInitial) {
      setInitialProps(initialPropsTemp);
      setContent(content);
    }
  }, [getProps, ids, getContent, needAnimation, layout2d, needCalculateInitial]);

  // here we define final styles and start animation
  // this code is executed if needAnimation is true
  useLayoutEffect(function () {
    if (!needAnimation || !ref.current || Object.keys(finalProps).length > 0) {
      return;
    }
    if (layout2d) {
      setFlat(true);
      return; // stop here because are not going to calculate final props
    }

    if (Object.keys(initialProps).length > 0) {
      var finalPropsTemp = {};
      for (var _iterator3 = ids, _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[Symbol.iterator]();;) {
        var _ref6;

        if (_isArray3) {
          if (_i3 >= _iterator3.length) break;
          _ref6 = _iterator3[_i3++];
        } else {
          _i3 = _iterator3.next();
          if (_i3.done) break;
          _ref6 = _i3.value;
        }

        var id = _ref6;

        finalPropsTemp[id] = getProps(id);
      }
      setFinalProps(finalPropsTemp);
      setFlat(true);
    }
  }, [finalProps, getProps, ids, initialProps, needAnimation, layout2d]);

  // reset state after animation completed
  var onAnimationCompleted = function onAnimationCompleted() {
    setFlat(false);
    setInitialProps(finalProps);
    setFinalProps({});
  };

  var resultFlat = flat;
  var resultContent = content;
  var resultInitialProps = initialProps;
  var resultFinalProps = finalProps;

  if (layout2d) {
    resultInitialProps = initialParams;
    resultFinalProps = fixedParams;

    if (resultContent === null || resultFinalProps === null || resultInitialProps === null) {
      resultFlat = false;
    }
  }

  // if we need animation render TreeFlat component
  var newChildren = resultFlat && Object.keys(resultInitialProps).length > 0 && Object.keys(resultFinalProps).length > 0 ? React.createElement(FlatTree, {
    tree: tree,
    onAnimationCompleted: onAnimationCompleted,
    startStyles: resultInitialProps,
    endStyles: resultFinalProps,
    content: resultContent,
    layout2d: layout2d
  }) : React.createElement(
    'div',
    { ref: ref },
    children
  );

  return React.createElement(
    RelativeContainer,
    null,
    newChildren
  );
});