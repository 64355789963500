var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import getOuterKeys, { getChildKeys, activeKeys } from './getOuterKeys';
import { ALT, INVERT, SWAP, INSERT, DELETE, SPRINGER } from '@gigmade/msm-merge-util';

// TODO: rename `positions` and `diffPositions` and/or make pos a sub-prop..
var filterPositions = function filterPositions(_ref) {
  var top = _ref.top,
      height = _ref.height,
      left = _ref.left,
      width = _ref.width;
  return {
    top: top,
    height: height,
    left: left,
    width: width
  };
};

var getMissingKeys = function getMissingKeys(positions, keys) {
  return keys.filter(function (key) {
    return !(key in positions);
  });
};

export default (function (_ref2) {
  var parent = _ref2.parent,
      positions = _ref2.positions,
      keys = _ref2.keys,
      type = _ref2.type;

  if (type === INSERT || type === DELETE) {
    return { dimensions: filterPositions(positions[keys[0]]) };
  }

  if (type === SPRINGER) {
    // This will also catch if keys.to is null which could happen in springers,
    // if both leftSibling and parent cannot be resolved against the current available blocks
    // (e.g. parent refers to the mergeRoot, but that is not part of the nodes.)
    var dragAlong = keys.dragAlong || [];

    var _notFoundKeys = getMissingKeys(positions, [keys.from, keys.to].concat(dragAlong));

    // keys are strings, so should always work
    if (_notFoundKeys.length) {
      return { notFoundKeys: _notFoundKeys };
    }

    var allFromKeys = [keys.from].concat(dragAlong);

    var _getOuterKeys = getOuterKeys(parent, allFromKeys),
        _firstFromKey = _getOuterKeys[0],
        _lastFromKey = _getOuterKeys[1];

    var _firstFromDim = filterPositions(positions[_firstFromKey]);
    var _lastFromDim = filterPositions(positions[_lastFromKey]);

    var _fromDimensions = _extends({}, _firstFromDim, {
      height: _lastFromDim.top - _firstFromDim.top + _lastFromDim.height

      // const fromDimensions = filterPositions(positions[keys.from])
    });var _toDimensions = filterPositions(positions[keys.to]);

    var _movesUp = _toDimensions.top < _fromDimensions.top;
    // If leftSiblingKey is defined, its bottom is our move target.
    // Otherwise, it's the parent's bottom.
    _toDimensions.top = _toDimensions.top + _toDimensions.height;
    _toDimensions.height = 0;

    return {
      fromDimensions: _fromDimensions,
      toDimensions: _toDimensions,
      movesUp: _movesUp
    };
  }

  // type is childrenSequence.

  var from = keys.from,
      to = keys.to;

  // This happens when sections are deleted.

  var notFoundKeys = getMissingKeys(positions, [].concat(from, to));

  // keys are strings, so should always work
  if (notFoundKeys.length) {
    return { notFoundKeys: notFoundKeys };
  }

  var _getOuterKeys2 = getOuterKeys(parent, from),
      firstFromKey = _getOuterKeys2[0],
      lastFromKey = _getOuterKeys2[1];
  // If we move a section away from a parent, that section doesn't count anymore
  // So if it's the only one remaining, we'll get undefined here.
  // (getOuterKeys searches within the parent node.)
  // Note: no firstFromKey implies no lastFromKey


  if (firstFromKey == null) {
    return { movedKeys: from };
  }

  var _getOuterKeys3 = getOuterKeys(parent, to),
      firstToKey = _getOuterKeys3[0],
      lastToKey = _getOuterKeys3[1];

  if (firstToKey == null) {
    return { movedKeys: to };
  }

  // If nothing missing, continue calculating.
  // Our diff positions use useMarkDiffPosition above and save content refs.
  // Note that this could mean that here we encounter an outer key
  // of a gigNode with no content.
  // Will have to manage this when we get to it.
  var firstFromDim = filterPositions(positions[firstFromKey]);
  var lastFromDim = filterPositions(positions[lastFromKey]);

  var firstToDim = filterPositions(positions[firstToKey]);
  var lastToDim = filterPositions(positions[lastToKey]);

  var movesUp = firstToDim.top < firstFromDim.top;

  var fromDimensions = _extends({}, firstFromDim, {
    height: lastFromDim.top - firstFromDim.top + lastFromDim.height
  });

  var toDimensions = void 0;
  switch (type) {
    case INVERT:
      {
        var toPos = movesUp ? firstToDim.top : lastToDim.top + lastToDim.height;
        toDimensions = _extends({}, lastToDim, {
          top: toPos,
          height: 0
        });
        break;
      }
    case SWAP:
      {
        toDimensions = _extends({}, firstToDim, {
          height: lastToDim.top - firstToDim.top + lastToDim.height
        });
        break;
      }
    case ALT:
      {
        var currentChildren = getChildKeys(parent);
        var activeAlt = activeKeys(currentChildren, to);

        var indices = currentChildren.map(function (elm) {
          return activeAlt.indexOf(elm);
        });
        // Human readable new index
        var numberDimensions = indices.map(function (index) {
          if (index === -1) {
            return null;
          }
          return { index: index + 1, dimensions: positions[activeAlt[index]] };
        });
        // NOTE: Return different signature!
        return { dimensions: fromDimensions, numberDimensions: numberDimensions };
      }
    default:
      {
        throw new Error('Unknown child sequence type.');
      }
  }

  return {
    twoSided: type === SWAP,
    fromDimensions: fromDimensions,
    toDimensions: toDimensions,
    movesUp: movesUp
  };
});