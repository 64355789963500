var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useContext, useMemo } from 'react';
import { isChildrenWrapper } from '../../utils/node';
import { GigNodeContext } from '../gigNodeContext';
import getChildrenWrapperOuterDoms from './getChildrenWrapperOuterDoms';

import { LayoutChildren } from '../layout';

var WrapIfExists = function WrapIfExists(_ref) {
  var Component = _ref.Component,
      children = _ref.children,
      props = _objectWithoutProperties(_ref, ['Component', 'children']);

  if (!Component) {
    return children;
  }

  return React.createElement(
    Component,
    props,
    children
  );
};

var ChildrenContainer = function ChildrenContainer(_ref2) {
  var children = _ref2.children,
      editor = _ref2.editor,
      node = _ref2.node,
      attributes = _ref2.attributes,
      contentPlugins = _ref2.contentPlugins;

  var _useContext = useContext(GigNodeContext),
      ChildrenContainer = _useContext.ChildrenContainer,
      ChildrenDomContainer = _useContext.childrenDom.Container,
      gigNodeProps = _objectWithoutProperties(_useContext, ['ChildrenContainer', 'childrenDom']);

  var currentGigNode = gigNodeProps.currentGigNode;

  // Some plugins can decide to wrap something around
  // the ChildrenWrapperNode ad-hoc at runtime.

  var OuterDoms = useMemo(function () {
    return getChildrenWrapperOuterDoms(contentPlugins);
  }, [contentPlugins]);

  return React.createElement(
    LayoutChildren,
    { node: currentGigNode, editor: editor },
    React.createElement(
      OuterDoms,
      gigNodeProps,
      React.createElement(
        WrapIfExists,
        _extends({ Component: ChildrenDomContainer }, gigNodeProps),
        React.createElement(
          WrapIfExists,
          _extends({ Component: ChildrenContainer }, gigNodeProps),
          children
        )
      )
    )
  );
};

export default (function (_ref3) {
  var contentPlugins = _ref3.contentPlugins;

  var renderNode = function renderNode(_ref4, editor, next) {
    var node = _ref4.node,
        attributes = _ref4.attributes,
        children = _ref4.children;

    if (!isChildrenWrapper(node)) {
      return next();
    }

    return React.createElement(
      ChildrenContainer,
      {
        node: node,
        attributes: attributes,
        editor: editor,
        contentPlugins: contentPlugins
      },
      children
    );
  };

  return {
    renderNode: renderNode
  };
});