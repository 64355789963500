var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/*
 * includeRoot == false has precedence over
 * includeSelf == true
 */
import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import isGigNode from './node/isGigNode';

var defaultOptions = {
  // Reverse direction: return ancestors upwards toward root
  bottomUp: true,
  // include the startingNode?
  includeSelf: true,
  // include the root gigNode?
  includeRoot: true,
  // for path normalization
  skipNonGigNodes: true
};

export default (function (editor, key) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  options = _extends({}, defaultOptions, options);

  var document = editor.value.document;

  var thisGigNode = getClosestGigNodeByKey(editor, key);

  var gigAncestors = document.getAncestors(thisGigNode.key)
  // Do not include document node.
  .slice(1);

  if (options.skipNonGigNodes) {
    // Avoid children wrappers
    gigAncestors = gigAncestors.filter(isGigNode);
  }

  // if (!options.includeSelf) {
  //   gigAncestors = gigAncestors.slice(0, -1)
  // }

  if (options.includeSelf) {
    gigAncestors = gigAncestors.push(thisGigNode);
  }

  if (!options.includeRoot) {
    gigAncestors = gigAncestors.slice(1);
  }

  if (options.bottomUp) {
    // Internal func is top down.
    gigAncestors = gigAncestors.reverse();
  }

  return gigAncestors;
});