import React, { Fragment } from 'react';

export default (function (_ref) {
  var contentPlugins = _ref.contentPlugins;

  // Return the plugin key so that if a user switches focus between two plugins that both have a custom bar,
  // the new plugin.TopBar is not rendered with the same index-based key
  var TopBars = contentPlugins.filter(function (plugin) {
    return plugin.TopBar;
  }).map(function (plugin) {
    return { Bar: plugin.TopBar, key: plugin.contentType };
  });

  var renderEditor = function renderEditor(props, editor, next) {
    return React.createElement(
      Fragment,
      null,
      TopBars.map(function (_ref2, i) {
        var Bar = _ref2.Bar,
            key = _ref2.key;
        return React.createElement(Bar, { key: key || i, editor: editor });
      }),
      next()
    );
  };

  return {
    renderEditor: renderEditor
  };
});