var getNodeKeys = function getNodeKeys(node, func) {
  var indt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

  var output = '';

  var space = '';
  for (var i = 0; i < indt; i++) {
    space += i % 2 ? ' ' : '|';
  }
  if (node.key) {
    // intFn: apply func on node, return '' if no success.
    var intFn = function intFn(func, node) {
      if (!func) {
        return '';
      }
      try {
        return func(node);
      } catch (err) {
        return '';
      }
    };

    //   output += `${space}${node.key} ${
    //     node.type ? node.type : '(' + node.object + ')'
    //   } ${node.object === 'text' ? '"' + node.text + '"' : '""'} ${intFn(
    //     func,
    //     node
    //   )}\n`

    output += node.object === 'text' ? ' ' + node.key + ':' + node.text : '\n' + space + ' ' + node.key + ' ' + (node.object === 'document' ? '(document)' : node.type) + ' "' + intFn(func, node) + '"';
  }

  var children = Array.isArray(node.nodes) ? node.nodes : node.nodes ? node.nodes.toArray() : [];
  if (children && children.length > 0) {
    for (var _i = 0; _i < children.length; _i++) {
      output += getNodeKeys(children[_i], func, indt + 2);
    }
  }

  if (indt === 0) {
    output += '\n';
  }

  return output;
};

export default getNodeKeys;