import tree from './tree';
import nodeByText from './nodeByText';

// window check: ssr (gatsby)
if (typeof window !== 'undefined') {
  window.etree = function (editor, fn) {
    return tree(editor.value.document, fn);
  };
}

export default (function (_ref) {
  var debug = _ref.debug,
      editorRef = _ref.editorRef;

  if (debug && typeof window !== 'undefined') {
    window.editor = function () {
      return editorRef.current;
    };
    window.tree = function (fn) {
      return tree(window.editor().value.document, fn);
    };
    window.nodeByText = function (text) {
      return nodeByText(window.editor(), text);
    };
  }
});