var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  border: none;\n  outline: none;\n  display: inline-block;\n  padding: 0;\n  margin-right: 0.3em;\n  background: none;\n  svg {\n    display: block;\n  }\n  color: ', ';\n  align-self: center;\n  font-size: 1.2em;\n'], ['\n  border: none;\n  outline: none;\n  display: inline-block;\n  padding: 0;\n  margin-right: 0.3em;\n  background: none;\n  svg {\n    display: block;\n  }\n  color: ', ';\n  align-self: center;\n  font-size: 1.2em;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  height: ', ';\n'], ['\n  height: ', ';\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { useRef, useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';
import styled from 'styled-components';
import colorScheme from '../colorScheme.json';

var defaultHeight = '0.8rem';

var Button = styled.button(_templateObject, colorScheme.compare.insert_mblue);

var Svg = styled.svg(_templateObject2, function (props) {
  return props.height ? props.height : defaultHeight;
});

var Mixed = function Mixed(_ref) {
  var _ref$height = _ref.height,
      height = _ref$height === undefined ? defaultHeight : _ref$height;
  return React.createElement(
    Svg,
    { height: height, viewBox: '0 0 51 52' },
    React.createElement(
      'g',
      { stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' },
      React.createElement(
        'g',
        {
          id: 'check-part',
          transform: 'translate(-0.398438, 0.500000)',
          fill: '#3C7FCC',
          fillRule: 'nonzero'
        },
        React.createElement('path', { d: 'M37.44,15.53 C37.4378545,16.1360975 37.2055117,16.7187418 36.79,17.16 L33.12,21.05 L27,27.58 L17,27.66 L15.22,25.9 C14.7723754,25.4554809 14.5204436,24.8508445 14.52,24.22 C14.5170735,23.5884849 14.7695262,22.9825983 15.22,22.54 C15.6617465,22.0910964 16.265195,21.8382946 16.895,21.8382946 C17.524805,21.8382946 18.1282535,22.0910964 18.57,22.54 L22,26 L26.62,21.1 L33.35,13.95 C34.0189749,13.2419229 35.0519106,13.0129876 35.9574136,13.3721043 C36.8629167,13.7312211 37.4581713,14.6058864 37.46,15.58 L37.44,15.53 Z' }),
        React.createElement('path', { d: 'M0.8,4.16 L0.8,47.16 C0.805512281,49.1940244 2.45596814,50.8400075 4.49,50.84 L47.49,50.84 C48.4659967,50.84 49.4020191,50.4522868 50.092153,49.762153 C50.7822868,49.0720191 51.17,48.1359967 51.17,47.16 L51.17,4.16 C51.1700075,2.12596814 49.5240244,0.475512281 47.49,0.47 L4.49,0.47 C3.51135115,0.47 2.57278521,0.858766741 1.88077598,1.55077598 C1.18876674,2.24278521 0.8,3.18135115 0.8,4.16 Z M45,27.44 L28,27.58 C27.3521373,27.5793117 26.7329829,27.8472499 26.29,28.32 L23.76,31 C23.3118838,31.4785119 22.6855775,31.750032 22.03,31.75 C21.3984849,31.7529265 20.7925983,31.5004738 20.35,31.05 L17.68,28.39 C17.2287095,27.9402699 16.6171168,27.6884376 15.98,27.69 L7.17,27.76 C6.53797881,27.7653337 5.93000926,27.5179946 5.48121301,27.072954 C5.03241677,26.6279133 4.77997749,26.0220437 4.78,25.39 L4.78,6.75 C4.78,5.49631362 5.79631362,4.48 7.05,4.48 L45,4.48 C46.3089149,4.48 47.37,5.54108514 47.37,6.85 L47.37,25.07 C47.37,26.3789149 46.3089149,27.44 45,27.44 Z' })
      )
    )
  );
};

var UncheckedIcon = function UncheckedIcon(_ref2) {
  var _ref2$height = _ref2.height,
      height = _ref2$height === undefined ? defaultHeight : _ref2$height;
  return React.createElement(
    Svg,
    { height: height, viewBox: '0 0 52 51' },
    React.createElement(
      'g',
      { stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' },
      React.createElement(
        'g',
        {
          transform: 'translate(0.174487, -0.248331)',
          fill: '#3C7FCC',
          fillRule: 'nonzero'
        },
        React.createElement('path', { d: 'M47.48,0.47 L4.48,0.47 C2.44596814,0.469992531 0.795512281,2.11597561 0.79,4.15 L0.79,47.15 C0.79,49.1879307 2.44206927,50.84 4.48,50.84 L47.48,50.84 C49.5140244,50.8344877 51.1600075,49.1840319 51.16,47.15 L51.16,4.15 C51.16,3.17400332 50.7722868,2.23798092 50.082153,1.54784705 C49.3920191,0.857713173 48.4559967,0.47 47.48,0.47 Z M47.23,45.33 C47.23,45.7251726 47.0730183,46.1041599 46.7935891,46.3835891 C46.5141599,46.6630183 46.1351726,46.82 45.74,46.82 L6.74,46.82 C5.92100365,46.8145033 5.25998155,46.1490148 5.26,45.33 L5.26,5.85 C5.26,5.03261857 5.92261857,4.37 6.74,4.37 L45.74,4.37 C46.5590148,4.36998155 47.2245033,5.03100365 47.23,5.85 L47.23,45.33 Z' })
      )
    )
  );
};

var CheckedIcon = function CheckedIcon(_ref3) {
  var _ref3$height = _ref3.height,
      height = _ref3$height === undefined ? defaultHeight : _ref3$height;
  return React.createElement(
    Svg,
    { height: height, viewBox: '0 0 51 51' },
    React.createElement(
      'g',
      { stroke: 'none', strokeWidth: '1', fill: 'none', fillRule: 'evenodd' },
      React.createElement(
        'g',
        {
          transform: 'translate(-0.244204, -0.425470)',
          fill: '#3C7FCC',
          fillRule: 'nonzero'
        },
        React.createElement('path', { d: 'M0.8,4.15 L0.8,47.15 C0.8,48.1286488 1.18876674,49.0672148 1.88077598,49.759224 C2.57278521,50.4512333 3.51135115,50.84 4.49,50.84 L47.49,50.84 C49.5240244,50.8344877 51.1700075,49.1840319 51.17,47.15 L51.17,4.15 C51.17,2.11759212 49.5224079,0.47 47.49,0.47 L4.49,0.47 C2.45596814,0.469992531 0.805512281,2.11597561 0.8,4.15 Z M36.79,18 L23.79,31.83 C23.3419919,32.2900047 22.727118,32.5494654 22.085,32.5494654 C21.442882,32.5494654 20.8280081,32.2900047 20.38,31.83 L15.25,26.7 C14.8029903,26.2552052 14.5516758,25.650603 14.5516758,25.02 C14.5516758,24.389397 14.8029903,23.7847948 15.25,23.34 C16.1776085,22.421055 17.6723915,22.421055 18.6,23.34 L22,26.79 L33.35,14.79 C34.2533862,13.8416074 35.7518108,13.7970114 36.71,14.69 C37.1705027,15.1213452 37.4399922,15.7185141 37.4587627,16.3492035 C37.4775332,16.9798929 37.2440351,17.5920317 36.81,18.05 L36.79,18 Z' })
      )
    )
  );
};

var WithToolTip = function WithToolTip(_ref4) {
  var helpText = _ref4.helpText,
      children = _ref4.children;

  var _useState = useState({}),
      hideProps = _useState[0],
      setHideProps = _useState[1];

  var timeout = useRef(null);

  useEffect(function () {
    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  var onVisibleChange = function onVisibleChange(isVisible) {
    clearTimeout(timeout.current);
    if (isVisible) {
      timeout.current = setTimeout(function () {
        setHideProps({ visible: false });
        timeout.current = setTimeout(function () {
          setHideProps({});
        }, 50);
      }, 4000);
    } else {
      setHideProps({});
    }
  };

  if (!helpText) {
    return children;
  }
  return React.createElement(
    Tooltip,
    _extends({
      placement: 'top',
      mouseEnterDelay: 1,
      overlay: helpText || ''
    }, hideProps, {
      onVisibleChange: onVisibleChange
    }),
    children
  );
};

export default (function (_ref5) {
  var _ref5$height = _ref5.height,
      height = _ref5$height === undefined ? defaultHeight : _ref5$height,
      onChange = _ref5.onChange,
      onClick = _ref5.onClick,
      onMouseDown = _ref5.onMouseDown,
      helpText = _ref5.helpText,
      checked = _ref5.checked,
      indeterminate = _ref5.indeterminate;

  var Icon = indeterminate ? Mixed : checked ? CheckedIcon : UncheckedIcon;

  // backward compatible:
  if (!onClick && onChange) {
    onClick = function onClick() {
      return onChange(!checked);
    };
  }

  return React.createElement(
    WithToolTip,
    { helpText: helpText },
    React.createElement(
      Button,
      { onMouseDown: onMouseDown, onClick: onClick },
      React.createElement(Icon, { height: height })
    )
  );
});