var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteralLoose(['\n  color: ', ';\n  font-size: 2rem;\n  ', '\n'], ['\n  color: ', ';\n  font-size: 2rem;\n  ', '\n']);

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { positionedCss } from './calcs';
import { textColor } from './config';
import Box from './Box';

var PositionedDiv = styled.div(_templateObject, textColor, positionedCss);

var Number = function Number(_ref) {
  var dimensions = _ref.dimensions,
      number = _ref.number;

  return React.createElement(
    PositionedDiv,
    dimensions,
    number,
    '.'
  );
};

// const numberDims = [{ top: 400, left: 200, width: 50, height: 40 }]

export default forwardRef(function (_ref2, ref) {
  var dimensions = _ref2.dimensions,
      numberDimensions = _ref2.numberDimensions,
      domProps = _objectWithoutProperties(_ref2, ['dimensions', 'numberDimensions']);

  return React.createElement(
    'div',
    _extends({ ref: ref }, domProps),
    React.createElement(Box, { dimensions: dimensions }),
    numberDimensions.map(function (_ref3, i) {
      var dimensions = _ref3.dimensions,
          index = _ref3.index;

      return React.createElement(Number, { key: i, number: index, dimensions: dimensions });
    })
  );
});