/*
 * Get all react components that wrap the gigNode defined by key.
 */
import getAncestorGigNodesByKey from './getAncestorGigNodesByKey';
import getGigNodeContentPluginByKey from './getGigNodeContentPluginByKey';

export default (function (editor, key) {
  var ancestors = getAncestorGigNodesByKey(editor, key, {
    skipNonGigNodes: true,
    bottomUp: true,
    includeRoot: true,
    includeSelf: false
  });

  var renderers = ancestors.map(function (ancestor, i) {
    var plugin = getGigNodeContentPluginByKey(editor, ancestor.key);
    return plugin.descendants ? plugin.descendants[i] : undefined;
  }).filter(Boolean).toArray();

  return renderers;
});