var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import isChildrenWrapper from '../node/isChildrenWrapper';
import { uuid, isUuid } from '../helpers';
import { getContentType as getDefaultContentType, getDefaultValue } from '../../coreTextPlugin';

/*
 * This is called by documentToSlate, as well as other functions to create
 * gigNodes.
 * Note that there is an assumption in here that a content
 * node is the first child of the gigNode.
 * We could avoid this by adding a `addContent` function to the contentPlugins,
 * but there appears to be no benefit for now.
 *
 * Alternatively, remove the addChildren/getChildren in contentPlugins and
 * just get a .slice(1) array if the content node is not isVoid.
 */
export var getFirstChildOffset = function getFirstChildOffset(contentPlugin) {
  var contentDef = contentPlugin.defineNode.content;

  return contentDef ? 1 : 0;
};

export var getChildren = function getChildren(node, contentPlugin) {
  if (!hasChildren(node)) {
    return [];
  }
  var childrenWrapper = getChildrenWrapper(node);
  return childrenWrapper.nodes;
};

export var contentNodeType = function contentNodeType(contentDef) {
  return (contentDef || {}).isVoid ? 'voidContent' : 'content';
};

export var createContentJson = function createContentJson(contentPlugin, value) {
  var defineNode = contentPlugin.defineNode;
  var contentDef = defineNode.content;
  // content can be 'void', 'editable' or false (no content).

  var _ref = contentDef || {},
      isVoid = _ref.isVoid,
      toSlate = _ref.toSlate;

  var inlineStructure = toSlate ? toSlate(value) : null;

  var nodes = inlineStructure ? inlineStructure : [{
    object: 'text',
    text: isVoid ? '' : value
  }];

  return {
    type: contentNodeType(contentDef),
    object: 'block',
    nodes: nodes
  };
};

export var createChildrenWrapperJson = function createChildrenWrapperJson() {
  return {
    type: 'children',
    object: 'block',
    nodes: []
  };
};

export var addContent = function addContent(node, contentNode, contentPlugin) {
  var contentDef = contentPlugin.defineNode.content;
  // contentDef can be null -> then it's a node without content.

  if (contentDef) {
    // if (!node.nodes.length || node.nodes[0].type === 'content')
    node.nodes.unshift(contentNode);
  }
};

export var addEmptyChildrenWrapper = function addEmptyChildrenWrapper(node) {
  var childrenWrapper = createChildrenWrapperJson();
  node.nodes.push(childrenWrapper);
  return childrenWrapper;
};

export var getChildrenWrapper = function getChildrenWrapper(parent) {
  return parent ? parent.nodes.find(isChildrenWrapper) : null;
};

var getOrCreateChildrenWrapper = function getOrCreateChildrenWrapper(parent) {
  var childrenWrapper = getChildrenWrapper(parent);
  if (!childrenWrapper) {
    childrenWrapper = addEmptyChildrenWrapper(parent);
  }
  return childrenWrapper;
};

/*
 * Note: We create the children wrapper "just in time" when we know we have
 * children, as opposed to when we create the parent gigNode.
 * The reason is that we use the default createGigNJson function in other
 * consumers, where we do not want to end up with an empty children wrapper.
 */
export var addChild = function addChild(parent, node, contentPlugins) {
  var contentType = parent.data.contentType;

  if (contentType) {
    var contentDef = contentPlugins[contentType].defineNode.content;

    if (contentDef && parent.nodes.length === 0) {
      throw new Error('Need to first add content, then add children');
    }
  }

  getOrCreateChildrenWrapper(parent).nodes.push(node);
};

export var hasChildren = function hasChildren(node) {
  var childrenWrapper = getChildrenWrapper(node);
  if (!childrenWrapper) {
    return false;
  }
  var nodes = childrenWrapper.nodes;

  return (nodes.length || nodes.size) > 0;
};

export var removeEmptyChildrenWrapper = function removeEmptyChildrenWrapper(node) {
  var childrenWrapper = getChildrenWrapper(node);
  node.nodes = node.nodes.filter(function (n) {
    return n !== childrenWrapper;
  });
};

/*
 * documentToSlate uses this function but always sends empty children,
 * then adds those later. Also, it doesn't add content.
 * skipContent: only used if wrapping a gigNode around existing contentNode.
 */
export default (function (_ref2, contentPlugins) {
  var contentType = _ref2.contentType,
      value = _ref2.value,
      _ref2$skipContent = _ref2.skipContent,
      skipContent = _ref2$skipContent === undefined ? false : _ref2$skipContent,
      _ref2$children = _ref2.children,
      children = _ref2$children === undefined ? [] : _ref2$children,
      data = _objectWithoutProperties(_ref2, ['contentType', 'value', 'skipContent', 'children']);

  if (typeof contentType === 'undefined' && typeof value === 'undefined') {
    // This may be a reasonable default, but there is currently 1 case
    // encountering this:
    // When, during the comparison, two trees do not match up at their root
    // node, in some cases the result is a dummy root node with the projects
    // as its children.
    // In this case, we need to convert that root node into a real node
    contentType = getDefaultContentType();
    value = getDefaultValue();
  }

  // We don't currently send a temp prop in data but just in case.

  var _temp = data.temp,
      diff = data.diff,
      restData = _objectWithoutProperties(data, ['temp', 'diff']);

  if (_temp != null) {
    throw new Error('The `temp` key is reserved.');
  }

  var temp = { gigNode: true

    // Note: on diffs, add the node's diff key to temp as we don't want to save it
  };if (diff) {
    temp.diff = diff;
  }

  // Note: In the diff case, restData may contain a diff specific `key`, not
  // to be confused with the slate key.
  var gigNode = {
    object: 'block',
    type: contentType,
    data: _extends({}, restData, { value: value, contentType: contentType, temp: temp }),
    nodes: []

    // Create uuid here if missing to avoid some normalization cycles.
  };var id = gigNode.data.id;


  if (!id || !isUuid(id)) {
    gigNode.data.id = uuid();
  }

  var contentPlugin = contentPlugins[contentType];

  if (!contentPlugin) {
    throw new Error('No plugin found for type \'' + contentType + '\'');
  }

  if (!skipContent) {
    addContent(gigNode, createContentJson(contentPlugin, value), contentPlugin);
  }
  children.forEach(function (child) {
    return addChild(gigNode, child, contentPlugins);
  });
  return gigNode;
});