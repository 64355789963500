var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/*
 * The very first content node in the slate system (first child of the title
 * gigNode) has the issue that on every edit, its `InlineRenderer` components
 * re-mount multiple times. This happens to no `InlineRenderer` components which
 * are in other contents, and it doesn't happen to the first content's
 * `Renderer` itself.
 *
 * Note: additionally, we have to be careful because when we update the dom
 * dimensions in a `useLayoutEffect` doesn't catch the case when a dom is
 * unaffected but just gets pushed down by sonething further up.
 *
 * Here our approach is: collect all refs that should get a diff indicator
 * highlight something using hooks, with meta info such as "skip" if a certain
 * node shouldn't be highlight.
 * We use `useLayoutEffect` to save all this information in a single ref and
 * from there, trigger a debounced setState to update the state feeding the UI.
 *
 * NOTE!!! diffPositions are saved with keys that may not reflect the slate key
 * of the ref. For example, we store content refs with gigNode keys.
 */

import React, { useRef, useCallback, useLayoutEffect, useContext, useState, useEffect, useMemo, createContext } from 'react';

import getDimensions from './getDimensions';
import useWindowResize from './useWindowResize';

import debounce from 'lodash/debounce';
import produce from 'immer';

var MARK_DELAY = 25;

// skip: it's not a insert or delete
export var useMarkDiffPosition = function useMarkDiffPosition(key, markRef, MarkComponent) {
  var skipDiffLine = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var _useContext = useContext(DiffIndicatorContext),
      markPosition = _useContext.markPosition,
      removePosition = _useContext.removePosition;

  useLayoutEffect(function () {
    markPosition(key, {
      ref: markRef.current,
      MarkComponent: MarkComponent,
      showDiffLine: !skipDiffLine
    });

    return function () {
      removePosition(key);
    };
  }, [MarkComponent, key, removePosition, markPosition, markRef, skipDiffLine]);
};

export var DiffIndicatorContext = createContext({});

/*
 * If newPositions is not provided, we re-calculate dimensions of all existing positions.
 */
var delayedTrigger = debounce(function (setter, newPositions, baseRef) {
  setter(function (diffPositions) {
    return produce(diffPositions, function (draft) {
      // Here, if newPositions is undefined this will just consist of the old positions.
      var allKeys = Object.keys(_extends({}, diffPositions, newPositions));

      allKeys.forEach(function (key) {
        var oldPos = diffPositions[key];
        // If newPositions is undefined (as opposed to empty!), we want to refresh positions.
        var newPos = newPositions ? newPositions[key] : oldPos;

        if (
        // key got deleted
        !newPos && key in diffPositions ||
        // ref is possibly unmounted
        newPos.ref && newPos.ref.offsetHeight === 0 //||
        // skip prop
        // newPos.showDiffLine === false
        ) {
            delete draft[key];
            return;
          }

        if (newPos.ref) {
          var ref = newPos.ref,
              MarkComponent = newPos.MarkComponent,
              showDiffLine = newPos.showDiffLine;


          var info = _extends({
            ref: ref
          }, getDimensions(ref, baseRef), {
            showDiffLine: showDiffLine,
            MarkComponent: MarkComponent

            // this pos isn't registered yet, or it has different dimensions.
          });if (!oldPos || Object.keys(info).some(function (k) {
            return info[k] !== oldPos[k];
          })) {
            draft[key] = info;
          }
        }
      });
    });
  });
}, MARK_DELAY);

export var DiffIndicatorProvider = function DiffIndicatorProvider(_ref) {
  var children = _ref.children,
      baseRef = _ref.baseRef;

  // This is to not accept any new delayed triggers once the component itself
  // wants to unmount.
  var isUnmounting = useRef(false);
  /*
   * The underlying issue for not directly updating positions is that the
   * root gigNode's *first* content's object == 'inline' nodes (and only those,
   * not those of other contents or the first content itself) is re-mounting 3-5
   * times before it settles. This happens everytime the validation is triggered
   * on that gigNode's content. It may or not be a slate issue.
   * The delayed and selective update here mitigates ths issue.
   */
  var lazyDiffPositions = useRef({});

  var _useState = useState({}),
      diffPositions = _useState[0],
      setDiffPositions = _useState[1];

  var markPosition = useCallback(function (key, props) {
    if (isUnmounting.current) {
      return;
    }

    if (!props) {
      delete lazyDiffPositions.current[key];
    } else {
      lazyDiffPositions.current[key] = props;
    }

    delayedTrigger(setDiffPositions, lazyDiffPositions.current, baseRef);
  }, [baseRef]);

  var resizeHandler = useCallback(function () {
    delayedTrigger(setDiffPositions, null, baseRef);
  }, [baseRef]);
  useWindowResize(resizeHandler);

  useEffect(function () {
    return function () {
      // This leads to ignoring new position requests
      isUnmounting.current = true;
      delayedTrigger.cancel();
    };
  }, []);

  var removePosition = useCallback(function (key) {
    markPosition(key, null);
  }, [markPosition]);

  var value = useMemo(function () {
    return { baseRef: baseRef, diffPositions: diffPositions, removePosition: removePosition, markPosition: markPosition };
  }, [baseRef, diffPositions, removePosition, markPosition]);

  return React.createElement(
    DiffIndicatorContext.Provider,
    { value: value },
    children
  );
};