import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import getGigNodeContentPluginByKey from './getGigNodeContentPluginByKey';
import gigDefHasContent from './helpers/gigDefHasContent';
import gigDefHasChildrenWrapper from './helpers/gigDefHasChildrenWrapper';

// TODO: optimize, move to ./node.
export default (function (editor, key) {
  var thisGigNode = getClosestGigNodeByKey(editor, key);

  var plugin = getGigNodeContentPluginByKey(editor, thisGigNode.key);
  var hasContent = gigDefHasContent(plugin);
  var hasChildrenWrapper = gigDefHasChildrenWrapper(plugin);

  var firstChildOffset = hasChildrenWrapper ? 0 : hasContent ? 1 : 0;

  return { hasContent: hasContent, hasChildrenWrapper: hasChildrenWrapper, firstChildOffset: firstChildOffset };
});