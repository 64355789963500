/*
 * This function blocks newnlines around text lines that are already empty.
 * Benefit: immediate reaction to user input.
 */
import { isEmptyTextGigNodeByKey, getGigNodesNearSelection } from '../utils';

import { isEnter } from './keyboard';

var existsAndIsEmptyText = function existsAndIsEmptyText(editor, gigNode) {
  return gigNode ? isEmptyTextGigNodeByKey(editor, gigNode.key) : false;
};

export default (function () {
  return {
    onKeyDown: function onKeyDown(event, editor, next) {
      if (isEnter(event)) {
        var selection = editor.value.selection;
        // Identify affected blocks.
        // We're here before a selection is collapsed on enter, so need to track
        // if multiple blocks in selection:

        var _getGigNodesNearSelec = getGigNodesNearSelection(editor),
            prevNode = _getGigNodesNearSelec.prevNode,
            startNode = _getGigNodesNearSelec.startNode,
            endNode = _getGigNodesNearSelec.endNode,
            nextNode = _getGigNodesNearSelec.nextNode;

        // Check for the conditions that would lead to blocking an additional newline.
        // We check selection endpoints, the blocks before the selection start,
        // and the block after selection end. We don't check in the middle of the
        // selection if it's spanning multiple blocks because that's not relevant
        // to allowing/blocking enter behaviour.


        if (
        // Checks if our selection endpoints touch an empty line.
        isEmptyTextGigNodeByKey(editor, startNode.key) || isEmptyTextGigNodeByKey(editor, endNode.key) ||
        // Checks if we are at start of line and previous line is empty
        selection.start.offset === 0 && existsAndIsEmptyText(editor, prevNode) ||
        // Checks if we are at end of line and next line is empty.
        // TODO: Check if "endContent.text.length" is relevant in case end block
        // is not text.
        selection.end.offset === endNode.text.length && existsAndIsEmptyText(editor, nextNode)) {
          // In this statement, we make an exception to a simple blocking of newline:
          // moving to the next line instead if it exists and is empty.
          if (nextNode && isEmptyTextGigNodeByKey(editor, nextNode.key) && selection.start.offset === endNode.text.length) {
            // We move to the next line.
            // The main problem this tries to solve is awkward UX when cursor is on the
            // 2nd-last line and last line is empty. If we just block enter, it may
            // look like it's not possible to continue writing.
            // An alternative solution might be to simply allow any returns and clean
            // them up in cleanEditorState.
            editor.moveToStartOfNextBlock();
          }
          event.preventDefault();
          return true;
        }
      }
      // Not enter key.
      return next();
    }
  };
});