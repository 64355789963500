import { Text } from '@gigmade/slate';
import replaceNode from './replaceNode';

export default (function (editor, key, accept) {
  if (typeof accept !== 'boolean') {
    throw new Error('Provide a boolean "accept" param');
  }

  var inline = editor.value.document.getNode(key);
  if (inline.type !== 'change') {
    throw new Error('Cannot only toggle inline nodes of type "change"');
  }

  var orphanType = inline.data.get('type');
  // on accept, keep inline; on ignore, keep delete.
  var keep = accept === (orphanType === 'insert');

  editor.withoutNormalizing(function () {
    editor.moveTo(key, 0);
    editor.moveToEndOfInline();
    editor.moveToStartOfNextText();

    if (!keep) {
      editor.removeNodeByKey(key);
    } else {
      // changeNode(editor, inline)
      replaceNode(editor, inline, Text.fromJSON({ text: inline.text }));
    }
    // Without this, undo is not working.
    editor.focus();
  });
});