var _templateObject = _taggedTemplateLiteralLoose(['\n  transform-origin: top left;\n  height: 2px;\n  background: #cccccc;\n  position: absolute;\n'], ['\n  transform-origin: top left;\n  height: 2px;\n  background: #cccccc;\n  position: absolute;\n']),
    _templateObject2 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  flex-direction: ', ';\n  align-items: center;\n  /* border: 0px solid #cecece; */\n  /* padding: add space between nodes in flexbox */\n  /* nope because this doesn\'t scale with nodes. */\n  padding: 0.6em;\n'], ['\n  display: flex;\n  flex-direction: ', ';\n  align-items: center;\n  /* border: 0px solid #cecece; */\n  /* padding: add space between nodes in flexbox */\n  /* nope because this doesn\'t scale with nodes. */\n  padding: 0.6em;\n']),
    _templateObject3 = _taggedTemplateLiteralLoose(['\n  display: flex;\n  /* border: 0px solid green; */\n  padding: 0;\n  flex-direction: ', ';\n'], ['\n  display: flex;\n  /* border: 0px solid green; */\n  padding: 0;\n  flex-direction: ', ';\n']);

function _taggedTemplateLiteralLoose(strings, raw) { strings.raw = raw; return strings; }

import styled from 'styled-components';

// Changed due to styled-components many classes generated warning.
export var Line = styled.div.attrs(function (props) {
  return {
    style: {
      transform: 'rotate(' + props.degree + 'deg)',
      width: props.lineLength + 'px',
      top: props.y1 + 'px',
      left: props.x1 + 'px'
    }
  };
})(_templateObject);

export var NodeGroup = styled.div(_templateObject2, function (props) {
  return props.type === 'down' ? 'column' : 'row';
});
export var ChildGroup = styled.div(_templateObject3, function (props) {
  return props.type === 'down' ? 'row' : 'column';
});