import { getEventRange, getEventTransfer } from '@gigmade/slate-react';
import makeAddMedia from './makeAddMedia';

var makeOnDrop = function makeOnDrop(_ref) {
  var fileLoader = _ref.fileLoader,
      pauseSave = _ref.pauseSave,
      uploadTypes = _ref.uploadTypes,
      addMessage = _ref.addMessage;

  var addMedia = makeAddMedia({
    fileLoader: fileLoader,
    pauseSave: pauseSave,
    uploadTypes: uploadTypes,
    addMessage: addMessage
  });

  var onDrop = function onDrop(event, editor, next) {
    var target = getEventRange(event, editor);
    if (!target && event.type === 'drop') return next();

    var transfer = getEventTransfer(event);
    var type = transfer.type,
        files = transfer.files;


    if (type === 'files') {
      addMedia(editor, files, target);
    }

    next();
  };

  return {
    commands: {
      // Namespacing outside facing editor commands for safety.
      // Alternatively, makePlugin(pauseSave) => returns { plugins, addMedia }.
      // Then editor can use plugins and expose addMedia.
      gigAddMedia: addMedia
    },
    onDrop: onDrop
  };
};

export default (function (_ref2) {
  var fileLoader = _ref2.fileLoader,
      pauseSave = _ref2.pauseSave,
      uploadTypes = _ref2.uploadTypes,
      addMessage = _ref2.addMessage;
  return makeOnDrop({ fileLoader: fileLoader, pauseSave: pauseSave, uploadTypes: uploadTypes, addMessage: addMessage });
});