import { PathUtils } from '@gigmade/slate';

var isBefore = PathUtils.isBefore;

/*
 * Accept a nodes list, return it sorted per doc occurrence.
 */

export default (function (editor, nodes) {
  var document = editor.value.document;

  var withPaths = nodes.map(function (node) {
    return {
      node: node,
      path: document.getPath(node.key)
    };
  });
  withPaths.sort(function (w1, w2) {
    return isBefore(w1.path, w2.path) ? -1 : 1;
  });
  return withPaths.map(function (w) {
    return w.node;
  });
});