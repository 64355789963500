var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useRef, useEffect, useState, useCallback, cloneElement, Fragment, Children } from 'react';
import { useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { Handler, Place, PlaceOriginal, DndNode, Line } from './styles';
import { useDnd } from './DndContext';
import { DATA_PLACE_ID, DATA_DRAG_ID } from './constants';

export default (function (_ref) {
  var _dataAttributes, _ref4, _ref5;

  var children = _ref.children,
      dragId = _ref.dragId,
      insertAsChild = _ref.insertAsChild,
      insertAfterSibling = _ref.insertAfterSibling,
      insertBeforeSibling = _ref.insertBeforeSibling,
      _ref$layout2d = _ref.layout2d,
      layout2d = _ref$layout2d === undefined ? false : _ref$layout2d,
      fixedParams = _ref.fixedParams,
      domProps = _objectWithoutProperties(_ref, ['children', 'dragId', 'insertAsChild', 'insertAfterSibling', 'insertBeforeSibling', 'layout2d', 'fixedParams']);

  var child = void 0;

  try {
    child = Children.only(children);
  } catch (e) {
    throw new Error('Each DragNode must wrap a single child');
  }

  var style = _extends({}, child.props.style, { boxSizing: 'border-box' });

  var _useDnd = useDnd(),
      state = _useDnd[0],
      dispatch = _useDnd[1];

  var _state$springIndexFor = state.springIndexForPlace,
      springIndexForPlace = _state$springIndexFor === undefined ? {} : _state$springIndexFor,
      _state$stylesPlace = state.stylesPlace,
      stylesPlace = _state$stylesPlace === undefined ? {} : _state$stylesPlace,
      setPlace = state.setPlace,
      inProgress = state.inProgress,
      _state$placeProps = state.placeProps,
      placeProps = _state$placeProps === undefined ? {} : _state$placeProps,
      callbacks = state.callbacks;

  var _ref2 = callbacks || {},
      onAfterCallback = _ref2.onAfterCallback,
      onBeforeCallback = _ref2.onBeforeCallback;

  var ref = useRef(null);
  var refPlace = useRef(null);
  var handlerTop = useRef(null);
  var handlerHeight = useRef(null);
  var handlerLeft = useRef(null);

  var _useState = useState(null),
      dragCurrent = _useState[0],
      setDragCurrent = _useState[1];

  var _useState2 = useState(false),
      focus = _useState2[0],
      setFocus = _useState2[1];

  var _useState3 = useState(null),
      nodeParams = _useState3[0],
      setNodeParams = _useState3[1];

  // This is for the dotted line to adjust to the top of the draggable.
  // This gives a small jump. Could probably be removed.


  if (dragCurrent) style = _extends({}, style, { marginTop: 0 });
  var dataAttributes = (_dataAttributes = {}, _dataAttributes[DATA_DRAG_ID] = dragId, _dataAttributes);

  // animated style in case of dragging this node

  var _useSpring = useSpring(function () {
    return {
      position: 'relative',
      zIndex: 2,
      border: 0,
      width: 'auto',
      left: 'auto',
      top: 'auto',
      opacity: 1
    };
  }),
      props = _useSpring[0],
      setStyle = _useSpring[1];

  var styleSelected = {
    position: props.position,
    zIndex: props.zIndex,
    width: props.width,
    left: props.left,
    top: props.top,
    userSelect: 'none',
    opacity: props.opacity

    //  animated style for original place in case of dragging
  };
  var _useSpring2 = useSpring(function () {
    return {
      height: 0,
      width: 0,
      opacity: 0,
      margin: 0,
      top: 0,
      left: 0
    };
  }),
      propsPlaceoriginal = _useSpring2[0],
      setStylePlaceInitial = _useSpring2[1];

  var stylePlaceInitial = {
    left: layout2d ? propsPlaceoriginal.left : null,
    top: layout2d ? propsPlaceoriginal.top : null,
    height: propsPlaceoriginal.height,
    width: propsPlaceoriginal.width,
    opacity: propsPlaceoriginal.opacity

    //  animated style for red line in case of dragging in layout2d
  };
  var _useSpring3 = useSpring(function () {
    return {
      width: 0,
      degree: 0,
      lineLength: 0,
      x1: '',
      y1: '',
      opacity: 0
    };
  }),
      propsLine = _useSpring3[0],
      setStyleLine = _useSpring3[1];

  var styleLine = {
    opacity: propsLine.opacity,
    transform: propsLine.degree.to(function (degree) {
      return 'rotate(' + degree + 'deg)';
    }),
    width: propsLine.lineLength,
    top: propsLine.y1,
    left: propsLine.x1,
    immediate: true
  };

  var mouseOverHandler = useCallback(function (e) {
    e.stopPropagation();
    if (layout2d) {
      var el = document.querySelector('div[' + DATA_DRAG_ID + '="' + dragId + '"]');
      var rectProps = el.getBoundingClientRect();
      handlerTop.current = rectProps.top + 5;
      handlerLeft.current = rectProps.left;
      handlerHeight.current = rectProps.height - 7;
    }
    setFocus(true);
  }, [dragId, layout2d]);

  var mouseOutHandler = useCallback(function (e) {
    e.stopPropagation();
    setFocus(false);
  }, []);

  var _useState4 = useState({}),
      placeParams = _useState4[0],
      setPlaceParams = _useState4[1];

  useEffect(function () {
    setNodeParams(fixedParams);
  }, [fixedParams]);

  useEffect(function () {
    if (!layout2d) return;
    var onResize = function onResize() {
      handlerTop.current = null;
    };
    handlerTop.current = null;
    window.addEventListener('resize', onResize);
    return function () {
      window.removeEventListener('resize', onResize);
    };
  }, [layout2d]);

  useEffect(function () {
    if (layout2d) {
      handlerTop.current = null;
      //ref.current = null
    }
  }, [children, layout2d]);

  useEffect(function () {
    if (dragCurrent) {
      var placeParamsTemp = {};
      // calculate all props for available places (lines)
      var els = document.querySelectorAll('[' + DATA_PLACE_ID + ']');
      for (var i = 0; i < els.length; i++) {
        var p = els[i].getBoundingClientRect();
        var left = p.left;
        var top = p.top;
        var id = Number(els[i].getAttribute(DATA_PLACE_ID));
        var from = null;
        var to = null;

        if (layout2d) {
          var nodeMapParams = nodeParams;
          if (!nodeMapParams || !placeProps[id]) return;

          var params = nodeMapParams[placeProps[id]['key']];

          if (placeProps[id]['type'] === 'inside') {
            if (layout2d === 'down') {
              left = params.left + params.width / 2;
              top = params.bottom;
              from = [left, top];
              to = [left, top + 20];
            } else {
              left = params.right;
              top = params.top + params.height / 2;
              from = [left, top];
              to = [left + 20, top];
            }
          } else {
            left = params.left;
            if (placeProps[id]['type'] === 'before') {
              top = params.top;
              if (placeProps[id]['key'] !== dragId && layout2d !== 'down') {
                top -= 10;
              }

              if (placeProps[id]['key'] === dragId) {
                if (layout2d === 'down') {
                  left = params.left + params.width / 2;
                } else {
                  top = params.top + params.height / 2;
                }
              }

              if (layout2d === 'down') {
                if (placeProps[id]['key'] !== dragId) {
                  left -= 10;
                }
              }
            } else {
              if (layout2d === 'down') {
                top = params.top;
                left = params.right + 15;
              } else {
                top = params.bottom + 15;
              }
            }

            var parentParams = nodeMapParams[placeProps[id]['parentKey']];
            to = [left, top];

            if (layout2d === 'down') {
              from = [parentParams.left + parentParams.width / 2, parentParams.bottom];
            } else {
              var firstOptionTop = parentParams.top;
              var secondOptionTop = parentParams.top + parentParams.height;
              var thirdOptionTop = parentParams.top + parentParams.height / 2;
              var winnerOptionTop = firstOptionTop;
              var winnerOptionLeft = parentParams.left + parentParams.width / 2;

              if (Math.abs(top - firstOptionTop) >= Math.abs(top - secondOptionTop)) {
                winnerOptionTop = secondOptionTop;
              }
              if (Math.abs(top - thirdOptionTop) < Math.abs(top - winnerOptionTop)) {
                winnerOptionTop = thirdOptionTop;
                winnerOptionLeft = parentParams.right;
              }

              from = [winnerOptionLeft, winnerOptionTop];
            }
          }
        }
        placeParamsTemp[id] = {
          top: top,
          left: left,
          from: from,
          to: to,
          width: p.width
        };
      }

      // remove all "lines" inside node and it's children
      //if (ref.current) {
      var innerPlaces = ref.current.querySelectorAll('[' + DATA_PLACE_ID + ']');
      for (var _i = 0; _i < innerPlaces.length; _i++) {
        delete placeParamsTemp[Number(innerPlaces[_i].getAttribute(DATA_PLACE_ID))];
      }
      //}
      // delete "before line" before next node
      if (refPlace.current) {
        var nextSibling = refPlace.current.nextSibling;
        if (nextSibling && nextSibling.getAttribute(DATA_PLACE_ID) !== '') {
          delete placeParamsTemp[Number(nextSibling.getAttribute(DATA_PLACE_ID))];
        }
      }
      setPlaceParams(placeParamsTemp);
    }
  }, [dragCurrent, layout2d, placeProps, dragId, nodeParams]);

  var recalculateFixedParams = useCallback(function () {
    setStyleLine({ opacity: 0, onRest: null, immediate: true });

    var newFixedParams = {};
    var els = document.querySelectorAll('[' + DATA_DRAG_ID + ']');
    for (var i = 0; i < els.length; i++) {
      var id = Number(els[i].getAttribute(DATA_DRAG_ID));
      newFixedParams[id] = els[i].getBoundingClientRect();
    }
    setNodeParams(newFixedParams);
  }, [setStyleLine]);

  var scrollTimer = useRef(-1);
  var onScroll = useCallback(function () {
    if (scrollTimer.current !== -1) {
      setStyleLine({ opacity: 0, onRest: null, immediate: true });
      clearTimeout(scrollTimer);
    }
    scrollTimer.current = window.setTimeout(recalculateFixedParams, 150);
  }, [recalculateFixedParams, setStyleLine]);

  var bind = useDrag(function (_ref3) {
    var _ref3$args = _ref3.args,
        index = _ref3$args[0],
        down = _ref3.down,
        xy = _ref3.xy,
        memo = _ref3.memo,
        movement = _ref3.movement,
        initial = _ref3.initial;

    if (!dragCurrent) {
      recalculateFixedParams();
      setDragCurrent(index);
      dispatch({ type: 'setInProgress', value: true });
      if (layout2d) {
        window.addEventListener('scroll', onScroll);
      }
      onBeforeCallback && onBeforeCallback();
      return;
    }

    var originalHeight = memo ? memo.height : 0;
    var originalWidth = memo ? memo.width : 0;
    var originalLeft = memo ? memo.left : 0;
    var originalTop = memo ? memo.top : 0;
    var originalOffset = memo ? memo.offset : 0;

    if (!originalHeight) {
      var params = void 0;

      params = ref.current.getBoundingClientRect();

      if (layout2d) {
        params = ref.current.querySelector('div:first-child').getBoundingClientRect();
      }

      originalHeight = params.height;
      originalWidth = params.width;
      originalLeft = params.left;
      originalTop = params.top;
      originalOffset = [ref.current.querySelector('div:first-child').offsetLeft, ref.current.querySelector('div:first-child').offsetTop];
    }

    var newPlaceIndex = 0;
    var reset = false;

    if (down) {
      //if (!layout2d) {
      var distanceToBottom = window.innerHeight - xy[1];
      if (distanceToBottom <= originalHeight) {
        window.scrollBy(0, 10);
      } else {
        var distanceToTop = xy[1];
        if (distanceToTop <= originalHeight) {
          window.scrollBy(0, -10);
        }
      }
      //}
      // find nearest line close to this y coord
      var currentY = void 0;
      if (movement[1] < originalHeight / 2) {
        currentY = originalTop + movement[1];
      } else {
        currentY = xy[1];
      }

      var minDistance = null;
      for (var id in placeParams) {
        var distance = void 0;
        if (layout2d) {
          var a = xy[0] - placeParams[id].left;
          var b = xy[1] - placeParams[id].top;
          distance = Math.sqrt(a * a + b * b); // distance between two points
        } else {
          distance = Math.abs(Number(placeParams[id].top) - currentY);
        }
        if (minDistance === null || minDistance > distance) {
          minDistance = distance;
          newPlaceIndex = Number(id);
        }
      }

      if (newPlaceIndex === springIndexForPlace[dragId]['before']) reset = true;

      var nextMemo = {
        height: originalHeight,
        width: originalWidth,
        left: originalLeft,
        top: originalTop,
        offset: originalOffset,
        lastPlaceParams: placeParams[newPlaceIndex],
        lastPlaceIndex: newPlaceIndex,
        reset: reset
      };

      if (!placeParams[newPlaceIndex]) {
        var resetPlaceIndex = springIndexForPlace[dragId]['before'];
        return _extends({}, nextMemo, {
          lastPlaceParams: placeParams[resetPlaceIndex],
          lastPlaceIndex: resetPlaceIndex,
          reset: true
        });
      }

      setStylePlaceInitial({
        height: originalHeight,
        width: originalWidth,
        opacity: layout2d ? 0 : 1,
        immediate: true,
        onRest: null,
        left: originalOffset[0],
        top: originalOffset[1]
      });

      if (layout2d) {
        var _placeParams$newPlace = placeParams[newPlaceIndex].from,
            x1 = _placeParams$newPlace[0],
            y1 = _placeParams$newPlace[1];
        var _placeParams$newPlace2 = placeParams[newPlaceIndex].to,
            x2 = _placeParams$newPlace2[0],
            y2 = _placeParams$newPlace2[1];


        if (x2 < x1) {
          var tmp = x2;
          x2 = x1;
          x1 = tmp;
          var tmp2 = y2;
          y2 = y1;
          y1 = tmp2;
        }
        var lineLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        var m = (y2 - y1) / (x2 - x1);
        var degree = Math.atan(m) * 180 / Math.PI;

        if (degree < 1 && layout2d === 'down') {
          degree = 180 + degree;
          var _tmp = x2;
          x2 = x1;
          x1 = _tmp;
          var _tmp2 = y2;
          y2 = y1;
          y1 = _tmp2;
        }

        setStyleLine({
          opacity: 1,
          degree: degree,
          lineLength: lineLength,
          x1: x1,
          y1: y1,
          immediate: true,
          onRest: null
        });
      } else {
        setPlace(function (i) {
          if (i === newPlaceIndex) {
            var left = -5 * (isNaN(children.props.depth) ? 1 : children.props.depth);
            var top = 0;
            return {
              height: 2,
              opacity: 1,
              left: left,
              top: top,
              onRest: null,
              immediate: true
            };
          } else {
            return {
              height: 0,
              opacity: 0,
              onRest: null,
              immediate: true
            };
          }
        });
      }
      var newStyleDragged = {
        zIndex: 9999,
        position: 'fixed',
        immediate: true,
        border: 1,
        width: layout2d ? originalWidth : placeParams[newPlaceIndex].width,
        height: layout2d ? originalHeight : null,
        left: xy[0],
        top: layout2d ? xy[1] : xy[1] - (initial[1] - originalTop),
        onRest: null
      };

      if (layout2d) {
        setStyle({
          opacity: 0.2,
          position: 'static',
          immediate: true,
          onRest: null
        });
      } else {
        setStyle(newStyleDragged);
      }
      return nextMemo;
    } else {
      if (layout2d) {
        window.removeEventListener('scroll', onScroll);
      }
      dispatch({ type: 'setInProgress', value: false });
      setDragCurrent(null);
      setFocus(false);

      var _nextMemo = {
        left: 0,
        top: 0,
        height: 0,
        width: 0,
        lastPlaceParams: null,
        lastPlaceIndex: null,
        reset: false
      };

      if (!memo) {
        onAfterCallback && onAfterCallback();
        return _nextMemo;
      }

      var top = memo.top;

      var _onRest = function _onRest() {
        var newStyleDragged = {
          border: 0,
          position: memo.reset ? 'static' : 'fixed',
          width: memo.lastPlaceParams.width,
          left: memo.lastPlaceParams.left,
          top: top,
          immediate: true,
          onRest: function onRest() {
            if (!layout2d) {
              setPlace(function (i) {
                return {
                  height: 0
                };
              });
            }
            if (memo.reset) {
              onAfterCallback && onAfterCallback();
              return;
            } else {
              var _placeProps$memo$last = placeProps[memo.lastPlaceIndex],
                  key = _placeProps$memo$last.key,
                  type = _placeProps$memo$last.type;

              switch (type) {
                case 'inside':
                  insertAsChild(dragId, key);
                  break;
                case 'before':
                  insertBeforeSibling(dragId, key);
                  break;
                case 'after':
                  insertAfterSibling(dragId, key);
                  break;
                default:
                  break;
              }
              onAfterCallback && onAfterCallback();
            }
          }
        };

        if (layout2d) {
          setStyle({
            opacity: 1,
            position: 'static',
            immediate: true,
            onRest: newStyleDragged.onRest
          });
        } else {
          setStyle(newStyleDragged);
        }
      };

      if (layout2d) {
        setStylePlaceInitial({
          height: 0,
          width: 0,
          opacity: 0,
          margin: 0,
          immediate: true,
          onRest: function onRest() {
            _onRest();
            setStyleLine({
              opacity: 0,
              immediate: true,
              onRest: null
            });
          }
        });
      } else {
        setStylePlaceInitial({
          height: 0,
          opacity: 0,
          immediate: true,
          onRest: function onRest() {
            setPlace(function (i) {
              return {
                height: i === memo.lastPlaceIndex && !memo.reset ? originalHeight : 0,
                opacity: 0,
                left: 0,
                immediate: true,
                onRest: function onRest() {
                  return i === memo.lastPlaceIndex ? _onRest() : null;
                }
              };
            });
          }
        });
      }
      return _nextMemo;
    }
  });

  var beforePlace = springIndexForPlace[dragId] && stylesPlace[dragId] && React.createElement(Place, _extends({}, (_ref4 = {}, _ref4[DATA_PLACE_ID] = springIndexForPlace[dragId]['before'], _ref4), {
    style: stylesPlace[dragId]['before'],
    contentEditable: false
  }));

  var afterPlace = springIndexForPlace[dragId] && stylesPlace[dragId] && React.createElement(Place, _extends({}, (_ref5 = {}, _ref5[DATA_PLACE_ID] = springIndexForPlace[dragId]['after'], _ref5), {
    style: _extends({}, stylesPlace[dragId]['after']),
    contentEditable: false
  }));

  var passedStyle = domProps.style,
      rest = _objectWithoutProperties(domProps, ['style']);

  var newChild = cloneElement(child, _extends({}, dataAttributes, rest, {
    style: _extends({}, passedStyle, style)
  }));

  var finalNodeContent = React.createElement(
    Fragment,
    null,
    newChild,
    springIndexForPlace[dragId] && !inProgress && React.createElement(Handler, _extends({
      layout2d: layout2d ? 'true' : ''
    }, bind({ dragId: dragId }), {
      focus: focus,
      selected: !!dragCurrent,
      contentEditable: false,
      top: handlerTop.current,
      left: handlerLeft.current,
      height: handlerHeight.current
    }))
  );

  return React.createElement(
    Fragment,
    null,
    beforePlace,
    React.createElement(
      DndNode,
      {
        selected: !!dragCurrent && !layout2d,
        style: dragCurrent ? styleSelected : {
          position: layout2d ? 'static' : 'relative',
          userSelect: inProgress ? 'none' : 'auto'
        },
        ref: ref,
        onMouseOver: mouseOverHandler,
        onMouseOut: mouseOutHandler
      },
      finalNodeContent
    ),
    layout2d && dragCurrent && React.createElement(Line, { style: styleLine, type: layout2d }),
    React.createElement(PlaceOriginal, {
      layout2d: layout2d ? 'true' : '',
      ref: refPlace,
      style: stylePlaceInitial,
      contentEditable: false
    }),
    afterPlace
  );
});