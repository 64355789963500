import getClosestGigNodeByKey from './getClosestGigNodeByKey';

export default (function (editor) {
  var atAnchor = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var selection = editor.value.selection;


  var side = atAnchor ? 'anchor' : 'focus';

  var selectedPath = selection[side].path;

  if (!selectedPath) {
    return null;
  }

  var thisGigNode = getClosestGigNodeByKey(editor, selectedPath);
  return thisGigNode;
});