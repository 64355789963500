import { useState, useEffect, useMemo, useCallback } from 'react';
import { Set } from 'immutable';

export default (function () {
  var sharedIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  var _useState = useState(null),
      disagreementIds = _useState[0],
      setLockedIds = _useState[1];

  // `sharedIds` is a static parameter and shouldn't change during consensus
  // negotiation. If it does, we reset the consensus first.


  useEffect(function () {
    setLockedIds(new Set());
  }, [sharedIds]);

  var disagreement = useMemo(function () {
    return disagreementIds && disagreementIds.size > 0;
  }, [disagreementIds]);

  var disagree = useCallback(function (id) {
    // Just make all clientIds unique unless sharedIds = true
    // We still add an optional 'reason' for debugging.
    var uniqueId = sharedIds ? id : { reason: id };

    setLockedIds(function (set) {
      return set.add(uniqueId);
    });

    return function () {
      return setLockedIds(function (set) {
        return set.delete(uniqueId);
      });
    };
  }, [sharedIds]);

  // Explanation:
  // - disagree: break consensus - returns function for ending break.
  // - disagreement: true if no consensus
  return [disagree, disagreement];
});