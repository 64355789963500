import React from 'react';

export default (function (_ref) {
  var color = _ref.color;

  return React.createElement(
    "svg",
    {
      x: "0px",
      y: "0px",
      viewBox: "0 0 32 32",
      style: { enableBackground: 'new 0 0 32 32' }
    },
    React.createElement(
      "g",
      null,
      React.createElement(
        "g",
        null,
        React.createElement("polygon", {
          fill: color,
          points: "18,13.5 14,13.5 14,21.5 11,21.5 16,27.5 21,21.5 18,21.5     "
        }),
        React.createElement("path", {
          //style={{ fill: '#c3c1c4' }}
          fill: color,
          d: "M27.586,12.712C26.66,10.251,24.284,8.5,21.5,8.5c-0.641,0-1.26,0.093-1.846,0.266\r C18.068,6.205,15.233,4.5,12,4.5c-4.904,0-8.894,3.924-8.998,8.803C1.207,14.342,0,16.283,0,18.5c0,3.312,2.688,6,6,6h4.5\r l-1.667-2H5.997C3.794,22.5,2,20.709,2,18.5c0-1.893,1.317-3.482,3.087-3.896C5.029,14.245,5,13.876,5,13.5c0-3.866,3.134-7,7-7\r c3.162,0,5.834,2.097,6.702,4.975c0.769-0.611,1.739-0.975,2.798-0.975c2.316,0,4.225,1.75,4.473,4h0.03\r c2.203,0,3.997,1.791,3.997,4c0,2.205-1.789,4-3.997,4h-2.836l-1.667,2H26c3.312,0,6-2.693,6-6\r C32,15.735,30.13,13.407,27.586,12.712z"
        })
      )
    )
  );
});