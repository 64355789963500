import wrapTextContentInGigNodeByKey from './wrapTextContentInGigNodeByKey';
import dedentByKey from './dedentByKey';

/*
 * Wrap a content (or voidContent) node in a gigNode.
 * Then, move the resulting node out of its parent and make it a sibling of its
 *  previous parent instead.
 * Optionally, provide an `addIndex` number to dedent move the new sibling to
 * a non-0 index, with 0 meaning directly after the previous parent.
 *
 */
export default (function (editor, unwrapKey, options) {
  editor.withoutNormalizing(function () {
    var gigNodeWrapper = wrapTextContentInGigNodeByKey(editor, unwrapKey);
    dedentByKey(editor, gigNodeWrapper.key, options);
  });
});