var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import { getChildren } from '../utils/helpers/createGigNodeJson';

var serializeGigNode = function serializeGigNode(gigNode, contentPlugins) {
  var depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var gigNodeData = gigNode.data;
  var contentType = gigNodeData.contentType;
  // if (contentPlugins[contentType].defineNode) {

  var plugin = contentPlugins[contentType];
  var fromSlate = plugin.defineNode.fromSlate;

  // If no getTextNode, value is in gigNode and thus already in gigNodeData.

  var value = fromSlate ? fromSlate(gigNode) : gigNodeData.value;

  delete gigNodeData.temp;

  var items = [_extends({}, gigNodeData, {
    depth: depth,
    // Note that this overwrites gigNodeData so careful that we add it
    // manually.
    value: value
  })];

  var children = getChildren(gigNode, plugin);
  children.forEach(function (gN) {
    items.push.apply(items, serializeGigNode(gN, contentPlugins, depth + 1));
  });

  return items;
};

export default (function (slateValue, contentPlugins) {
  var rootNodes = [];

  try {
    rootNodes = slateValue.toJSON().document.nodes;
  } catch (error) {
    throw new Error('Could not parse raw value:', error);
  }
  if (rootNodes.length > 1) {
    throw new Error('Invalid raw value: >1 root nodes.');
  }
  var result = rootNodes.length ? serializeGigNode(rootNodes[0], contentPlugins) : [];
  return result;
});