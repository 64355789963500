var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/*
 * Prepare context once so we can re-use in multiple other plugins and
 * save performance on path look-up.
 * Note the throttled value update for this context in its react hook.
 *
 * Note: This function does not need access to contentPlugins because of the
 * `injectContentPlugins` slate plugin.
 */
import React, { createContext, useContext } from 'react';
import { SlateContext } from '../../SlateEditor';
import { getCurrentGigNodeInfo, getClosestGigNodeByKey } from '../../utils';
import { isGigNode, isChildrenWrapper, isContentNode } from '../../utils/node';
import { isDiffing as isDiffingFn, diffToggleProps } from '../../diff';
// import useCache from '../../useCache'
import { useCache, CacheProvider } from '../../CacheContext';

export var GigNodeContext = createContext({});

var AddContext = function AddContext(_ref) {
  var editor = _ref.editor,
      node = _ref.node,
      children = _ref.children,
      options = _ref.options;

  // TODO: explore mechanism for more performant path lookups
  // getPath is slow for large trees. For now. throttling.
  var cache = useCache();

  // We find that before gigNodeInfo gives it because:
  // Since gigNodeInfo is the same for every node that is under the same gigNode,
  // we key our "editor-global" cache with the gigNodeKey and store the info there first time.
  // Then we can look it up later.
  var currentGigNode = getClosestGigNodeByKey(editor, node.key);

  // TODO: When removing nodes in map mode (layout.js), it's possible that the
  // editor tries to re-render an inline node that has already been removed
  // and will then not have any currentGigNode. This needs investigation.
  if (!currentGigNode) {
    return null;
  }

  var gigNodeInfo = cache.get(currentGigNode.key, function () {
    return getCurrentGigNodeInfo(editor, node.key);
  });

  // const { currentGigNode } = gigNodeInfo

  // The idea here is to run diffData on every tick, because only then
  // the tree is re-rendered.
  var diffData = {
    isDiffing: isDiffingFn(editor),
    diff: diffToggleProps(editor, currentGigNode.key)
  };

  var gigNodeProps = _extends({
    node: node,
    editor: editor
  }, gigNodeInfo, diffData);

  return React.createElement(
    GigNodeContext.Provider,
    { value: gigNodeProps },
    children
  );
};

var RenderEditor = function RenderEditor(_ref2) {
  var children = _ref2.children;

  // Note: The `useContext(SlateContext)` is enough to re-render everytime
  // the tick updates.
  var _useContext = useContext(SlateContext),
      tick = _useContext.tick;

  return React.createElement(
    CacheProvider,
    { deps: [tick] },
    children
  );
};

export var addGigNodeContext = function addGigNodeContext(options) {
  var renderNode = function renderNode(_ref3, editor, next) {
    var node = _ref3.node;

    // TODO: For all children/content under a gigNode, same context.
    // --> Could save 3x on path lookups.
    if (!(isGigNode(node) || isChildrenWrapper(node) || isContentNode(node) || node.object === 'inline')) {
      return next();
    }

    return React.createElement(
      AddContext,
      { editor: editor, node: node, options: options },
      next()
    );
  };

  var renderEditor = function renderEditor(props, editor, next) {
    return React.createElement(
      RenderEditor,
      null,
      next()
    );
  };

  return {
    renderNode: renderNode,
    renderEditor: renderEditor
  };
};