import getClosestGigNode from './getClosestGigNode';
import getLeftSiblingGigNode from './getLeftSiblingGigNode';
import appendGigNodeChild from './appendGigNodeChild';

export default (function (editor) {
  var thisGigNode = getClosestGigNode(editor);
  var leftSib = getLeftSiblingGigNode(editor);

  if (!leftSib) {
    // We are already indented or root.
    return;
  }

  appendGigNodeChild(editor, leftSib.key, thisGigNode);
});