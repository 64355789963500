var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React, { createContext, useReducer, useEffect, useState, useMemo } from 'react';
import { useSprings } from 'react-spring';

var getNodeKeys = function getNodeKeys(tree) {
  var list = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Map();
  var lastChildKeys = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var parentList = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new Map();

  if (!tree) return [];

  var children = tree.children;
  if (list.has(tree.key)) {
    throw new Error('Tree has key dublicates');
  }

  list.set(tree.key, children ? children.length : 0);

  if (children && children.length > 0) {
    lastChildKeys.push(children[children.length - 1].key);
  }
  ;(children || []).forEach(function (child) {
    parentList.set(child.key, tree.key);

    getNodeKeys(child, list, lastChildKeys, parentList);
  });
  return [list, lastChildKeys, parentList];
};

var initial = {
  springIndexForPlace: {},
  stylesPlace: {},
  setPlace: function setPlace() {},
  inProgress: false,
  placeProps: {},
  callbacks: null
};

var DndContext = createContext();

function dndReducer(state, action) {
  switch (action.type) {
    case 'setInProgress':
      {
        return _extends({}, state, { inProgress: action.value });
      }
    case 'setSpringIndexForPlace':
      {
        return _extends({}, state, { springIndexForPlace: action.value });
      }
    case 'setPlaceProps':
      {
        return _extends({}, state, { placeProps: action.value });
      }
    case 'setStylesPlace':
      {
        return _extends({}, state, { stylesPlace: action.value });
      }
    case 'setPlace':
      {
        return _extends({}, state, { setPlace: action.value });
      }
    default:
      {
        throw new Error('Unhandled action type: ' + action.type);
      }
  }
}

var useSpringSet = function useSpringSet(_ref) {
  var _ref$springIndexForPl = _ref.springIndexForPlace,
      springIndexForPlace = _ref$springIndexForPl === undefined ? {} : _ref$springIndexForPl,
      _ref$placeProps = _ref.placeProps,
      placeProps = _ref$placeProps === undefined ? {} : _ref$placeProps;

  var _useState = useState({}),
      stylesPlace = _useState[0],
      setStylesPlace = _useState[1];

  var _useSprings = useSprings(Object.keys(placeProps).length, function (i) {
    return {
      opacity: 0,
      height: 0,
      left: 0,
      top: 0,
      immediate: true
    };
  }),
      propsAllPlace = _useSprings[0],
      setPlace = _useSprings[1];

  useEffect(function () {
    var stylesPlace = {};
    for (var key in springIndexForPlace) {
      stylesPlace[key] = {
        before: propsAllPlace[springIndexForPlace[key].before],
        inside: propsAllPlace[springIndexForPlace[key].inside],
        after: propsAllPlace[springIndexForPlace[key].after]
      };
    }

    setStylesPlace(stylesPlace);
  }, [springIndexForPlace]);

  return [stylesPlace, setPlace];
};

export var DndProvider = function DndProvider(_ref2) {
  var children = _ref2.children,
      tree = _ref2.tree,
      callbacks = _ref2.callbacks;

  var _useReducer = useReducer(dndReducer, _extends({}, initial, { callbacks: callbacks })),
      state = _useReducer[0],
      dispatch = _useReducer[1];

  useEffect(function () {
    var index = 0;

    var _getNodeKeys = getNodeKeys(tree),
        list = _getNodeKeys[0],
        lastChildKeys = _getNodeKeys[1],
        parentList = _getNodeKeys[2];

    if (!list) return;
    var springIndexForPlace = {},
        placeProps = {};
    for (var _iterator = list, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
      var _ref4;

      if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref4 = _iterator[_i++];
      } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref4 = _i.value;
      }

      var _ref3 = _ref4;
      var key = _ref3[0];
      var childrenCnt = _ref3[1];

      if (key === tree.key) {
        continue;
      }
      springIndexForPlace[key] = { before: null, after: null, inside: null };
      index++;
      springIndexForPlace[key]['before'] = index;
      placeProps[index] = {
        key: key,
        type: 'before',
        parentKey: parentList.get(key)
      };
      if (childrenCnt < 1) {
        index++;
        springIndexForPlace[key]['inside'] = index;
        placeProps[index] = {
          key: key,
          type: 'inside',
          parentKey: parentList.get(key)
        };
      }
    }
    for (var _iterator2 = lastChildKeys, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
      var _ref5;

      if (_isArray2) {
        if (_i2 >= _iterator2.length) break;
        _ref5 = _iterator2[_i2++];
      } else {
        _i2 = _iterator2.next();
        if (_i2.done) break;
        _ref5 = _i2.value;
      }

      var _key = _ref5;

      index++;
      springIndexForPlace[_key]['after'] = index;
      placeProps[index] = { key: _key, type: 'after', parentKey: parentList.get(_key) };
    }

    dispatch({ type: 'setSpringIndexForPlace', value: springIndexForPlace });
    dispatch({ type: 'setPlaceProps', value: placeProps });
  }, [tree]);

  // based on currents state (springIndexForPlace and placeProps) I get array
  // of animated styles that then I will save in context

  var _useSpringSet = useSpringSet(state),
      stylesPlace = _useSpringSet[0],
      setPlace = _useSpringSet[1];

  useEffect(function () {
    dispatch({ type: 'setStylesPlace', value: stylesPlace });
    dispatch({ type: 'setPlace', value: setPlace });
  }, [stylesPlace, setPlace]);

  var value = useMemo(function () {
    return [state, dispatch];
  }, [state, dispatch]);

  return React.createElement(
    DndContext.Provider,
    { value: value },
    children
  );
};

export function useDnd() {
  var context = React.useContext(DndContext);
  if (context === undefined) {
    throw new Error('useDnd must be used within a DndProvider');
  }
  return context;
}