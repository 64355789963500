var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React, { useMemo, forwardRef } from 'react';
import Svg from './Svg';
import { PathLine } from 'react-svg-pathline';
import { dimStr, dimensionsToBox, addDimMargin } from './calcs';
import { strokeColor, strokeWidth, margin, lineRadius } from './config';

/*
 * It's possible to have a 0-height box, i.e. a line. Then, we need
 * the dimensions to be minimally covering stroke width.
 */
var boxToDimensions = function boxToDimensions(_ref, strokeWidth) {
  var xMin = _ref[0],
      yMin = _ref[1],
      xMax = _ref[2],
      yMax = _ref[3];

  var height = yMax - yMin;
  var halfWidth = strokeWidth / 2;

  return {
    left: xMin - halfWidth,
    top: yMin - halfWidth,
    width: xMax - xMin + strokeWidth,
    height: height + strokeWidth
  };
};

export default (function (_ref2) {
  var dimensions = _ref2.dimensions,
      ref = _ref2.ref;

  var points = useMemo(function () {
    var add = margin + strokeWidth / 2;

    var points = dimensionsToBox(addDimMargin(dimensions, add));

    return points;
  }, [dimensions]);

  var xMin = points[0],
      yMin = points[1],
      xMax = points[2],
      yMax = points[3];

  var finalDimensions = boxToDimensions(points, strokeWidth);

  return {
    points: points,
    element: React.createElement(
      Svg,
      _extends({
        ref: ref,
        fill: 'none',
        strokeWidth: strokeWidth,
        stroke: strokeColor
      }, finalDimensions, {
        viewBox: dimStr(finalDimensions)
      }),
      React.createElement(PathLine, {
        points: [{ x: xMin, y: yMin }, { x: xMax, y: yMin }, { x: xMax, y: yMax }, { x: xMin, y: yMax }, { x: xMin, y: yMin },
        // Just for a nice round final edge
        { x: xMax, y: yMin }],
        stroke: strokeColor,
        strokeWidth: strokeWidth,
        fill: 'none',
        r: lineRadius
      })
    )
  };
});