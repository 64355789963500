var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useContext, useMemo, useRef } from 'react';

// import SectionCounter from './SectionCounter'
import ContextMenu from './ContextMenu';
import addGigDomProps from './addGigDomProps';
import getGigNodeOuterDoms from './getGigNodeOuterDoms';

import { isGigNode } from '../../utils/node';

import { GigNodeContext } from '../gigNodeContext';

import { useMarkDiffPosition } from '../diffIndicator';
import { AcceptableChangeOverlay } from '../../diff';

import { LayoutGigNode } from '../layout';

var WrapIfExists = function WrapIfExists(_ref) {
  var Component = _ref.Component,
      children = _ref.children,
      domProps = _ref.domProps,
      props = _objectWithoutProperties(_ref, ['Component', 'children', 'domProps']);

  // TODO: Already losing domProps here if no Component...
  if (!Component) {
    return children;
  }

  return React.createElement(
    Component,
    _extends({}, props, domProps),
    children
  );
};

// const empty = {}

var GigNodePlugin = function GigNodePlugin(_ref2) {
  var children = _ref2.children,
      editor = _ref2.editor,
      node = _ref2.node,
      attributes = _ref2.attributes,
      contentPlugins = _ref2.contentPlugins;

  // Don't memoize because editor is mutable.
  var _useContext = useContext(GigNodeContext),
      OuterContainer = _useContext.OuterContainer,
      DescendantContainer = _useContext.Container,
      gigContext = _objectWithoutProperties(_useContext, ['OuterContainer', 'Container']);

  var ancestors = gigContext.ancestors,
      parent = gigContext.parent,
      currentGigNode = gigContext.currentGigNode,
      isDiffing = gigContext.isDiffing;


  var gigRef = useRef(null);

  // We don't wrap this in a useMemo because editor mutates.
  // Anyway it does a delayed update.

  // We wrap this with a useMemo so it doesn't keep refreshing the diff sidebar.
  var MarkComponent = useMemo(function () {
    return function (_ref3) {
      var dimensions = _ref3.dimensions,
          nodeDimensions = _ref3.nodeDimensions;

      if (!isDiffing) {
        return null;
      }
      return AcceptableChangeOverlay({
        parent: parent,
        editor: editor,
        // the same for this plugin.
        currentGigNode: node,
        dimensions: dimensions,
        nodeDimensions: nodeDimensions
      });
    };
  }, [isDiffing, parent, editor, node]);

  useMarkDiffPosition(currentGigNode.key, gigRef, MarkComponent, true);

  // This adds ALL plugins `GigDomProps` props into the gignode dom.
  // TODO: domProps are currently used in plugin-table, and they'll always
  // have a Container, so that's why this works for now.
  // We're passing on the domProps until CardWrapper and not below (if it
  // is skipped). Is this really the best mechanism to define the "highest
  // element in the render tree that is a real dom element"?
  var domProps = useMemo(function () {
    return addGigDomProps(contentPlugins)(gigContext);
  }, [contentPlugins, gigContext]);

  var OuterDoms = useMemo(function () {
    return getGigNodeOuterDoms(contentPlugins);
  }, [contentPlugins]);

  // The challenge here is we cannot freely add a div to spread our attributes.
  // So how do we assign attributes to the top actual dom node, and only the top one?
  // Note: LinedPadding -> CardWrapper
  // const { skipLinedPadding } = gigContext.outerDom.flags
  // const linedPaddingAttrs = !skipLinedPadding ? attributes : empty
  // const ownContainerAttrs =
  //   skipLinedPadding && gigContext.OuterContainer ? attributes : empty

  // const grandParent = ancestors.get(2, null)
  // TODO: so if we go next(), there are attributes, and since we custom render
  // all nodes we'll spread attributes there.
  return React.createElement(
    OuterDoms,
    gigContext,
    React.createElement(
      WrapIfExists,
      _extends({
        Component: DescendantContainer
      }, gigContext, {
        domProps: domProps
      }),
      React.createElement(
        LayoutGigNode,
        {
          node: node,
          editor: editor,
          attributes: attributes,
          gigContext: gigContext,
          ref: gigRef
        },
        React.createElement(ContextMenu, _extends({}, gigContext, { contentPlugins: contentPlugins })),
        React.createElement(
          WrapIfExists,
          _extends({
            Component: OuterContainer
          }, gigContext),
          children
        )
      )
    )
  );
};

export default (function (_ref4) {
  var contentPlugins = _ref4.contentPlugins;

  var renderNode = function renderNode(_ref5, editor, next) {
    var node = _ref5.node,
        attributes = _ref5.attributes,
        children = _ref5.children;

    if (!isGigNode(node)) {
      return next();
    }

    return React.createElement(
      GigNodePlugin,
      {
        node: node,
        attributes: attributes,
        editor: editor,
        contentPlugins: contentPlugins
      },
      children
    );
  };

  return {
    renderNode: renderNode
  };
});