var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { useContext, useMemo } from 'react';
import { GigNodeContext } from './gigNodeContext';
import { colorScheme } from '@gigmade/msm-ui';
import { useMarkDiffPosition } from './diffIndicator';
import { AcceptIgnore } from '../diff';
import { INSERT, DELETE } from '@gigmade/msm-merge-util';

var InlineContainer = function InlineContainer(_ref) {
  var children = _ref.children,
      editor = _ref.editor,
      isFocused = _ref.isFocused,
      node = _ref.node,
      attributes = _ref.attributes,
      contentPlugin = _ref.contentPlugin;

  var gigContext = useContext(GigNodeContext);

  var isDiffing = gigContext.isDiffing,
      diff = gigContext.diff,
      InlineRenderer = gigContext.InlineRenderer,
      currentGigNode = gigContext.currentGigNode,
      tick = gigContext.tick,
      gigNodeProps = _objectWithoutProperties(gigContext, ['isDiffing', 'diff', 'InlineRenderer', 'currentGigNode', 'tick']);

  var blockChange = useMemo(function () {
    var blockChangeType = isDiffing ? diff.isDelete ? DELETE : diff.isInsert ? INSERT : null : null;

    return blockChangeType ? {
      type: blockChangeType,
      onFinalize: diff.getToggle(blockChangeType).onFinalize
    } : null;
  }, [diff, isDiffing]);

  return React.createElement(
    InlineRenderer,
    _extends({
      blockChange: blockChange,
      colorScheme: colorScheme,
      isActiveBlock: isFocused,
      editor: editor,
      node: node,
      attributes: attributes,
      useMarkDiffPosition: useMarkDiffPosition,
      AcceptIgnore: AcceptIgnore
    }, gigNodeProps),
    children
  );
};

export default (function () {
  var renderNode = function renderNode(_ref2, editor, next) {
    var node = _ref2.node,
        attributes = _ref2.attributes,
        children = _ref2.children,
        isFocused = _ref2.isFocused;

    if (node.object !== 'inline') {
      return next();
    }

    return React.createElement(
      InlineContainer,
      {
        node: node,
        attributes: attributes,
        editor: editor,
        isFocused: isFocused
      },
      children
    );
  };

  return {
    renderNode: renderNode
  };
});