var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import { Block, Document } from '@gigmade/slate';
import { isGigNode } from '../../utils/node';

export default (function (changeOp) {
  var changeFn = function changeFn(node) {
    if (node.object === 'document') {
      return Document.create(node.nodes.map(changeFn));
    }

    if (node.object !== 'block') {
      return node;
    }

    return Block.fromJSON(_extends({}, node.toJSON(), {
      nodes: node.nodes.map(changeFn),
      data: isGigNode(node) ? changeOp(node) : node.data
    }));
  };

  return changeFn;
});