import isGigNode from './node/isGigNode';

/*
 * Would slate getBlocks() help optimizing against not touching every node?
 */
export default (function (editor, filterFn) {
  var document = editor.value.document;

  return document.filterDescendants(function (node) {
    if (node.object !== 'block') {
      return false;
    }
    if (!isGigNode(node)) {
      return false;
    }
    return Boolean(filterFn(node));
  });
});