import getParentGigNodeByKey from './getParentGigNodeByKey';
import insertChildGigNode from './insertChildGigNode';
import isEmptyTextGigNodeByKey from './isEmptyTextGigNodeByKey';
import unwrapRemoveGigNodeByKey from './unwrapRemoveGigNodeByKey';
import dedentByKey from './dedentByKey';

/*
 * This function is a small wrapper used to standardize how new
 * custom blocks get inserted from the menu.
 */

export default (function (editor, newNode) {
  var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

  if (target) {
    editor.select(target);
  }

  editor.withoutNormalizing(function () {
    // Since we sit at a contentNode, the editor will insert it as
    // a child.
    editor.insertBlock(newNode);
    // Therefore, need to dedent in the end.
    // But before, here's the chance to remove the selected node if it is empty.
    var parent = getParentGigNodeByKey(editor, newNode.key);
    // Note we let the insertBlock run first in order to do any necessary
    // selection deletion or other cleanup. Now, first arrange the node better.
    insertChildGigNode(editor, parent.key, newNode, 0);
    isEmptyTextGigNodeByKey(editor, parent.key) ? unwrapRemoveGigNodeByKey(editor, parent.key) : dedentByKey(editor, newNode.key);
  });
});