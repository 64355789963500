import debounce from 'lodash/debounce';
import { updateDiffCache } from './diffToggles';
import { initializeDiffCache } from '.';

var DELAY = 600;

// Since onChange happens frequently, this debounces a bit.
// TODO: It's still called twice because of our "shadow" editor in
// gigGetCleanValue()
var debouncedUpdateCache = debounce(updateDiffCache, DELAY);

function onValueFromNode(editor, next) {
  // Note: the fact that this is sync is very important.
  // Without it, we risk that the SlateEditor runs the clients onChange with
  // a node that has a mixed structure between stuff that is accepted and not,
  // and treats it as our wip node.
  initializeDiffCache(editor);
  next();
}

export default (function () {
  return {
    onChange: function onChange(editor, next) {
      debouncedUpdateCache(editor);
      next();
    },
    onValueFromNode: onValueFromNode
  };
});