import { useRef } from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';

/*
 * This form saves a run over useEffect but shouldn't matter.
 */
export default (function (onResize) {
  var size = useWindowSize();
  var currentSize = useRef(size);

  if (size.width !== currentSize.current.width || size.height !== currentSize.current.height) {
    currentSize.current = size;
    onResize(size);
  }
});