import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

/*
 * Return newData if different from last result, or else last result.
 */
export default (function (newData) {
  var previous = useRef();

  var equalData = isEqual(newData, previous.current);

  var data = equalData ? previous.current : newData;

  if (!equalData) {
    previous.current = data;
  }

  return data;
});