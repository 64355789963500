/*
 * Get the selected node's unique id.
 */
import { getSelectedId } from '../utils';

export default (function (options) {
  return {
    queries: {
      gigGetSelectedId: function gigGetSelectedId(editor) {
        return getSelectedId(editor);
      }
    }
  };
});