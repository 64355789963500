/*
 * Goal: what springers should travel together?
 * Question to ask: who is a neighbor now and has his current left neighbor as a
 * target (leader's target state) leftSibling? These will travel with us.
 *
 * Further: who has their own arrow?
 * - The leader
 * - all those who do NOT have same leftSibling in both states.
 *
 * Approach:
 * We classify springers into 3 categories:
 * - regular springers: have a toggle and move normally on their own.
 *   They can be dragged along, but not toggled or finalized, by a leader.
 * - leaders: they have a toggle and drag others along when they are toggled.
 *   They can drag along dependents and regular springers.
 *   When leaders toggle or finalize, dependents toggle or finalize as well,
 *   but regular springers that are dragged along do neither.
 * - dependents: strictly belong to 1 leader and toggle/finalize in sync with them.
 *
 * Test: springer neighbors [1, 2, 3, 4], and 3 can move out, others stay together
 */
import { getParentGigNodeByKey } from '../../utils';
import { isChildOfGigNodeByKey, getGigNodeChildIndex } from '../../utils/node';

import { getCache, setCache, getToggleCache } from '../diffCache';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import produce from 'immer';
import { SPRINGER } from '@gigmade/msm-merge-util';

function getSpringerLeftSiblingKeys(diffCache, key) {
  var springerData = diffCache[key].springer ? diffCache[key].springer.data : undefined;

  return springerData ? springerData.map(function (side) {
    return side.leftSiblingKey;
  }) : [];
}

function getLeftConnectedSpringers(diffCache, key) {
  var list = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

  var leftSiblings = getSpringerLeftSiblingKeys(diffCache, key);

  leftSiblings.forEach(function (leftSibling) {
    if (
    // leftSibling is defined
    leftSibling != null &&
    // leftSibling is not already collected
    list.indexOf(leftSibling) === -1 &&
    // leftSibling points to a springer
    diffCache[leftSibling] && 'springer' in diffCache[leftSibling]) {
      list.push(leftSibling);
      getLeftConnectedSpringers(diffCache, leftSibling, list);
    }
  });

  return list;
}

/*
 * This essentially just returns the parents for springers by the current
 * slate state, but saves a few slate key lookup cycles.
 */
function createParentLookup(editor, springersWithLeftConnected) {
  var springers = springersWithLeftConnected.map(function (item) {
    return item.key;
  });

  var connectedOfLength = function connectedOfLength(item) {
    return item.connected.length === connectedCount;
  };

  var currentChildren = new Set();
  var parentLookup = {};

  var connectedCount = 0;

  // Loop until we found the parents of all springers
  while (currentChildren.size < springers.length) {
    var candidates = springersWithLeftConnected.filter(connectedOfLength).map(function (item) {
      return item.key;
    })
    // Not already completed in a previous run.
    .filter(function (key) {
      return !currentChildren.has(key);
    });

    var parents = candidates.map(function (key) {
      return getParentGigNodeByKey(editor, key);
    });

    candidates.forEach(function (key) {
      return currentChildren.add(key);
    });

    parents.forEach(function (parent) {
      springers.filter(function (springer) {
        return isChildOfGigNodeByKey(parent, springer);
      }).forEach(function (key) {
        currentChildren.add(key);
        parentLookup[key] = parent;
      });
    });
    connectedCount++;
  }
  return parentLookup;
}

function getParentKeys(editor, key) {
  var toggleCache = getToggleCache(editor, key, SPRINGER);
  return toggleCache.data.map(function (item) {
    return item.parentKey;
  });
}

function hasParentKeys(editor, key, parentKeys) {
  var parentKeysOfKey = getParentKeys(editor, key);
  return isEqual(parentKeys, parentKeysOfKey);
}

function sameParentKeys(editor, key1, key2) {
  return hasParentKeys(editor, key1, getParentKeys(editor, key2));
}

function getSpringers(diffCache) {
  var springers = [];

  Object.keys(diffCache).forEach(function (key) {
    if (diffCache[key].springer) {
      springers.push(key);
    }
  });

  return springers;
}

function getSpringersWithLeftConnected(diffCache) {
  var springers = getSpringers(diffCache);

  /*
   * Define a cluster of springers that are mutually connected through leftSibling.
   */
  var springersWithLeftConnected = springers.map(function (key) {
    return { key: key, connected: getLeftConnectedSpringers(diffCache, key) };
  });
  return springersWithLeftConnected;
}

/*
 * has 0 same parentKeys in its springersWithLeftConnected array
 * is in the leftConnected array of at least 1 springer that
 * has the same parentKeys.
 */
function getLeaders(editor, springersWithLeftConnected) {
  var leaderCandidates = springersWithLeftConnected.filter(
  // has 0 springers with same parentKeys in leftConnected
  function (_ref) {
    var key = _ref.key,
        connected = _ref.connected;

    return connected.every(function (connectedKey) {
      return !sameParentKeys(editor, connectedKey, key);
    });
  }).map(function (item) {
    return item.key;
  });

  // if (springers.length) debugger

  var leaders = [];
  // at least one springer has it in its leftConnected and has the same
  // parent keys
  springersWithLeftConnected.forEach(function (_ref2) {
    var key = _ref2.key,
        connected = _ref2.connected;

    connected.forEach(function (candidate) {
      if (
      // it's not a candidate
      leaderCandidates.indexOf(candidate) === -1 ||
      // already have it.
      leaders.indexOf(candidate) !== -1) {
        return;
      }

      if (sameParentKeys(editor, candidate, key)) {
        leaders.push(candidate);
      }
    });
  });

  return leaders;
}

function addDragAlongs(leaders, parentBySpringer, springersWithLeftConnected) {
  var withDragAlongs = leaders.map(function (leader) {
    var currentParent = parentBySpringer[leader];

    // sortBy ensures a consistent order.
    var dragged = sortBy(springersWithLeftConnected.filter(function (_ref3) {
      var key = _ref3.key,
          connected = _ref3.connected;

      return connected.indexOf(leader) !== -1 && parentBySpringer[key] === currentParent;
    }).map(function (item) {
      return item.key;
    }), [function (key) {
      return getGigNodeChildIndex(currentParent, key);
    }]);
    return {
      leader: leader,
      dragged: dragged
    };
  });

  return withDragAlongs;
}

// Some dragAlongs just travel with the leader but retain their own capability
// to leave that train because they can head to a different section
// altogether.
// Others, specifically those that share the parent on both head and compare
// sides with the leader, should not exercise any own control, i.e. we rid
// those of their toggles.
function getDependents(editor, withDragAlongs) {
  var dependents = [];
  withDragAlongs.forEach(function (_ref4) {
    var leader = _ref4.leader,
        dragged = _ref4.dragged;

    var toggleCache = getToggleCache(editor, leader, SPRINGER);
    var leaderParentKeys = toggleCache.data.map(function (item) {
      return item.parentKey;
    });

    // check here if dragged and leader have the same head AND compare parent.
    // If yes, add to dependents, and in newCache, mark those as such.
    dragged.forEach(function (key) {
      if (hasParentKeys(editor, key, leaderParentKeys)) {
        dependents.push({ key: key, leader: leader });
      }
    });
  });

  return dependents;
}

var updateCache = function updateCache(editor) {
  var diffCache = getCache(editor);

  var springersWithLeftConnected = getSpringersWithLeftConnected(diffCache);

  var leaders = getLeaders(editor, springersWithLeftConnected);

  var parentBySpringer = createParentLookup(editor, springersWithLeftConnected);

  var leadersWithDragAlongs = addDragAlongs(leaders, parentBySpringer, springersWithLeftConnected);

  var dependents = getDependents(editor, leadersWithDragAlongs);

  setCache(editor, produce(function (draft) {
    springersWithLeftConnected.forEach(function (_ref5) {
      var springer = _ref5.key;

      var leaderItem = leadersWithDragAlongs.find(function (item) {
        return item.leader === springer;
      });

      if (leaderItem) {
        if (!isEqual(draft[springer].springer.dragAlong, leaderItem.dragged)) {
          draft[springer].springer.dragAlong = leaderItem.dragged;
        }

        delete draft[springer].springer.dependent;
        return;
      }

      var dependentItem = dependents.find(function (item) {
        return item.key === springer;
      });

      if (dependentItem) {
        if (draft[springer].springer.dependent !== dependentItem.leader) {
          draft[springer].springer.dependent = dependentItem.leader;
        }

        delete draft[springer].springer.dragAlong;
        return;
      }

      delete draft[springer].springer.dependent;
      delete draft[springer].springer.dragAlong;
    });
  }));
};

export default (function (editor) {
  editor.withoutSaving(function () {
    editor.withoutNormalizing(function () {
      updateCache(editor);
    });
  });
});