import { slateToDocument } from '../convert';
import { removePendingStructure } from '../diff';

var createGigExtractNodes = function createGigExtractNodes(contentPlugins) {
  // This removes inserts in the 2nd editor created by gigCleanValue, not the original.
  // So we can continue working during a diff while still using this function.
  var postProcessing = removePendingStructure;
  return function (editor) {
    return slateToDocument(editor.gigGetCleanValue(postProcessing), contentPlugins);
  };
};

export default (function (_ref) {
  var contentPlugins = _ref.contentPlugins;

  return {
    queries: {
      gigExtractNodes: createGigExtractNodes(contentPlugins)
    }
  };
});