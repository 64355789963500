/*
 * NOTE: Doesn't take into account yet the possibility
 * of node content and children mixed in sequence.
 * For now, assumes content first, then children.
 */

import getClosestGigNode from './getClosestGigNode';
import getLeftSiblingGigNodeByKey from './getLeftSiblingGigNodeByKey';

export default (function (editor) {
  var document = editor.value.document;


  var thisGigNode = getClosestGigNode(editor);
  return getLeftSiblingGigNodeByKey(editor, thisGigNode.key);
});