import getParentGigNodeByKey from './getParentGigNodeByKey';
import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import insertChildGigNode from './insertChildGigNode';
import isGigNode from './node/isGigNode';
import getGigNodeChildIndex from './node/getGigNodeChildIndex';

function getKey(child) {
  return typeof child === 'string' ? child : child.key;
}

/*
 * Append a sibling to node. Ignore if no parent or parent is root.
 * Note: newNode can be a key if the node is already in the doc.
 */
export default (function (editor, siblingKey, newNode) {
  var leftOfKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  var thisGigNode = getClosestGigNodeByKey(editor, siblingKey);

  var parent = getParentGigNodeByKey(editor, thisGigNode.key);
  if (!parent || !(isGigNode(parent) || parent.object === 'document')) {
    throw new Error('Append sibling failed, bad parent.');
  }
  var index = getGigNodeChildIndex(parent, thisGigNode.key);

  var targetIndex = index + (leftOfKey ? 0 : 1);

  // compensate for the case that the newNode exists, is already a sibling
  // to siblingKey AND is before siblingKey -> then, need to adjust the index
  // because when the node is moved out of its original place, the index
  // changes.
  var childKey = getKey(newNode);
  var removeIndex = getGigNodeChildIndex(parent, childKey);
  var adjust = removeIndex !== -1 && removeIndex < index;

  insertChildGigNode(editor, parent.key, newNode, targetIndex + (adjust ? -1 : 0));
});