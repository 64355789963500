/*
 * Path are 0 based and we want 1 based for human use.
 * Path node 0 usually is a contentNode, but not always.
 */
import getAncestorGigNodesByKey from './getAncestorGigNodesByKey';
import getGigNodeStructureByKey from './getGigNodeStructureByKey';
import isGigNode from './node/isGigNode';
import { PathUtils } from '@gigmade/slate';

export default (function (editor, path) {
  // In case passed path is a key.
  path = editor.value.document.getPath(path);

  // Get ancestors including non-gigNodes from root down to this node.
  var ancestors = getAncestorGigNodesByKey(editor, path, {
    bottomUp: false,
    includeRoot: true,
    skipNonGigNodes: false
  });

  var parentStructure = void 0;
  var rawUserPath = ancestors.reduce(function (newPath, ancestor, i) {
    // !isGigNode -> childrenWrapper, i === 0 -> root gigNode.
    if (!isGigNode(ancestor) || i === 0) {
      // Save the root node's structure for next time.
      parentStructure = getGigNodeStructureByKey(editor, ancestor.key);
      return newPath;
    }

    var index = path.get(i);
    var _parentStructure = parentStructure,
        firstChildOffset = _parentStructure.firstChildOffset;

    var userIndex = index + (firstChildOffset === 1 ? 0 : 1);

    // Save the parent's structure for next time.
    parentStructure = getGigNodeStructureByKey(editor, ancestor.key);
    return newPath.push(userIndex);
  }, PathUtils.create([]));

  return rawUserPath.filter(function (item) {
    return item !== null;
  });
});