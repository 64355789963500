/*
 * TODO: Make Controls a connector.
 */
import React, { Fragment, createRef } from 'react'
import useEditorControls from '../connectors/useEditorControls'
import DOM from 'react-dom'

import { Button } from './ui'
import Tooltip from 'rc-tooltip'

import LayoutSelector from './LayoutSelector'

const ButtonWithTooltip = ({ children, tooltip, icon, ...buttonProps }) => {
  return (
    <Tooltip mouseEnterDelay={1} overlay={tooltip} placement="bottom">
      <Button {...buttonProps}>
        {children}
        <span className={icon} />
      </Button>
    </Tooltip>
  )
}

const attachmentInputRef = createRef()

// node.value = '' afterwards...
const onClickAttach = () => {
  const node = DOM.findDOMNode(attachmentInputRef.current)
  node.click()
  node.value = ''
}

export default () => {
  const {
    loading,
    undo,
    redo,
    indent,
    dedent,
    onChangeFileInput,
    setLayout,
    layout,
  } = useEditorControls()

  // Loading: We don't yet have an editor controller or commands.
  if (loading) {
    return null
  }

  return (
    <Fragment>
      <LayoutSelector select={setLayout} selectedType={layout} />
      <ButtonWithTooltip
        key="undo"
        icon="icon-ccw"
        onMouseDown={undo}
        tooltip="Undo"
      />
      <ButtonWithTooltip
        key="redo"
        icon="icon-cw"
        onMouseDown={redo}
        tooltip="Redo"
      />
      <ButtonWithTooltip
        key="dedent"
        icon="icon-indent-left"
        onMouseDown={dedent}
        tooltip="Decrease indent"
      />
      <ButtonWithTooltip
        key="indent"
        icon="icon-indent-right"
        onMouseDown={indent}
        tooltip="Increase indent"
      />
      <ButtonWithTooltip
        key="attach"
        icon="icon-paperclip"
        onClick={onClickAttach}
        tooltip="Attach (Demo)"
      >
        <input
          ref={attachmentInputRef}
          onChange={onChangeFileInput}
          type="file"
        />
      </ButtonWithTooltip>
    </Fragment>
  )
}
