var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import React, { Fragment, useState, useEffect } from 'react';
import { useSprings, animated } from 'react-spring';
import parse from 'html-react-parser';

export default (function (_ref) {
  var tree = _ref.tree,
      onAnimationCompleted = _ref.onAnimationCompleted,
      startStyles = _ref.startStyles,
      endStyles = _ref.endStyles,
      content = _ref.content,
      layout2d = _ref.layout2d;

  var children = [tree];
  var cntNodes = Object.keys(startStyles).length;

  var _useState = useState(0),
      cnt = _useState[0],
      setCnt = _useState[1];

  var cntNodesChanged = 0;
  for (var key in startStyles) {
    for (var prop in startStyles[key]) {
      if (startStyles[key][prop] !== endStyles[key][prop]) {
        cntNodesChanged++;
        break;
      }
    }
  }

  useEffect(function () {
    if (cnt === cntNodesChanged - 1) {
      onAnimationCompleted();
    }
  }, [cnt, onAnimationCompleted, cntNodesChanged]);

  var nodeIdFor = {};
  var i = 0;
  for (var nodeId in startStyles) {
    nodeIdFor[i] = nodeId;
    i++;
  }

  var _useSprings = useSprings(cntNodes, function (i) {
    return _extends({
      position: layout2d ? 'fixed' : 'absolute',
      marginTop: 0
    }, startStyles[nodeIdFor[i]], {
      config: { duration: 600 },
      onRest: function onRest() {
        setCnt(function (cnt) {
          return cnt + 1;
        });
      }
    });
  }),
      props = _useSprings[0],
      set = _useSprings[1];

  var animatedProps = {};
  for (var j = 0; j < props.length; j++) {
    animatedProps[nodeIdFor[j]] = props[j];
  }

  var newStyles = {};
  for (var _i = 0; _i < cntNodes; _i++) {
    newStyles[_i] = _extends({}, endStyles[nodeIdFor[_i]], {
      zIndex: startStyles[nodeIdFor[_i]].zIndex
    });
  }

  useEffect(function () {
    set(function (i) {
      if (i === 0) {
        return {};
      }
      return newStyles[i];
    });
  }, [set, newStyles]);

  var list = function list(children) {
    if (children) {
      return children.map(function (node) {
        var nodeContent = parse(content[node.key] ? content[node.key] : '');
        return React.createElement(
          'div',
          { key: node.key },
          React.createElement(
            animated.div,
            {
              style: _extends({}, animatedProps[node.key], { boxSizing: 'border-box' })
            },
            nodeContent
          ),
          node.children && list(node.children)
        );
      });
    }
  };
  return React.createElement(
    Fragment,
    null,
    list(children)
  );
});