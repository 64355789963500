import React from 'react'
import styled from 'styled-components'
import { FlowMerge as DownIcon } from 'styled-icons/typicons/FlowMerge'
import { FlowChildren as RightIcon } from 'styled-icons/typicons/FlowChildren'
import { DocumentText as DefaultIcon } from 'styled-icons/typicons/DocumentText'

const SelectorButton = styled.div`
  display: inline-flex;
  align-content: flex-start;
  margin: auto 0.2em;
  color: #ffffff;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`

const Option = styled.span`
  display: flex;
  padding: 5px 7px;
  &:last-child {
    border-radius: 0 5px 5px 0;
  }
  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:hover {
    background: #7f8c8d;
  }
  ${props => (props.selected ? 'background: #7f8c8d' : 'background: #bdc3c7;')}
`

export default ({ select, selectedType }) => {
  return (
    <SelectorButton>
      <Option onClick={() => select('')} selected={!Boolean(selectedType)}>
        <DefaultIcon size="20" title="default" />
      </Option>
      <Option
        onClick={() => select('right')}
        selected={selectedType === 'right'}
      >
        <RightIcon size="20" title="right map" />
      </Option>
      <Option onClick={() => select('down')} selected={selectedType === 'down'}>
        <DownIcon size="20" title="down map" />
      </Option>
    </SelectorButton>
  )
}
