import getClosestGigNodeByKey from './getClosestGigNodeByKey';
import createGigNode from './createGigNode';
import insertSiblingGigNode from './insertSiblingGigNode';

export default (function (editor, key) {
  var insertBefore = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var focusOnly = arguments[3];

  var thisGigNode = getClosestGigNodeByKey(editor, key);

  var newNode = createGigNode(editor);
  insertSiblingGigNode(editor, thisGigNode.key, newNode, insertBefore);
  focusOnly ? editor.moveFocusToStartOfNode(newNode) : editor.moveToStartOfNode(newNode);
});