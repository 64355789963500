var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

/*
 * This allows slate plugins to define custom dom props to add to the dom
 * that renders this gigNode.
 * Examples: onClick listeners in plugin-table.
 */
var mergeProps = function mergeProps(props) {
  var newProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if ((typeof newProps === 'undefined' ? 'undefined' : _typeof(newProps)) !== 'object') {
    throw new Error('Illegal return value in addGigDomProps');
  }

  var style = newProps.style,
      rest = _objectWithoutProperties(newProps, ['style']);

  Object.assign(props, _extends({
    style: _extends({}, props.style, style)
  }, rest));
};

export default (function (contentPlugins) {
  // Using Reflect to guarantee sequence (CRA appears to not polyfill
  // Object.keys which guarantees sequence in ES2016 only,
  // https://github.com/facebook/create-react-app/issues/170
  // https://www.stefanjudis.com/today-i-learned/property-order-is-predictable-in-javascript-objects-since-es2015
  var addPropFns = contentPlugins.map(function (plugin) {
    return plugin.addGigDomProps;
  }).filter(Boolean);

  return function (args) {
    addPropFns.reduce(function (agg, fn) {
      return mergeProps(agg, fn(args));
    }, {});
  };
});